import { Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { displayErrorMessage } from '@/util';
import { RootState } from '@/store';
import toast from '@/atoms/toast';

type headersType = {
  'X-PawCare-API-Key': string | undefined;
  Authorization?: string | undefined;
};

export const headers: headersType = {
  'X-PawCare-API-Key': process.env.REACT_APP_API_KEY,
};

interface FetchDataProps {
  endpoint?: string;
  httpMethod?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions?: (((...args: any[]) => void) | null)[];
  body?: object;
  isMedia?: boolean;
  headers?: { [key: string]: string };
  fullEndpoint?: string;
  showAPIerror?: boolean;
  displayErrorToast?: boolean;
  bypassImpersonation?: boolean;
  token?: string;
}

export const fetchData = ({
  endpoint,
  fullEndpoint,
  httpMethod = 'get',
  actions,
  body,
  isMedia,
  headers: extraHeaders,
  showAPIerror,
  displayErrorToast = true,
  bypassImpersonation,
  token: passedToken,
}: FetchDataProps) => {
  if (!endpoint && !fullEndpoint) return;

  const basePath = process.env.REACT_APP_API_DOMAIN;
  const apiEndpoint = fullEndpoint || `${basePath}/${endpoint}`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (dispatch: Dispatch<any>, getState: () => RootState) => {
    const [request, success, failure] = actions || [];
    const { token } = getState().user;
    let fetchHeaders = headers;

    // sessionStorage and passedToken are used on Survey flow
    let requestToken =
      token ||
      sessionStorage.getItem('token') ||
      localStorage.getItem('token') ||
      passedToken;

    if (bypassImpersonation) {
      requestToken = token || localStorage.getItem('token') || undefined;
    }
    if (requestToken) {
      fetchHeaders = {
        ...headers,
        Authorization: `Bearer:${requestToken}`,
      };
    }

    if (extraHeaders) {
      fetchHeaders = Object.assign(headers, extraHeaders);
    }

    const postBody = isMedia ? body : decamelizeKeys(body);

    if (request) dispatch(request());
    const axiosObj = {
      fetchHeaders,
      endpoint: apiEndpoint,
      body: postBody,
      httpMethod,
    };
    return axiosRequest(axiosObj)
      .then(({ data }) => {
        const camelizedData = camelizeKeys(data);
        if (success) dispatch(success(camelizedData));
        return { success: true, data: camelizedData };
      })
      .catch((error) => {
        const errorData = error.response?.data?.detail;
        if (failure) dispatch(failure({ error: errorData }));
        let errorMessage = null;
        if (showAPIerror) {
          if (process.env.REACT_APP_MODE === 'development') {
            console.log(errorData);
          }
          errorMessage =
            typeof errorData === 'string' ? errorData : errorData[0].message;
        }
        if (displayErrorToast && !(errorData?.[0]?.code === 204)) {
          const errorText = displayErrorMessage({ errorData, errorMessage });
          toast({ text: errorText });
        }

        return {
          success: false,
          data: errorData,
          status: error.response?.status,
        };
      });
  };
};

interface AxiosRequestProps {
  fetchHeaders: object;
  endpoint: string;
  httpMethod: string;
  body?: object;
}

const axiosRequest = ({
  fetchHeaders,
  endpoint,
  body,
  httpMethod,
}: AxiosRequestProps) => {
  if (httpMethod === 'post')
    return axios.post(endpoint, body, {
      headers: fetchHeaders,
    });
  if (httpMethod === 'patch')
    return axios.patch(endpoint, body, {
      headers: fetchHeaders,
    });
  if (httpMethod === 'delete')
    return axios.delete(endpoint, {
      headers: fetchHeaders,
    });
  if (httpMethod === 'put')
    return axios.put(endpoint, body, {
      headers: fetchHeaders,
    });
  return axios.get(endpoint, {
    headers: fetchHeaders,
  });
};
