import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { mfaInit } from '@/actions/authentication';
import { editUser } from '@/actions/user';
import Button from '@/atoms/button';
import Dropdown from '@/atoms/dropdown';
import Input from '@/atoms/input';
import { setActivePet } from '@/store/reducers/pet-appointments';
import { editForm } from '@/store/reducers/signup-form';

export default function FinishSignupInfo() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const countryIdOptions = useAppSelector(
    (state) => state.main.geo.countryCode,
  );
  const { token } = useAppSelector((state) => state.user);
  const { countryCodeId, phoneNumber } = useAppSelector(
    (state) => state.signupForm.data,
  );
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const canContinue =
    phoneNumber && password && confirmPassword && password === confirmPassword;
  const data = useAppSelector((state) => state.user.data);

  useEffect(() => {
    // If user is signed up, we can't ask for their phone number and password.
    // They need to first reset their password and log in.
    if (!token) {
      if (params.code) {
        navigate(`/password-reset/${params.code}`);
      } else navigate('/trigger-password-reset');
    }
  }, []);

  const onSignupValidate = async (e: any) => {
    e.preventDefault();

    if (!canContinue) return;

    const userEdited = await dispatch(
      editUser({ phoneNumber, password, countryCodeId: countryCodeId.id }),
    );
    if (userEdited?.success) verifyPhone();
  };

  const verifyPhone = () => {
    dispatch(mfaInit({ countryCodeId: countryCodeId.id, phoneNumber }));
    dispatch(setActivePet(data.animals[0]));
    navigate('/signup/mfa');
  };

  return (
    <div className="signup h-100">
      <h1 className="mb-0 f-14 mobile-page-title">Finish signing up</h1>
      <form onSubmit={onSignupValidate} className="h-100">
        <div className="mobile-page-container h-100">
          <div className="phone-container flex-row">
            <Dropdown
              id="countryCode"
              label="Phone"
              className="country-code-input"
              items={countryIdOptions}
              onClick={(value) =>
                dispatch(editForm({ key: 'countryCodeId', value }))
              }
              value={countryCodeId}
            />
            <Input
              id="phone"
              className="mt-42 w-100"
              onChange={(value: string) =>
                dispatch(editForm({ key: 'phoneNumber', value }))
              }
              value={phoneNumber}
              isNumber
            />
          </div>
          <Input
            id="password"
            label="New password"
            type="password"
            onChange={(value: string) => setPassword(value)}
            value={password}
          />
          <Input
            id="confirmPassword"
            label="Confirmed password"
            type="password"
            onChange={(value: string) => setConfirmPassword(value)}
            value={confirmPassword}
          />
        </div>
        <div className="mobile-main-action-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            type="submit"
            className="mt-35"
          />
        </div>
      </form>
    </div>
  );
}
