import {
  petAppointmentsFailure,
  petAppointmentsRequest,
  petAppointmentsSuccess,
} from '@/store/reducers/pet-appointments';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';

import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { fetchData } from './fetchData';

export const createPet =
  (body: any, actions?: any) =>
  (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'animal',
      actions: actions || [requestOngoing, requestFulfilled, requestFulfilled],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);

export const editPet =
  (body: any, petId: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `animal/${petId}`,
      actions: [null, null, null],
      httpMethod: 'patch',
      body,
    })?.(dispatch, getState);

export const uploadPetAvatar =
  (body: any, petUuid: any) =>
  (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `animal/${petUuid}/image/upload`,
      actions: [null, null, null],
      httpMethod: 'post',
      isMedia: true,
      body,
    })?.(dispatch, getState);

export const loadPetAppointments =
  (animal: any) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!animal?.uuid) {
      return;
    }
    return fetchData({
      endpoint: `animal/${animal?.uuid}/appointments`,
      actions: [
        petAppointmentsRequest,
        petAppointmentsSuccess,
        petAppointmentsFailure,
      ],
    })?.(dispatch, getState);
  };
