import { createSlice } from '@reduxjs/toolkit';

interface AuthenticationState {
  signupLoading: boolean;
}

const initialState: AuthenticationState = {
  signupLoading: false,
};

const authentication = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    signupRequest(state) {
      state.signupLoading = true;
    },
    signupSuccess(state) {
      state.signupLoading = false;
    },
    signupFailure(state) {
      state.signupLoading = false;
    },
  },
});

const { actions, reducer } = authentication;

export const { signupRequest, signupSuccess, signupFailure } = actions;

export default reducer;
