import './date-input.scss';

import { DatePicker } from '@mui/x-date-pickers';
import classNames from 'classnames';
import dayjs from 'dayjs';

interface DateInputProps {
  value: string | Date | dayjs.Dayjs | null;
  onChange: (date: string | null) => void;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'white' | 'black' | 'grey';
  minDate?: string | dayjs.Dayjs;
  maxDate?: string | dayjs.Dayjs;
  disableValues?: (date: dayjs.Dayjs) => boolean;
  placeholder?: string;
  readOnly?: boolean;
}

export function DateInput({
  value,
  onChange,
  className,
  minDate,
  maxDate,
  disableValues,
  size = 'medium',
  placeholder = 'mm/dd/yyyy',
  variant = 'white',
  readOnly,
}: DateInputProps) {
  return (
    <div className={classNames(className)}>
      <DatePicker
        className={classNames(
          readOnly && 'date-picker-disabled',
          `date-picker date-picker--${variant} date-picker--${size}`,
        )}
        shouldDisableDate={disableValues}
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        value={value ? dayjs(value) : null}
        // onKeyDown={(e: any) => e.preventDefault()}
        slotProps={{ textField: { placeholder } }}
        readOnly={readOnly}
        onChange={(date) =>
          onChange(date ? dayjs(date).format('YYYY-MM-DD') : null)
        }
      />
    </div>
  );
}
