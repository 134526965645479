import { useEffect, useState } from 'react';
import { useAppDispatch } from '@/hooks';
import { Link } from 'react-router-dom';

import { deleteWaiver, signWaiver } from '@/actions/user';
import Checkbox from '@/atoms/checkbox';

export default function TermsLabel({
  hasSignedWaiver,
}: {
  hasSignedWaiver: boolean;
}) {
  const dispatch = useAppDispatch();
  const [startedSigned, setStartedSigned] = useState(false);

  useEffect(() => {
    if (hasSignedWaiver) setStartedSigned(true);
  }, []);

  const onChangeWaiver = ({ isChecked }: { isChecked: boolean }) => {
    if (isChecked) dispatch(signWaiver());
    else dispatch(deleteWaiver());
  };

  if (startedSigned)
    return (
      <p className="f11 text-grey-dark italic mt-15">
        By proceeding you allow PawCare Inc. to charge your card for future
        payments in accordance with our{' '}
        <Link to="/terms" target="_blank">
          Terms of Service
        </Link>{' '}
        and{' '}
        <a href="/privacy.html" target="_blank">
          Privacy Policy
        </a>
        .
      </p>
    );

  return (
    <div className="flex-row align-start mt-15">
      <Checkbox id="waiver" value={hasSignedWaiver} onClick={onChangeWaiver}>
        <p className="f11 text-grey-dark italic ml-5">
          I have read and accept the{' '}
          <Link to="/terms" target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <a href="/privacy.html" target="_blank">
            Privacy Policy
          </a>
          , including our liability waiver and cancellation/no show fee
          policies.
        </p>
      </Checkbox>
    </div>
  );
}
