import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import Button from '@/atoms/button';
import OverflowText from '@/organisms/overflow-text';
import ServiceViewMoreModal from '@/organisms/service-view-more-modal';
import { UserDetails } from '@/organisms/user-details';
import { clearForm as clearAppointmentForm } from '@/store/reducers/request-appointment-form';
import { formatHoursAndMinutes, prettifyDate } from '@/util';
import { trackServiceViewed } from '@/util/tracking/events';

export function DesktopView({ finishSignup }: { finishSignup: boolean }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { uuid } = useAppSelector((state) => state.user.data);
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const { invoice, policy } = useAppSelector((state) => state.invoice);
  const { provider, service, dateStart, locationUser } =
    useAppSelector((state) => state.requestAppointmentForm) || {};
  const [isOpen, openModal] = useState(false);
  const { publicSettings } = useAppSelector((state) => state.location);
  const confirmationMessage =
    publicSettings?.aptConfirmationMessage ||
    'As a reminder, if you must cancel please do so more than 24 hours before your appointment time to avoid a cancellation fee. You can manage your appointment and future pet care needs in the PawCare mobile app.';
  const disclaimerMessage =
    policy?.price?.text ||
    "Prices may change at groomer's discretion based on size, condition of coat, length and animal temperament.";

  useEffect(() => {
    if (!service) navigate('/');
  }, []);

  const onClickSurveyAction = () => {
    sessionStorage.clear();
    dispatch(clearAppointmentForm());
    navigate('/signup/finish-signup');
  };

  const onViewMore = (service: any) => {
    dispatch(trackServiceViewed(service, provider));
    openModal(service);
  };

  if (!service) return;

  return (
    <div className="booking-confirmation-desktop">
      <div className="booking-confirmation-desktop-container h-100">
        <h1>Your appointment has been confirmed</h1>
        <p className="text-grey-dark">{confirmationMessage}</p>
        <UserDetails item={provider} className="mt-35 mb-35" />
        <div className="landing-container-centered-service mt-20 w-100">
          <p className="bold f18">{service.name}</p>
          <p className="f13 date">
            on {prettifyDate(dateStart)} with {locationUser.user.firstName}
          </p>
          {service.durationMinutes && (
            <p className="f13 mb-10">{`Duration: ${formatHoursAndMinutes(
              service.durationMinutes,
            )}`}</p>
          )}
          <OverflowText
            buttonText="Learn more"
            text={service.description}
            className="f16 text-grey-dark description mb"
            onClick={() => onViewMore(service)}
          />
        </div>
        <div className="mt-32 w-100">
          {invoice?.transaction?.details?.map((transaction: any) => (
            <div
              className="flex-row justify-between mb-15"
              key={transaction.description}
            >
              <p>{transaction.description}</p>
              <p>{transaction.price?.localized || 'TBD'}</p>
            </div>
          ))}
        </div>
        <div className="booking-confirmation-desktop-container-price mt-15 w-100 flex-row justify-between">
          <p className="bold f18 mt-15">Est. total</p>
          <h2 className="f18">{invoice?.total?.localized || 'TBD'}</h2>
        </div>
        <p className="f14 text-grey-dark italic mt-12">{disclaimerMessage}</p>
        {finishSignup ? (
          <Button
            onClick={onClickSurveyAction}
            className={classNames('margin-center mt-40', isMobile && 'w-100')}
            text="Finish signup"
            size="medium"
          />
        ) : (
          <Button
            onClick={() => navigate(`/user/${uuid}/pet/${activePet.uuid}`)}
            className="margin-center mt-40"
            text="View your appointments"
            size="medium"
          />
        )}
        {isOpen && (
          <ServiceViewMoreModal
            isOpen={isOpen}
            openModal={openModal}
            service={isOpen}
          />
        )}
      </div>
    </div>
  );
}
