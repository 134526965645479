import './mfa.scss';

import { Helmet } from 'react-helmet-async';

import Landing from '@/organisms/landing';

import MFAInfo from './mfa-info';

export default function MFA() {
  return (
    <>
      <Helmet title="Phone verification | PawCare">
        <link
          rel="canonical"
          href="https://mypawcare.com/signup/phone-verification"
        />
      </Helmet>
      <Landing removeOutterPadding>
        <MFAInfo />
      </Landing>
    </>
  );
}
