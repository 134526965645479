import { useAppDispatch } from '@/hooks';

import { appointmentFinder } from '@/actions/appointments';
import Button from '@/atoms/button';
import { chicagoLatLng } from '@/util/constants';

export default function NoResults({ dateStart }: any) {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(
      appointmentFinder({
        dateStart,
        includeAllProviders: true,
        lat: chicagoLatLng.lat,
        lng: chicagoLatLng.lng,
      }),
    );
  };

  return (
    <div className="no-results margin-center text-center">
      <h2 className="mb-5">No results found</h2>
      <p>
        Try searching for something else, in a different area, or for a
        different provider.
      </p>
      <Button
        onClick={onClick}
        size="medium"
        text="Expand your search"
        variant="reverse-orange"
        className="margin-center mt-15"
      />
    </div>
  );
}
