import './details.scss';
import { useAppSelector } from '@/hooks';
import Icon from '@/atoms/icon';
import ProviderInfoCard from '@/organisms/provider-info-card';
import { prettifyUrl } from '@/util';

import Hours from './hours';

export function Details({ data, isMobile }: any) {
  const { email, locality, postalCode, address1, region, hours = [] } = data;

  const dowOptions = useAppSelector((state) => state.main.calendar.dayOfWeek);
  const mapImage = `https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=terrain&zoom=14&markers=${encodeURIComponent(
    `icon:https://i.imgur.com/ESZho3g.png|${address1},${locality},${region}`,
  )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

  return (
    <div className="profile-info details mt-25">
      {!isMobile ? (
        <>
          <h3 className="f20 mb-15 text-black">Open hours</h3>
          <Hours dowOptions={dowOptions} hours={hours} />
          <ProviderInfoCard location={data} />
        </>
      ) : (
        <>
          <h2 className="section-title mb-15">Location details</h2>
          <a
            href={`https://maps.google.com/maps?q=${address1}, ${region}, ${locality}`}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="static-map"
              style={{ backgroundImage: `url(${mapImage})` }}
            />
          </a>
          <div>
            <span className="flex-row mb-30 address">
              <Icon name="map-drop" className="mr-20" />
              <div className="flex-column mt-15">
                <h3 className="f17 text-black">Get directions</h3>
                <a
                  href={`https://maps.google.com/maps?q=${address1}, ${region}, ${locality}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="mobile">
                    <p>{address1}</p>
                    <p>{`${locality}, ${region} ${postalCode}`}</p>
                  </span>
                </a>
              </div>
            </span>
            {/* <span className="flex-row phone">
              <Icon name="phone" className="mr-15" />
              <a href={`tel:${phone?.localized}`} onClick={() => trackEvent('Select call')}>
                <div className="flex-column">
                  <h3 className="f17 text-black">Call</h3>
                  <p className="mobile">{phone?.localized}</p>
                </div>
              </a>
            </span> */}
            {email && (
              <span className="flex-row mt-35 mb-35 email">
                <Icon name="email" className="mr-15" />
                <a href={`mailto:${email}`}>
                  <div className="flex-column ellipsis">
                    <h3 className="f17 text-black">Email</h3>
                    <p className="mobile ellipsis">{prettifyUrl(email)}</p>
                  </div>
                </a>
              </span>
            )}
          </div>
          <span className="flex-row align-start hours">
            <Icon name="clock" className="mr-20 mt-10" />
            <div className="flex-column">
              <h3 className="f17 text-black">Hours</h3>
              <Hours dowOptions={dowOptions} hours={hours} />
            </div>
          </span>
        </>
      )}
    </div>
  );
}
