import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

// To be used when we want to save previous location
export default function LinkComponent({
  to,
  children,
  ...props
}: {
  to: string;
  children: ReactNode;
  className?: string;
}) {
  const { pathname, search } = useLocation();
  return (
    <Link to={to} state={{ referrer: pathname + search }} {...props}>
      {children}
    </Link>
  );
}
