import './dropdown.scss';

import { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import Icon from '@/atoms/icon';
import { isEmpty } from '@/util';

interface DropdownProps {
  items: any[];
  placeholder?: string;
  onClick: (item: any) => void;
  value?: { value?: string; name?: string; code?: string } | null;
  className?: string;
  label?: string;
  menuItemClassname?: string;
  id?: string;
  generateKey?: boolean;
  disabledValues?: any[];
  disableDropdown?: boolean;
  isClearable?: boolean;
  icon?: any;
  size?: 'big' | 'small' | 'medium';
  shape?: 'square';
  variant?: 'grey' | 'white';
  hideSelection?: boolean;
  noWrap?: boolean;
}

export default function Dropdown({
  items = [],
  placeholder,
  onClick,
  value: controlledValue,
  className,
  label,
  menuItemClassname,
  id,
  generateKey,
  disabledValues = [],
  disableDropdown,
  isClearable,
  icon = KeyboardArrowDownIcon,
  size = 'big',
  shape = 'square',
  variant = 'grey',
  hideSelection,
  noWrap = false,
}: DropdownProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(100);
  // Empty value has to be an empty string, MUI will complain with null
  const [value, setValue] = useState('');
  const canClearValue = isClearable && value;

  useEffect(() => {
    // Value has to be the exact instance of the values from the list of options,
    // otherwise MUI will show a warning on console
    if (controlledValue && !isEmpty(controlledValue)) {
      const valueIndex = items.findIndex(
        (item) =>
          item.value ===
          (controlledValue?.value ? controlledValue.value : controlledValue),
      );
      const valueFromItemsInstance = items[valueIndex];
      setValue(valueFromItemsInstance || controlledValue || '');
    } else setValue('');
  }, [controlledValue]);

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      setWidth(width);
    }
  }, [ref]);

  const onItemclick = (item: any) => () => {
    if (id === 'countryCode') {
      setValue(item.code);
    } else {
      setValue(item);
    }
    onClick(item);
  };

  const isValueDisabled = (item: any) => {
    const option = item.value || item.name || item;
    return !!disabledValues.includes(option);
  };

  const onClearValue = (e: any) => {
    e.stopPropagation();
    onClick(null);
    setValue('');
  };

  return (
    <div
      ref={ref}
      role="button"
      tabIndex={0}
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        className,
        'pos-relative dropdown-container pointer',
      )}
    >
      {label && <p className="bold f18 mb-15 mt-0 dropdown-label">{label}</p>}
      <Select
        className={classNames(
          'dropdown',
          `dropdown--${size}`,
          `dropdown--${shape}`,
          `dropdown--${variant}`,
          !value && 'dropdown-placeholder',
        )}
        value={value}
        disabled={disableDropdown}
        onChange={onItemclick}
        displayEmpty
        IconComponent={disableDropdown ? null : icon}
        renderValue={() => {
          if (hideSelection) {
            return;
          }
          if (!value) {
            return <p className="dropdown-placeholder">{placeholder}</p>;
          }

          return (
            <div className="flex-row justify-between dropdown-content fw-100">
              <div
                className={classNames(
                  'mr-20',
                  noWrap ? 'ellipsis' : 'flex-row',
                )}
              >
                {(value as any)?.code ??
                  (value as any)?.value ??
                  (value as any)?.name ??
                  value}
              </div>
            </div>
          );
        }}
      >
        {items.map((item) => (
          <MenuItem
            className={`menuItem--${size} ${menuItemClassname}`}
            style={{ width: `${width} !important` }}
            value={item}
            disabled={isValueDisabled(item)}
            onClick={onItemclick(item)}
            key={
              generateKey
                ? Math.random() * 10000
                : item.id || item.value || item
            }
          >
            {item.country || item.value || item.name || item}
          </MenuItem>
        ))}
      </Select>
      {canClearValue && (
        <ClickAwayListener onClickAway={() => {}}>
          <div
            role="button"
            tabIndex={0}
            onClick={onClearValue}
            className="clear-icon-container"
          >
            <Icon
              width={10}
              height={10}
              fill="#63637b"
              className="clear-icon"
              name="close"
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
