import './daycare-modal.scss';

import { useState } from 'react';

import ConfirmationScreen from './confirmation-screen';
import CreateAccount from './create-account';

export default function DaycareModal({
  openModal,
}: {
  openModal: (bool: boolean) => void;
}) {
  const [accountCreated, setAccountCreated] = useState(false);

  return (
    <div className="flex-column align-center daycare-modal-container">
      {!accountCreated ? (
        <CreateAccount setAccountCreated={setAccountCreated} />
      ) : (
        <ConfirmationScreen openModal={openModal} />
      )}
    </div>
  );
}
