import { useEffect } from 'react';
import useQuery from '@/hooks/useQuery';

export default function LinkMobileRedirect() {
  const query = useQuery();

  useEffect(() => {
    const userAgent = navigator.userAgent || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      window.location.assign('/');
    } else if (/android/i.test(userAgent)) {
      window.location.assign(
        'https://play.google.com/store/apps/details?id=com.mypawcare',
      );
    } else if (
      (/iPad|iPhone|iPod|^((?!chrome|android).)*safari/.test(userAgent) &&
        !window.MSStream) ||
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      window.location.assign('https://apps.apple.com/app/pawcare/id6446097633');
    } else
      window.location.assign(
        `/${query.size > 0 ? `?${query.toString()}` : ''}`,
      );
  }, []);

  return null;
}
