import { Helmet } from 'react-helmet-async';

import Landing from '@/organisms/landing';

import AdditionalPetDetailsInfo from './additional-pet-details-info';

export default function AdditionalPetDetails() {
  return (
    <>
      <Helmet title="Create pet | PawCare">
        <link
          rel="canonical"
          href="https://mypawcare.com/signup/additional-pet-details"
        />
      </Helmet>
      <Landing>
        <AdditionalPetDetailsInfo />
      </Landing>
    </>
  );
}
