import './admin-search.scss';

import ConsumerSearch from '@/organisms/user-search/consumer-search';

function AdminSearch() {
  return (
    <div className="admin-search">
      <ConsumerSearch />
    </div>
  );
}

export default AdminSearch;
