import './toggle.scss';

import { Switch } from '@headlessui/react';

export default function Toggle({
  setState,
  enabled = false,
}: {
  setState: (state: boolean) => void;
  enabled: boolean;
}) {
  const onToggle = (state: boolean) => {
    setState(state);
  };

  return (
    <Switch
      checked={enabled}
      onChange={onToggle}
      className={`switch switch--${enabled ? 'enabled' : ''}`}
    >
      <span aria-hidden="true" className={enabled ? 'enabled' : ''} />
    </Switch>
  );
}
