import './signup.scss';

import { Helmet } from 'react-helmet-async';

import Landing from '@/organisms/landing';

import SignupDetails from './signup-details';

export default function Signup() {
  return (
    <>
      <Helmet title="Signup | PawCare" />
      <Landing>
        <SignupDetails />
      </Landing>
    </>
  );
}
