import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useParams } from 'react-router-dom';

import { editUser, loadUser } from '@/actions/user';
import Button from '@/atoms/button';
import Dropdown from '@/atoms/dropdown';
import Input from '@/atoms/input';
import LocationSearch from '@/atoms/location-search';
import { editAdvancedSearch } from '@/store/reducers/advanced-search';
import { toggleVerifyNumberModal } from '@/store/reducers/ui';
import {
  editCountryCode,
  editForm,
  editPhoneNumber,
} from '@/store/reducers/user';

let phoneNumber: string;
let countryCodeId: string;

export default function EditUserModal({
  uuid,
  openModal,
}: {
  uuid?: string;
  openModal: (bool: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { firstName, lastName, email, phone } = useAppSelector(
    (state) => state.user.data,
  );
  const countryIdOptions = useAppSelector(
    (state) => state.main.geo.countryCode,
  );
  // eslint-disable-next-line camelcase
  const { address_1, locality, region, latitude, longitude, postalCode } =
    useAppSelector((state) => state.advancedSearch);
  // eslint-disable-next-line camelcase
  const invalidAddress = address_1 === 'invalid_address';

  useEffect(() => {
    phoneNumber = phone.number;
    countryCodeId = phone.country.code.id;
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();

    interface UserEditBody {
      first_name: string;
      last_name: string;
      email: string;
      phoneNumber: string;
      countryCodeId: string;
      address?: {
        address: string | null;
        country_id: number;
        locality: string | null;
        region: string | null;
        postalCode: string | null;
        latitude: number;
        longitude: number;
      };
    }

    const body: UserEditBody = {
      first_name: firstName,
      last_name: lastName,
      email,
      phoneNumber: phone.number,
      countryCodeId: phone.country.code.id,
    };

    if (latitude && longitude) {
      body.address = {
        // eslint-disable-next-line camelcase
        address: address_1,
        country_id: 238,
        locality,
        region,
        postalCode,
        latitude,
        longitude,
      };
    }

    dispatch(editUser(body, uuid))?.then(({ success }) => {
      if (success) {
        dispatch(loadUser(params.userId));
        openModal(false);
        if (
          countryCodeId !== phone.country.code.id ||
          phoneNumber !== phone.number
        ) {
          dispatch(toggleVerifyNumberModal({ isOpen: true }));
        }
      }
    });
  };

  return (
    <div className="edit-user-modal">
      <h1 className="mb-25">Edit User Information</h1>
      <form onSubmit={onSubmit}>
        <Input
          id="first-name"
          label="First Name"
          className="w-100 mb-25"
          onChange={(value: string) =>
            dispatch(editForm({ key: 'firstName', value }))
          }
          value={firstName}
          autoFocus
        />
        <Input
          id="last-name"
          label="Last Name"
          className="w-100 mb-25"
          onChange={(value: string) =>
            dispatch(editForm({ key: 'lastName', value }))
          }
          value={lastName}
        />
        <Input
          id="email"
          label="Email"
          type="email"
          className="w-100 mb-25"
          onChange={(value: string) =>
            dispatch(editForm({ key: 'email', value }))
          }
          value={email}
        />
        <div className="flex-row phone-container mobile-page-container">
          <Dropdown
            id="countryCode"
            label="Phone"
            className="country-code-input"
            items={countryIdOptions}
            onClick={({ code, id, country }) => {
              dispatch(
                editCountryCode({
                  value: { code: { id, value: code }, name: country },
                }),
              );
            }}
            value={{
              code: phone.country.code.value,
              value: phone.country.name,
            }}
          />
          <Input
            id="phone"
            className="mt-42 w-100"
            onChange={(value: string) => dispatch(editPhoneNumber({ value }))}
            value={phone.number}
            isNumber
          />
        </div>
        <LocationSearch
          label="Update Address"
          editLocation={editAdvancedSearch}
          displayErrorCaption
          hasError={invalidAddress}
        />
        <Button
          variant="default"
          text="Update"
          type="submit"
          className="mt-25"
        />
      </form>
    </div>
  );
}
