import './payment.scss';
import { Helmet } from 'react-helmet-async';
import Checkout from '@/organisms/checkout';
import PaymentInfo from './payment-info';

export default function Payment() {
  return (
    <>
      <Helmet title="Complete booking | PawCare" />
      <Checkout
        showProgressBar
        progressBarData={{ currentStep: 4, maxSteps: 4 }}
        hideInvoice={false}
        mobileTitle="Complete booking"
        mobileSubtitle="Your card will not be charged for the service until after your
          appointment."
      >
        <PaymentInfo />
      </Checkout>
    </>
  );
}
