import {
  promoCodeFailure,
  promoCodeRequest,
  promoCodeSuccess,
} from '@/store/reducers/invoice';

import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { fetchData } from './fetchData';

export const fetchPromoCodeData =
  (promoCode: any) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(promoCodeRequest());

    return fetchData({
      endpoint: `payment/promotion_code/${promoCode}`,
      actions: [promoCodeRequest, promoCodeSuccess, promoCodeFailure],
      httpMethod: 'get',
      showAPIerror: true,
    })?.(dispatch, getState)
      .then((response) => {
        // Handle the success case
        const promoData = response.data;
        sessionStorage.setItem('promoBannerText', promoData?.data?.bannerCopy);
        dispatch(promoCodeSuccess(promoData));
      })
      .catch((error) => {
        // Handle the error case
        console.error('Error fetching promo code data:', error);
        dispatch(promoCodeFailure(error));
      });
  };
