import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface RadioButtonGroupProps {
  options: any;
  value: any;
  setValue: any;
}

export default function RadioButtonGroup({
  options,
  value,
  setValue,
}: RadioButtonGroupProps) {
  const handleRadioChange = (event: any) => {
    setValue(event.target.value);
  };

  const hasOptions = !!options || Array.isArray(options) || options.length > 0;

  if (!hasOptions) return null;

  if (!value) setValue(options[0].value);

  return (
    <RadioGroup name="radio-group" value={value} onChange={handleRadioChange}>
      {options.map((option: any) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          label={option.label}
          labelPlacement="start"
          control={
            <Radio
              sx={{
                padding: 0,
                '& .MuiSvgIcon-root': {
                  fontSize: 24,
                  color: '#9191a8',
                },
                '&.Mui-checked': {
                  '& .MuiSvgIcon-root': {
                    color: '#ff9838',
                  },
                },
              }}
            />
          }
          sx={{
            width: '360px',
            padding: '16px',
            margin: 0,
            marginBottom: '12px',
            justifyContent: 'space-between',
            border: '1px solid var(--light-purple)',
            borderRadius: '100px',
            span: {
              color: '#150828',
              fontSize: '13px',
              fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
            },
            '&:has(.Mui-checked)': {
              borderColor: '#ff9838',
              backgroundColor: 'rgba(255, 152, 56, 0.05)',
              span: {
                color: '#ff9838',
                fontWeight: 700,
              },
            },
          }}
        />
      ))}
    </RadioGroup>
  );
}
