import { Helmet } from 'react-helmet-async';

import Landing from '@/organisms/landing';

import PhoneVerificationInfo from './phone-verification-info';

export default function PhoneVerification() {
  return (
    <>
      <Helmet title="Phone verification | PawCare">
        <link rel="canonical" href="https://mypawcare.com" />
      </Helmet>
      <Landing removeOutterPadding>
        <PhoneVerificationInfo />
      </Landing>
    </>
  );
}
