import {
  invoiceFailure,
  invoiceRequest,
  invoiceSuccess,
} from '@/store/reducers/invoice';
import {
  bootstrapFailure,
  bootstrapRequest,
  bootstrapSuccess,
} from '@/store/reducers/main';
import { userSetCredentials } from '@/store/reducers/user';

import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { logOut } from './authentication';
import { fetchData } from './fetchData';

export const loadBootstrap =
  () => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'bootstrap',
      actions: [bootstrapRequest, bootstrapSuccess, bootstrapFailure],
    })?.(dispatch, getState).then(({ success, data, status }: any) => {
      if (success) {
        if (data.data.user.credentials.token) {
          const { token, uuid } = data.data.user.credentials;
          dispatch(userSetCredentials({ token, uuid }));
          localStorage.setItem('homepageUrl', '/');
          localStorage.setItem('token', token);
        }
        return {
          success,
          data,
          isLoggedIn: !!data.data.user.credentials.token,
        };
      }
      if (status === 401) {
        logOut(dispatch);
      }
      return { success: false };
    });

export const sendAppDownloadSMS =
  (body: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'download/link',
      actions: [null, null, null],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);

export const searchConsumers =
  (keyword: string) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `search/consumers?q=${keyword}`,
      actions: [null, null, null],
      bypassImpersonation: true,
    })?.(dispatch, getState);

export const searchProviders =
  (data: any, searchParams: any) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const params = searchParams
      ? `&lat=${searchParams.lat}&long=${searchParams.long}`
      : '';
    const keyword = data ? `q=${data}` : '';
    return fetchData({
      endpoint: `search/providers?${keyword}${params}`,
      actions: [null, null, null],
      bypassImpersonation: true,
    })?.(dispatch, getState);
  };

export const getBlogPosts =
  () => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'cms/posts',
      actions: [null, null, null],
    })?.(dispatch, getState);

export const servicePriceCheck = ({ suid, discount }: any) => {
  const promoCode = discount || sessionStorage.getItem('promoCode');
  const promoQuery = promoCode ? `?promotion_code=${promoCode}` : '';
  return (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `service/${suid}/price/check${promoQuery}`,
      actions: [invoiceRequest, invoiceSuccess, invoiceFailure],
    })?.(dispatch, getState);
};

export const storeAddress =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    // eslint-disable-next-line camelcase
    const { latitude, longitude, locality, region, postalCode, address_1 } =
      getState().advancedSearch;
    const body = {
      country_id: 238,
      latitude,
      longitude,
      locality,
      region,
      postalCode,
    };

    // eslint-disable-next-line camelcase
    if (address_1 !== 'invalid_address') {
      // eslint-disable-next-line camelcase
      (body as any).address = address_1;
    }
    return fetchData({
      endpoint: `search/address`,
      actions: [null, null, null],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);
  };
