export default function Visa() {
  return (
    <svg
      width="36"
      height="25"
      viewBox="0 0 36 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="319">
        <g id="New Icons">
          <g id="Card Brands">
            <g id="Color">
              <g id="Visa">
                <path
                  id="Container"
                  d="M32.625 0.875H3.375C1.71815 0.875 0.375 2.21815 0.375 3.875V21.125C0.375 22.7819 1.71815 24.125 3.375 24.125H32.625C34.2819 24.125 35.625 22.7819 35.625 21.125V3.875C35.625 2.21815 34.2819 0.875 32.625 0.875Z"
                  fill="white"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.5"
                />
                <path
                  id="Shape"
                  d="M4.1816 9.37166C3.39689 8.94126 2.50131 8.59511 1.5 8.35489L1.542 8.16782H5.64747C6.20394 8.18733 6.65549 8.35476 6.80242 8.94607L7.69465 13.1999L7.96798 14.4811L10.4669 8.16782H13.1652L9.15432 17.4163H6.45595L4.1816 9.37166ZM15.15 17.4261H12.5982L14.1943 8.16782H16.7459L15.15 17.4261ZM24.4002 8.39416L24.0531 10.3934L23.8223 10.2951C23.3606 10.1079 22.7511 9.9208 21.9216 9.94057C20.9141 9.94057 20.4623 10.3442 20.4518 10.7382C20.4518 11.1716 20.9984 11.4573 21.8909 11.8809C23.361 12.5408 24.043 13.3483 24.0328 14.4023C24.0121 16.323 22.269 17.5641 19.5916 17.5641C18.4468 17.5541 17.3442 17.3271 16.7457 17.0714L17.1028 14.9931L17.4388 15.141C18.2683 15.4861 18.8142 15.6335 19.833 15.6335C20.5676 15.6335 21.3553 15.3476 21.3654 14.7273C21.3654 14.3235 21.0298 14.0278 20.0426 13.5747C19.0767 13.1313 17.7853 12.3927 17.8062 11.063C17.8171 9.26064 19.5916 8 22.1115 8C23.0986 8 23.8969 8.20683 24.4002 8.39416ZM27.7916 14.1462H29.9126C29.8077 13.6833 29.3244 11.4672 29.3244 11.4672L29.1461 10.6695C29.0201 11.0141 28.7998 11.5756 28.8104 11.5558C28.8104 11.5558 28.0018 13.6144 27.7916 14.1462ZM30.9414 8.16782L33 17.426H30.6374C30.6374 17.426 30.4061 16.3622 30.3329 16.0372H27.0567C26.9619 16.2833 26.5211 17.426 26.5211 17.426H23.8437L27.634 8.93599C27.8966 8.33512 28.359 8.16782 28.9676 8.16782H30.9414Z"
                  fill="#171E6C"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
