import './signup.scss';

import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import Checkout from '@/organisms/checkout';
import Landing from '@/organisms/landing';
import SignupDetails from './signup-details';

export default function Signup() {
  const [searchParams] = useSearchParams();
  const showAppointmentPanel = searchParams.get('appointment');

  const LayoutComponent = showAppointmentPanel ? Checkout : Landing;
  return (
    <>
      <Helmet title="Signup | PawCare">
        <link rel="canonical" href="https://mypawcare.com/signup" />
      </Helmet>
      <LayoutComponent removeOutterPadding>
        <SignupDetails showAppointmentPanel={!!showAppointmentPanel} />
      </LayoutComponent>
    </>
  );
}
