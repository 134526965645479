import './select-button.scss';

import classNames from 'classnames';
import { MouseEvent, ReactNode } from 'react';

interface SelectButtonProps {
  children: ReactNode;
  onClick: (value: any, e: MouseEvent) => void;
  value: any;
  isActive: boolean;
  className?: string;
  size?: string;
}

export default function SelectButton({
  children,
  onClick,
  value,
  isActive,
  className,
  size,
}: SelectButtonProps) {
  return (
    <button
      className={classNames(
        'select-button flex-row align-center justify-center text-black',
        isActive && 'select-button--active',
        className,
        `select-button--${size}`,
      )}
      onClick={(e) => {
        onClick(value, e);
      }}
    >
      {children}
    </button>
  );
}
