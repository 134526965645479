import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';

import Button from '@/atoms/button';
import { toggleVerifyNumberModal } from '@/store/reducers/ui';

export default function VerifyNumberModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.ui.verifyNumberModal);

  const onVerify = () => {
    navigate('/signup/phone-verification');
    onClose();
  };

  const onClose = () => {
    dispatch(toggleVerifyNumberModal({ isOpen: false }));
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen as boolean}
      onRequestClose={onClose}
      className="cancel-success-modal"
    >
      <div className="text-center flex-column align-center">
        <h2 className="mb-15 f26">Verify phone number</h2>
        <p className="mb-32 f15">
          Your phone number has been changed. Please verify your new phone
          number.
        </p>
        <Button size="large" onClick={onVerify} text="Verify" />
      </div>
    </ReactModal>
  );
}
