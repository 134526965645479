import Slot from '@/atoms/slot';

interface Props {
  calendar: any;
  item: any;
  selectSlot: (data: any) => void;
  onViewMore: (item: any) => void;
  maxSlots: number;
  className?: string;
  emptySlotAction?: (value: any) => void;
  viewMoreClassName?: string;
  stopPropagation: boolean;
}
export function Slots({
  calendar,
  item,
  selectSlot,
  onViewMore,
  maxSlots,
  className,
  emptySlotAction,
  viewMoreClassName,
  stopPropagation,
}: Props) {
  const appointmentDays = calendar.filter(
    (el: any) => el.provider.location.uuid === item.uuid,
  );

  const onSlotWithTimeClick = (data: any) => {
    selectSlot(data);
  };

  const onViewMoreClick = () => {
    onViewMore(item);
  };

  const onEmptySlotClick = (value: any) => {
    if (emptySlotAction) {
      emptySlotAction(value);
    }
  };

  if (appointmentDays.length === 0) {
    return (
      <Slot
        emptySlotAction={onEmptySlotClick}
        location={item}
        emptySlotText="Request appointment"
        key="no-slot"
        className={className}
        stopPropagation
      />
    );
  }

  const availableSlots: any[] = [];
  appointmentDays.forEach((day: any, indexDay: any) => {
    day.time.forEach((time: any, indexTime: any) => {
      availableSlots.push(
        <Slot
          id={`${item.uuid}-${indexTime}${indexDay}`}
          key={Math.random() * 10000}
          time={time}
          date={day.date}
          location={item}
          onClick={onSlotWithTimeClick}
          stopPropagation={stopPropagation}
        />,
      );
    });
  });

  if (maxSlots) {
    const slots: any[] = availableSlots.slice(0, maxSlots);
    slots.push(
      <Slot
        key="view-more"
        id="view-more"
        emptySlotText="View more"
        className={viewMoreClassName}
        emptySlotAction={onViewMoreClick}
        stopPropagation={stopPropagation}
      />,
    );
    return slots;
  }
  return availableSlots;
}
