import './index.css';

import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './app';
import store from './store';

const gtmId = 'GTM-NZT4C7M';
const tagManagerArgs = { gtmId };

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <HelmetProvider>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </HelmetProvider>,
);
