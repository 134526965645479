import dayjs from 'dayjs';

export const groomerSubtitle = (groomer: any, userAnimalType: any) => {
  const { nextAvailableDate, nextAvailableTime, animal } = groomer;
  const caredForPets = animal.type.filter(
    (el: any) => el.id === userAnimalType.id,
  );
  if (caredForPets.length === 0) {
    return `Not available for ${userAnimalType.value.toLowerCase()}s`;
  }

  if (nextAvailableDate && nextAvailableTime) {
    const date = `${nextAvailableDate}T${nextAvailableTime}`;
    return `Next available: ${dayjs(date).format('MMM D, h:mm a')}`;
  }

  return '';
};

export const isGroomerDisabled = (groomer: any, userAnimalType: any) => {
  const { animal } = groomer;
  const caredForPets = animal.type.filter(
    (el: any) => el.id === userAnimalType?.id,
  );
  return caredForPets.length === 0;
};

export const filterGroomersWithoutAvailability = (groomers: any) =>
  groomers.filter(
    (groomer: any) => groomer.nextAvailableDate && groomer.nextAvailableTime,
  );

export const filterGroomersByAnimalType = (groomers: any, animalType: any) =>
  groomers.filter((groomer: any) =>
    groomer.animal.type.some((type: any) => type.id === animalType.id),
  );
