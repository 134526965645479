export const hoursOptions = [
  { value: 'Closed', valueFormatted: 'closed' },
  { value: '5:00 am', valueFormatted: '05:00:00' },
  { value: '5:15 am', valueFormatted: '05:15:00' },
  { value: '5:30 am', valueFormatted: '05:30:00' },
  { value: '5:45 am', valueFormatted: '05:45:00' },
  { value: '6:00 am', valueFormatted: '06:00:00' },
  { value: '6:15 am', valueFormatted: '06:15:00' },
  { value: '6:30 am', valueFormatted: '06:30:00' },
  { value: '6:45 am', valueFormatted: '06:45:00' },
  { value: '7:00 am', valueFormatted: '07:00:00' },
  { value: '7:15 am', valueFormatted: '07:15:00' },
  { value: '7:30 am', valueFormatted: '07:30:00' },
  { value: '7:45 am', valueFormatted: '07:45:00' },
  { value: '8:00 am', valueFormatted: '08:00:00' },
  { value: '8:15 am', valueFormatted: '08:15:00' },
  { value: '8:30 am', valueFormatted: '08:30:00' },
  { value: '8:45 am', valueFormatted: '08:45:00' },
  { value: '9:00 am', valueFormatted: '09:00:00' },
  { value: '9:15 am', valueFormatted: '09:15:00' },
  { value: '9:30 am', valueFormatted: '09:30:00' },
  { value: '9:45 am', valueFormatted: '09:45:00' },
  { value: '10:00 am', valueFormatted: '10:00:00' },
  { value: '10:15 am', valueFormatted: '10:15:00' },
  { value: '10:30 am', valueFormatted: '10:30:00' },
  { value: '10:45 am', valueFormatted: '10:45:00' },
  { value: '11:00 am', valueFormatted: '11:00:00' },
  { value: '11:15 am', valueFormatted: '11:15:00' },
  { value: '11:30 am', valueFormatted: '11:30:00' },
  { value: '11:45 am', valueFormatted: '11:45:00' },
  { value: '12:00 pm', valueFormatted: '12:00:00' },
  { value: '12:15 pm', valueFormatted: '12:15:00' },
  { value: '12:30 pm', valueFormatted: '12:30:00' },
  { value: '12:45 pm', valueFormatted: '12:45:00' },
  { value: '1:00 pm', valueFormatted: '13:00:00' },
  { value: '1:15 pm', valueFormatted: '13:15:00' },
  { value: '1:30 pm', valueFormatted: '13:30:00' },
  { value: '1:45 pm', valueFormatted: '13:45:00' },
  { value: '2:00 pm', valueFormatted: '14:00:00' },
  { value: '2:15 pm', valueFormatted: '14:15:00' },
  { value: '2:30 pm', valueFormatted: '14:30:00' },
  { value: '2:45 pm', valueFormatted: '14:45:00' },
  { value: '3:00 pm', valueFormatted: '15:00:00' },
  { value: '3:15 pm', valueFormatted: '15:15:00' },
  { value: '3:30 pm', valueFormatted: '15:30:00' },
  { value: '3:45 pm', valueFormatted: '15:45:00' },
  { value: '4:00 pm', valueFormatted: '16:00:00' },
  { value: '4:15 pm', valueFormatted: '16:15:00' },
  { value: '4:30 pm', valueFormatted: '16:30:00' },
  { value: '4:45 pm', valueFormatted: '16:45:00' },
  { value: '5:00 pm', valueFormatted: '17:00:00' },
  { value: '5:15 pm', valueFormatted: '17:15:00' },
  { value: '5:30 pm', valueFormatted: '17:30:00' },
  { value: '5:45 pm', valueFormatted: '17:45:00' },
  { value: '6:00 pm', valueFormatted: '18:00:00' },
  { value: '6:15 pm', valueFormatted: '18:15:00' },
  { value: '6:30 pm', valueFormatted: '18:30:00' },
  { value: '6:45 pm', valueFormatted: '18:45:00' },
  { value: '7:00 pm', valueFormatted: '19:00:00' },
  { value: '7:15 pm', valueFormatted: '19:15:00' },
  { value: '7:30 pm', valueFormatted: '19:30:00' },
  { value: '7:45 pm', valueFormatted: '19:45:00' },
  { value: '8:00 pm', valueFormatted: '20:00:00' },
  { value: '8:15 pm', valueFormatted: '20:15:00' },
  { value: '8:30 pm', valueFormatted: '20:30:00' },
  { value: '8:45 pm', valueFormatted: '20:45:00' },
  { value: '9:00 pm', valueFormatted: '21:00:00' },
  { value: '9:15 pm', valueFormatted: '21:15:00' },
  { value: '9:30 pm', valueFormatted: '21:30:00' },
  { value: '9:45 pm', valueFormatted: '21:45:00' },
  { value: '10:00 pm', valueFormatted: '22:00:00' },
  { value: '10:15 pm', valueFormatted: '22:15:00' },
  { value: '10:30 pm', valueFormatted: '22:30:00' },
  { value: '10:45 pm', valueFormatted: '22:45:00' },
  { value: '11:00 pm', valueFormatted: '23:00:00' },
  { value: '11:15 pm', valueFormatted: '23:15:00' },
  { value: '11:30 pm', valueFormatted: '23:30:00' },
  { value: '11:45 pm', valueFormatted: '23:45:00' },
  { value: '12:00 am', valueFormatted: '00:00:00' },
  { value: '12:15 am', valueFormatted: '00:15:00' },
  { value: '12:30 am', valueFormatted: '00:30:00' },
  { value: '12:45 am', valueFormatted: '00:45:00' },
  { value: '1:00 am', valueFormatted: '01:00:00' },
  { value: '1:15 am', valueFormatted: '01:15:00' },
  { value: '1:30 am', valueFormatted: '01:30:00' },
  { value: '1:45 am', valueFormatted: '01:45:00' },
  { value: '2:00 am', valueFormatted: '02:00:00' },
  { value: '2:15 am', valueFormatted: '02:15:00' },
  { value: '2:30 am', valueFormatted: '02:30:00' },
  { value: '2:45 am', valueFormatted: '02:45:00' },
  { value: '3:00 am', valueFormatted: '03:00:00' },
  { value: '3:15 am', valueFormatted: '03:15:00' },
  { value: '3:30 am', valueFormatted: '03:30:00' },
  { value: '3:45 am', valueFormatted: '03:45:00' },
  { value: '4:00 am', valueFormatted: '04:00:00' },
  { value: '4:15 am', valueFormatted: '04:15:00' },
  { value: '4:30 am', valueFormatted: '04:30:00' },
  { value: '4:45 am', valueFormatted: '04:45:00' },
];

export const onlyHoursOptions = hoursOptions.filter(
  (el) => el.value !== 'Closed',
);

export const currentYear = new Date().getFullYear();

export const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const chicagoLatLng = { lat: 41.8781, lng: -87.6298 };

export const mobileGroomerId = 4;

export const checkoutFlowURLs = [
  '/signup/pet-details',
  '/signup/user-details',
  '/signup/booking-payment',
  '/signup/address',
];
