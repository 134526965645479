function PawFilled({ width = 52, height = 52 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 52 52" fill="none">
      <g clipPath="url(clip0_0}">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.1624 10.3301C28.3534 14.4229 25.3192 17.3665 22.3854 16.9048C19.4516 16.4431 17.7291 12.751 18.5382 8.65818C19.3473 4.56538 22.3814 1.62178 25.3152 2.08348C28.249 2.54517 29.9715 6.23733 29.1624 10.3301ZM41.6961 17.5766C39.8841 21.3499 36.2678 23.3415 33.6188 22.0249C30.9699 20.7083 30.2914 16.5821 32.1035 12.8088C33.9155 9.03547 37.5318 7.04389 40.1808 8.36048C42.8297 9.67707 43.5082 13.8033 41.6961 17.5766ZM39.233 35.0949C41.6928 36.3174 44.9925 34.5895 46.6032 31.2354C48.2139 27.8814 47.5256 24.1713 45.0659 22.9487C42.6061 21.7262 39.3064 23.4541 37.6957 26.8082C36.085 30.1623 36.7733 33.8723 39.233 35.0949ZM15.5804 15.9144C15.0432 19.583 12.468 22.349 9.82839 22.0923C7.18879 21.8356 5.48442 18.6535 6.02155 14.9849C6.55869 11.3163 9.13394 8.55034 11.7735 8.80702C14.4131 9.0637 16.1175 12.2458 15.5804 15.9144ZM26.5884 49.6728C30.2036 49.2367 33.0521 45.9237 34.1588 39.1015C35.2655 32.2794 33.4941 24.2944 26.4452 21.4465C19.3963 18.5985 12.5753 23.1119 8.63231 28.7882C4.68933 34.4644 4.4261 38.8353 6.73452 41.6513C7.64459 42.7615 9.86471 42.7524 12.2213 42.7426C14.1369 42.7347 16.1426 42.7264 17.6083 43.3186C19.0752 43.9113 20.5173 45.3139 21.8933 46.6523C23.5836 48.2964 25.1742 49.8434 26.5884 49.6728Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PawFilled;
