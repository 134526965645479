import { useAppSelector } from '@/hooks';

import LocationSearch from '@/atoms/location-search';
import { editAdvancedSearch } from '@/store/reducers/advanced-search';

export default function LocationFilter() {
  const advancedSearch = useAppSelector((state) => state.advancedSearch);

  // Check if address_1 is valid and does not contain 'Invalid_address'
  const isValidAddress1 =
    advancedSearch.address_1 &&
    !advancedSearch.address_1.includes('invalid_address');

  // Build the address array, conditionally including address_1
  const addressParts = [
    ...(isValidAddress1 ? [advancedSearch.address_1] : []),
    advancedSearch.locality,
    advancedSearch.region,
    advancedSearch.postalCode,
  ].filter(Boolean); // Filter out falsy values (empty, null, undefined, etc.)

  // Join the parts with a comma
  const addressString = addressParts.join(', ');

  return (
    <LocationSearch
      hideLabel
      size="small"
      editLocation={editAdvancedSearch}
      defaultValue={addressString}
      placeholder="My address or zipcode"
    />
  );
}
