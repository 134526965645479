import './groomer-select.scss';
import { Helmet } from 'react-helmet-async';
import Checkout from '@/organisms/checkout';
import GroomerInfo from './groomer-info';

export default function GroomerSelect() {
  return (
    <>
      <Helmet title="Select a groomer for your booking | PawCare" />
      <Checkout>
        <GroomerInfo />
      </Checkout>
    </>
  );
}
