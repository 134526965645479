import { paymentInitSuccess } from '@/store/reducers/main';
import { paymentMethodSuccess } from '@/store/reducers/user';

import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { fetchData } from './fetchData';

export const paymentMethod =
  (uuid: string) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `user/${uuid}/payment/methods`,
      actions: [null, paymentMethodSuccess, null],
    })?.(dispatch, getState);

export const paymentInit =
  () => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'payment/card/init',
      actions: [null, paymentInitSuccess, null],
      httpMethod: 'post',
    })?.(dispatch, getState);
