import { useAppSelector } from '@/hooks';
import dayjs from 'dayjs';
import ListItem from '@/organisms/list-item';
import { capitalizeFirstLetter } from '@/util';
import { groomerSubtitle } from './util';

interface GroomersPerAvailabilityProps {
  currentAvailableGroomers: any[];
  futureAvailableGroomers: any[];
  selectGroomer: (groomer: any) => void;
  userPetType: string;
}

export default function GroomersPerAvailability({
  currentAvailableGroomers,
  futureAvailableGroomers,
  selectGroomer,
  userPetType,
}: GroomersPerAvailabilityProps) {
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const { locationUser, dateStart } = useAppSelector(
    (state) => state.requestAppointmentForm,
  );

  return (
    <div>
      <h4>Available on {dayjs(dateStart).format('MMM DD')}</h4>
      <ul className="no-style-ul flex row">
        {currentAvailableGroomers.length > 1 && (
          <ListItem
            title="Any groomer"
            avatar=""
            id="any"
            onClick={selectGroomer}
            isActive={locationUser === 'any'}
          />
        )}
        {currentAvailableGroomers.length > 0 ? (
          currentAvailableGroomers.map((groomer) => (
            <ListItem
              key={groomer.user?.uuid}
              title={capitalizeFirstLetter(groomer.user.firstName)}
              subtitle={groomerSubtitle(groomer, userPetType)}
              avatar={groomer.user?.links?.image?.desktop?.large}
              id={groomer.user?.uuid}
              onClick={selectGroomer}
              item={groomer}
              isActive={locationUser.user?.uuid === groomer.user?.uuid}
            />
          ))
        ) : (
          <>
            <p>
              On this date there are no groomers available for{' '}
              {activePet?.type?.value?.toLowerCase()}s.
            </p>
            {futureAvailableGroomers.length > 0 && (
              <p>Please select a groomer below.</p>
            )}
          </>
        )}
      </ul>
      {futureAvailableGroomers.length > 0 && (
        <>
          <h4 className="mt-30">Available soon</h4>
          <ul className="no-style-ul flex row">
            {futureAvailableGroomers.map((groomer) => (
              <ListItem
                key={groomer.user?.uuid}
                title={capitalizeFirstLetter(groomer.user?.firstName)}
                subtitle={groomerSubtitle(groomer, userPetType)}
                avatar={groomer.user?.links?.image?.desktop?.large}
                id={groomer.user?.uuid}
                onClick={selectGroomer}
                item={groomer}
                isActive={locationUser.user?.uuid === groomer.user?.uuid}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
