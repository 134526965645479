import { generateKey, prettifyHour } from '@/util';

export default function Hours({ dowOptions, hours }: any) {
  return (
    <div className="flex-column hours-container">
      {dowOptions.map((el: any) => {
        const filteredData = hours.filter(
          (hour: any) => hour.dow.value === el.value,
        );
        const hourBlock = filteredData[0];
        return (
          <div key={generateKey()} className="flex-row mb-10 justify-between">
            <span className="mr-15 day mobile">
              {hourBlock ? hourBlock.dow.value : el.value}
            </span>
            <span className="w100 mobile">
              {hourBlock
                ? `${prettifyHour(hourBlock.time.start)} - ${prettifyHour(hourBlock.time.end)}`
                : 'Closed'}
            </span>
          </div>
        );
      })}
    </div>
  );
}
