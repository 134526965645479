import { ReactNode } from 'react';

export default function Section({
  title,
  children,
}: {
  title: string;
  children?: ReactNode;
}) {
  return (
    <div className="mb-20">
      <p>
        <strong>{title}</strong>
        {children}
      </p>
    </div>
  );
}
