export const cards = [
  {
    id: 1,
    title: 'Be decisive',
    text: "Make decisions quickly and confidently, even when there is no clear path forward. Learn from your mistakes and don't be afraid to take risks.",
  },
  {
    id: 2,
    title: 'Deliver results',
    text: 'Set ambitious goals and work tirelessly to achieve them. Be accountable for your actions and never give up on your commitments',
  },
  {
    id: 3,
    title: 'Think big',
    text: "Have a grand vision for the future and always strive to achieve more than you think is possible. Don't be afraid to think outside the box and challenge the status quo.",
  },
  {
    id: 4,
    title: 'Embrace ambiguity',
    text: "See challenges as opportunities for creativity and innovation. Don't be afraid to step outside your comfort zone and try new things.",
  },
  {
    id: 5,
    title: 'Have grit',
    text: 'Never give up on your goals, no matter how difficult they may seem. Be persistent and determined, even in the face of adversity.',
  },
  {
    id: 6,
    title: 'Be uncomfortable',
    text: "Get out of your comfort zone and challenge yourself to grow. Embrace discomfort as a sign that you're pushing yourself to be better.",
  },
  {
    id: 7,
    title: 'Radically transparent',
    text: "Be honest and open with your colleagues and customers. Communicate openly and honestly, even when it's difficult.",
  },
  {
    id: 8,
    title: 'Disagree but commit',
    text: 'Even when you disagree with a decision, support your colleagues and commit to making it a success. Be willing to put your ego aside and work together for the common good.',
  },
  {
    id: 9,
    title: 'Treat everyone with respect',
    text: "Treat everyone with dignity and consideration, regardless of their position or title. Be kind and compassionate, even when it's difficult.",
  },
  {
    id: 10,
    title: 'Be agile',
    text: " Be able to adapt to change quickly and easily. Don't be afraid to try new things and experiment. Be willing to fail and learn from your mistakes.",
  },
];
