function AppleStore() {
  return (
    <svg
      width="192"
      height="64"
      viewBox="0 0 192 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3801_36492)">
        <path
          d="M176.216 0.000208H15.2555C14.6688 0.000208 14.0891 0.000208 13.5039 0.003408C13.0141 0.006608 12.5281 0.015904 12.0336 0.023728C10.9592 0.0363709 9.88737 0.13089 8.82736 0.306464C7.76884 0.48586 6.74348 0.824073 5.78595 1.30966C4.8296 1.79936 3.95575 2.43568 3.19611 3.19552C2.43248 3.95323 1.79594 4.82903 1.31096 5.78926C0.82464 6.74758 0.487425 7.77451 0.31096 8.83458C0.132818 9.8933 0.0369574 10.9642 0.02424 12.0378C0.009392 12.5283 0.007824 13.0205 0 13.5112V50.494C0.007824 50.9908 0.009392 51.472 0.02424 51.969C0.0369613 53.0425 0.132821 54.1134 0.31096 55.172C0.486938 56.2327 0.824173 57.2602 1.31096 58.2189C1.79572 59.176 2.43235 60.0483 3.19611 60.8018C3.95287 61.565 4.82728 62.2018 5.78595 62.6877C6.74347 63.1746 7.76872 63.5148 8.82736 63.697C9.88755 63.8712 10.9593 63.9657 12.0336 63.9799C12.5281 63.9908 13.0141 63.997 13.5039 63.997C14.0891 64.0002 14.6688 64.0002 15.2555 64.0002H176.216C176.791 64.0002 177.375 64.0002 177.95 63.997C178.438 63.997 178.938 63.9908 179.425 63.9799C180.497 63.9665 181.567 63.8719 182.625 63.697C183.687 63.5136 184.716 63.1734 185.678 62.6877C186.636 62.2015 187.51 61.5648 188.266 60.8018C189.028 60.0454 189.665 59.1737 190.156 58.2189C190.639 57.2595 190.973 56.2321 191.147 55.172C191.325 54.1132 191.425 53.0426 191.444 51.969C191.45 51.472 191.45 50.9908 191.45 50.494C191.463 49.9127 191.463 49.3346 191.463 48.7439V15.258C191.463 14.6721 191.463 14.0908 191.45 13.5112C191.45 13.0205 191.45 12.5283 191.444 12.0377C191.425 10.9641 191.325 9.89338 191.147 8.83451C190.973 7.77506 190.639 6.74821 190.156 5.7892C189.169 3.86452 187.603 2.29785 185.678 1.30952C184.716 0.825116 183.687 0.486993 182.625 0.30632C181.567 0.129972 180.497 0.0354204 179.425 0.023504C178.938 0.015696 178.438 0.00632 177.95 0.0032C177.375 0 176.791 0.000208 176.216 0.000208Z"
          fill="#A6A6A6"
        />
        <path
          d="M13.5119 62.6C13.0244 62.6 12.5487 62.5938 12.065 62.5829C11.0631 62.5698 10.0635 62.4826 9.0744 62.3219C8.15214 62.1631 7.25872 61.8676 6.42361 61.4453C5.59616 61.0264 4.84146 60.4772 4.18841 59.8187C3.52592 59.168 2.97436 58.4132 2.5556 57.5843C2.13222 56.7501 1.83922 55.8558 1.6868 54.9328C1.5222 53.941 1.43314 52.9381 1.4204 51.9328C1.41025 51.5954 1.39697 50.4718 1.39697 50.4718V13.5109C1.39697 13.5109 1.41112 12.4047 1.42048 12.0797C1.43268 11.076 1.52122 10.0746 1.68532 9.08437C1.83803 8.1588 2.13126 7.262 2.55486 6.42501C2.97208 5.59671 3.52058 4.84138 4.17908 4.18829C4.83685 3.52899 5.59397 2.97697 6.42283 2.55234C7.25602 2.13135 8.14775 1.83797 9.06814 1.68203C10.0605 1.51974 11.0635 1.43199 12.069 1.41954L13.5127 1.4H177.942L179.403 1.42032C180.4 1.43216 181.394 1.51912 182.377 1.68046C183.306 1.83836 184.207 2.13378 185.05 2.55702C186.711 3.41278 188.062 4.76666 188.914 6.42891C189.331 7.26012 189.62 8.14961 189.771 9.06718C189.937 10.0656 190.03 11.0748 190.049 12.0867C190.053 12.5398 190.053 13.0266 190.053 13.5109C190.066 14.1109 190.066 14.682 190.066 15.2578V48.7437C190.066 49.325 190.066 49.8922 190.053 50.464C190.053 50.9843 190.053 51.461 190.047 51.9515C190.028 52.9454 189.937 53.9366 189.774 54.9171C189.624 55.8468 189.333 56.748 188.91 57.5891C188.488 58.409 187.939 59.1572 187.285 59.8062C186.631 60.4683 185.875 61.0207 185.046 61.4422C184.205 61.8678 183.305 62.1644 182.377 62.3219C181.388 62.4835 180.388 62.5707 179.386 62.5829C178.917 62.5938 178.427 62.6 177.95 62.6L176.216 62.6032L13.5119 62.6Z"
          fill="black"
        />
        <path
          d="M39.6303 32.4811C39.6475 31.1456 40.0022 29.8362 40.6615 28.6747C41.3207 27.5132 42.263 26.5373 43.4008 25.8378C42.678 24.8055 41.7245 23.956 40.616 23.3567C39.5074 22.7575 38.2745 22.4249 37.0149 22.3855C34.3281 22.1035 31.7234 23.9932 30.3543 23.9932C28.9587 23.9932 26.8507 22.4135 24.5807 22.4602C23.1124 22.5076 21.6815 22.9346 20.4273 23.6995C19.173 24.4644 18.1384 25.5411 17.424 26.8248C14.3296 32.1823 16.6378 40.056 19.6019 44.3865C21.085 46.5071 22.8182 48.8758 25.0861 48.7918C27.3054 48.6998 28.1343 47.3766 30.8132 47.3766C33.4672 47.3766 34.2448 48.7918 36.5588 48.7384C38.9402 48.6997 40.4406 46.6084 41.8716 44.4678C42.9372 42.9568 43.7571 41.2868 44.3011 39.5198C42.9175 38.9346 41.7369 37.9551 40.9063 36.7034C40.0757 35.4518 39.6319 33.9833 39.6303 32.4811Z"
          fill="white"
        />
        <path
          d="M35.2599 19.5374C36.5584 17.9787 37.1981 15.9752 37.0432 13.9524C35.0594 14.1608 33.227 15.1089 31.911 16.6078C31.2676 17.3401 30.7748 18.192 30.4608 19.1148C30.1468 20.0376 30.0178 21.0133 30.0811 21.986C31.0733 21.9962 32.0549 21.7812 32.952 21.357C33.849 20.9329 34.6382 20.3107 35.2599 19.5374Z"
          fill="white"
        />
        <path
          d="M67.6835 43.4234H60.1101L58.2913 48.7938H55.0835L62.2569 28.925H65.5897L72.7632 48.7938H69.5007L67.6835 43.4234ZM60.8944 40.9453H66.8976L63.9383 32.2297H63.8554L60.8944 40.9453Z"
          fill="white"
        />
        <path
          d="M88.2552 41.5516C88.2552 46.0531 85.8458 48.9453 82.2099 48.9453C81.2889 48.9935 80.3729 48.7813 79.5668 48.3331C78.7607 47.8849 78.0972 47.2187 77.6521 46.4109H77.5833V53.5859H74.6099V34.3078H77.488V36.7172H77.5427C78.0083 35.9132 78.683 35.2505 79.4952 34.7996C80.3074 34.3486 81.2267 34.1263 82.1552 34.1562C85.8317 34.1563 88.2552 37.0625 88.2552 41.5516ZM85.199 41.5516C85.199 38.6188 83.6833 36.6906 81.3708 36.6906C79.099 36.6906 77.5708 38.6594 77.5708 41.5516C77.5708 44.4703 79.099 46.425 81.3708 46.425C83.6833 46.425 85.199 44.5109 85.199 41.5516Z"
          fill="white"
        />
        <path
          d="M104.199 41.5516C104.199 46.0531 101.79 48.9453 98.1538 48.9453C97.2327 48.9935 96.3167 48.7813 95.5106 48.3331C94.7046 47.8849 94.041 47.2187 93.596 46.4109H93.5271V53.5859H90.5537V34.3078H93.4318V36.7172H93.4865C93.952 35.9132 94.6268 35.2505 95.439 34.7996C96.2512 34.3486 97.1705 34.1263 98.099 34.1563C101.776 34.1563 104.199 37.0625 104.199 41.5516ZM101.143 41.5516C101.143 38.6188 99.6272 36.6906 97.3147 36.6906C95.0428 36.6906 93.5147 38.6594 93.5147 41.5516C93.5147 44.4703 95.0428 46.425 97.3147 46.425C99.6272 46.425 101.143 44.5109 101.143 41.5516Z"
          fill="white"
        />
        <path
          d="M114.737 43.2578C114.957 45.2281 116.871 46.5218 119.487 46.5218C121.993 46.5218 123.796 45.2281 123.796 43.4515C123.796 41.9093 122.709 40.9859 120.134 40.3531L117.559 39.7327C113.91 38.8515 112.216 37.1452 112.216 34.3765C112.216 30.9484 115.204 28.5937 119.446 28.5937C123.644 28.5937 126.523 30.9484 126.619 34.3765H123.618C123.438 32.3937 121.799 31.1968 119.404 31.1968C117.009 31.1968 115.369 32.4077 115.369 34.1702C115.369 35.5749 116.416 36.4015 118.977 37.0342L121.166 37.5717C125.243 38.5358 126.937 40.1733 126.937 43.0796C126.937 46.7967 123.976 49.1249 119.266 49.1249C114.86 49.1249 111.885 46.8514 111.693 43.2577L114.737 43.2578Z"
          fill="white"
        />
        <path
          d="M133.354 30.8797V34.3078H136.109V36.6625H133.354V44.6484C133.354 45.8891 133.906 46.4672 135.116 46.4672C135.444 46.4615 135.77 46.4385 136.095 46.3984V48.739C135.55 48.8407 134.997 48.8868 134.443 48.8765C131.51 48.8765 130.366 47.7749 130.366 44.9656V36.6625H128.26V34.3078H130.366V30.8797H133.354Z"
          fill="white"
        />
        <path
          d="M137.704 41.5516C137.704 36.9938 140.388 34.1297 144.574 34.1297C148.774 34.1297 151.446 36.9937 151.446 41.5516C151.446 46.1219 148.788 48.9734 144.574 48.9734C140.362 48.9734 137.704 46.1219 137.704 41.5516ZM148.417 41.5516C148.417 38.425 146.984 36.5797 144.574 36.5797C142.165 36.5797 140.734 38.4391 140.734 41.5516C140.734 44.6906 142.165 46.5219 144.574 46.5219C146.984 46.5219 148.417 44.6906 148.417 41.5516Z"
          fill="white"
        />
        <path
          d="M153.897 34.3078H156.733V36.7734H156.802C156.994 36.0034 157.445 35.3228 158.08 34.8462C158.714 34.3696 159.494 34.1259 160.287 34.1562C160.629 34.155 160.971 34.1922 161.305 34.2672V37.0484C160.873 36.9162 160.421 36.8555 159.969 36.8687C159.537 36.8512 159.107 36.9274 158.707 37.092C158.307 37.2566 157.948 37.5058 157.653 37.8224C157.359 38.139 157.137 38.5156 157.002 38.9263C156.867 39.337 156.822 39.772 156.871 40.2015V48.7937H153.897L153.897 34.3078Z"
          fill="white"
        />
        <path
          d="M175.015 44.5391C174.615 47.1687 172.054 48.9734 168.777 48.9734C164.563 48.9734 161.948 46.15 161.948 41.6203C161.948 37.0766 164.577 34.1297 168.652 34.1297C172.66 34.1297 175.181 36.8828 175.181 41.275V42.2937H164.949V42.4734C164.902 43.0065 164.969 43.5436 165.145 44.049C165.321 44.5543 165.603 45.0164 165.972 45.4044C166.34 45.7925 166.787 46.0977 167.283 46.2998C167.778 46.5019 168.311 46.5962 168.846 46.5765C169.549 46.6424 170.254 46.4796 170.857 46.1125C171.459 45.7454 171.928 45.1936 172.192 44.539L175.015 44.5391ZM164.963 40.2156H172.206C172.232 39.7363 172.16 39.2566 171.992 38.8066C171.825 38.3567 171.566 37.9462 171.232 37.601C170.899 37.2558 170.497 36.9833 170.053 36.8005C169.609 36.6178 169.132 36.5287 168.652 36.5391C168.168 36.5362 167.688 36.6292 167.24 36.8128C166.792 36.9964 166.385 37.2669 166.042 37.6087C165.699 37.9505 165.427 38.3569 165.242 38.8043C165.057 39.2517 164.962 39.7314 164.963 40.2156Z"
          fill="white"
        />
        <path
          d="M60.5217 13.9696C61.1451 13.9249 61.7706 14.019 62.3532 14.2453C62.9358 14.4716 63.4609 14.8244 63.8906 15.2782C64.3203 15.732 64.644 16.2755 64.8382 16.8695C65.0325 17.4636 65.0925 18.0933 65.0139 18.7133C65.0139 21.7633 63.3654 23.5165 60.5217 23.5165H57.0732V13.9696H60.5217ZM58.556 22.1664H60.356C60.8015 22.193 61.2473 22.12 61.6609 21.9526C62.0746 21.7852 62.4457 21.5277 62.7473 21.1988C63.0489 20.8699 63.2734 20.4779 63.4043 20.0513C63.5353 19.6247 63.5695 19.1743 63.5045 18.7328C63.5648 18.2931 63.5271 17.8454 63.3941 17.422C63.2612 16.9985 63.0361 16.6097 62.7352 16.2834C62.4343 15.9571 62.065 15.7014 61.6537 15.5347C61.2423 15.3679 60.7992 15.2941 60.356 15.3187H58.556V22.1664Z"
          fill="white"
        />
        <path
          d="M66.6889 19.9109C66.6436 19.4375 66.6978 18.9598 66.8479 18.5085C66.9981 18.0571 67.2409 17.6422 67.5608 17.2902C67.8807 16.9383 68.2706 16.657 68.7056 16.4646C69.1406 16.2721 69.6109 16.1727 70.0866 16.1727C70.5622 16.1727 71.0326 16.2721 71.4676 16.4646C71.9025 16.657 72.2925 16.9383 72.6124 17.2902C72.9323 17.6422 73.1751 18.0571 73.3252 18.5085C73.4754 18.9598 73.5295 19.4375 73.4842 19.9109C73.5304 20.3849 73.4769 20.8633 73.3271 21.3153C73.1774 21.7673 72.9347 22.1831 72.6147 22.5357C72.2947 22.8884 71.9045 23.1702 71.4691 23.3631C71.0337 23.5559 70.5628 23.6555 70.0866 23.6555C69.6104 23.6555 69.1395 23.5559 68.7041 23.3631C68.2687 23.1702 67.8784 22.8884 67.5584 22.5357C67.2384 22.1831 66.9958 21.7673 66.846 21.3153C66.6963 20.8633 66.6428 20.3849 66.6889 19.9109ZM72.0217 19.9109C72.0217 18.3492 71.3202 17.436 70.0889 17.436C68.853 17.436 68.1577 18.3492 68.1577 19.911C68.1577 21.4852 68.853 22.3914 70.0889 22.3914C71.3202 22.3914 72.0217 21.4789 72.0217 19.9109Z"
          fill="white"
        />
        <path
          d="M82.517 23.5164H81.042L79.5529 18.2101H79.4404L77.9576 23.5164H76.4967L74.5107 16.3117H75.9529L77.2436 21.8093H77.3498L78.8311 16.3117H80.1951L81.6764 21.8093H81.7889L83.0732 16.3117H84.4951L82.517 23.5164Z"
          fill="white"
        />
        <path
          d="M86.1655 16.3117H87.5343V17.4562H87.6405C87.8208 17.0452 88.1248 16.7006 88.5102 16.4705C88.8956 16.2404 89.3432 16.1363 89.7905 16.1726C90.1411 16.1463 90.4931 16.1991 90.8204 16.3273C91.1478 16.4554 91.4421 16.6556 91.6816 16.913C91.9211 17.1703 92.0996 17.4783 92.204 17.814C92.3083 18.1497 92.3357 18.5046 92.2843 18.8523V23.5163H90.8624V19.2094C90.8624 18.0516 90.3593 17.4758 89.3077 17.4758C89.0697 17.4647 88.8321 17.5052 88.6112 17.5945C88.3903 17.6839 88.1914 17.8199 88.028 17.9933C87.8646 18.1667 87.7406 18.3734 87.6646 18.5992C87.5885 18.825 87.5622 19.0646 87.5874 19.3016V23.5164H86.1655L86.1655 16.3117Z"
          fill="white"
        />
        <path
          d="M94.5498 13.4992H95.9717V23.5164H94.5498V13.4992Z"
          fill="white"
        />
        <path
          d="M97.9482 19.9109C97.9029 19.4374 97.9571 18.9597 98.1073 18.5084C98.2575 18.0571 98.5003 17.6421 98.8202 17.2901C99.1402 16.9381 99.5302 16.6569 99.9652 16.4645C100.4 16.272 100.871 16.1726 101.346 16.1726C101.822 16.1726 102.292 16.272 102.727 16.4645C103.162 16.6569 103.552 16.9381 103.872 17.2901C104.192 17.6421 104.435 18.0571 104.585 18.5084C104.735 18.9597 104.79 19.4374 104.744 19.9109C104.79 20.3849 104.737 20.8633 104.587 21.3153C104.437 21.7674 104.194 22.1831 103.874 22.5357C103.554 22.8884 103.164 23.1702 102.729 23.363C102.293 23.5559 101.822 23.6555 101.346 23.6555C100.87 23.6555 100.399 23.5559 99.9636 23.363C99.5282 23.1702 99.138 22.8884 98.8179 22.5357C98.4979 22.1831 98.2552 21.7674 98.1054 21.3153C97.9556 20.8633 97.9021 20.3849 97.9482 19.9109ZM103.281 19.9109C103.281 18.3492 102.579 17.436 101.348 17.436C100.112 17.436 99.417 18.3492 99.417 19.911C99.417 21.4852 100.112 22.3914 101.348 22.3914C102.579 22.3914 103.281 21.4789 103.281 19.9109Z"
          fill="white"
        />
        <path
          d="M106.241 21.4789C106.241 20.182 107.207 19.4344 108.921 19.3281L110.872 19.2156V18.5938C110.872 17.8328 110.369 17.4031 109.397 17.4031C108.604 17.4031 108.054 17.6945 107.896 18.2039H106.519C106.665 16.9664 107.829 16.1727 109.463 16.1727C111.269 16.1727 112.288 17.0719 112.288 18.5938V23.5164H110.919V22.5039H110.807C110.578 22.8671 110.258 23.1632 109.878 23.3619C109.497 23.5607 109.071 23.6549 108.643 23.6351C108.34 23.6666 108.035 23.6343 107.745 23.5404C107.456 23.4465 107.19 23.293 106.964 23.0898C106.737 22.8867 106.556 22.6384 106.432 22.3609C106.307 22.0835 106.242 21.783 106.241 21.4789ZM110.872 20.8633V20.2609L109.113 20.3734C108.121 20.4398 107.671 20.7773 107.671 21.4125C107.671 22.0609 108.233 22.4383 109.007 22.4383C109.234 22.4612 109.462 22.4383 109.68 22.371C109.898 22.3036 110.1 22.1932 110.274 22.0463C110.448 21.8993 110.59 21.7189 110.693 21.5156C110.796 21.3124 110.857 21.0905 110.872 20.8633Z"
          fill="white"
        />
        <path
          d="M114.157 19.9109C114.157 17.6344 115.327 16.1922 117.147 16.1922C117.598 16.1714 118.045 16.2793 118.436 16.5031C118.827 16.727 119.146 17.0575 119.357 17.4562H119.463V13.4992H120.885V23.5164H119.522V22.3781H119.41C119.183 22.7741 118.853 23.1005 118.454 23.3221C118.055 23.5437 117.603 23.6519 117.147 23.6351C115.315 23.6352 114.157 22.193 114.157 19.9109ZM115.626 19.9109C115.626 21.4391 116.346 22.3586 117.551 22.3586C118.749 22.3586 119.49 21.4258 119.49 19.9172C119.49 18.4156 118.741 17.4695 117.551 17.4695C116.354 17.4695 115.626 18.3953 115.626 19.9109Z"
          fill="white"
        />
        <path
          d="M126.768 19.9109C126.722 19.4375 126.776 18.9598 126.927 18.5085C127.077 18.0571 127.32 17.6422 127.639 17.2902C127.959 16.9383 128.349 16.657 128.784 16.4646C129.219 16.2721 129.69 16.1727 130.165 16.1727C130.641 16.1727 131.111 16.2721 131.546 16.4646C131.981 16.657 132.371 16.9383 132.691 17.2902C133.011 17.6422 133.254 18.0571 133.404 18.5085C133.554 18.9598 133.608 19.4375 133.563 19.9109C133.609 20.3849 133.555 20.8633 133.406 21.3153C133.256 21.7673 133.013 22.1831 132.693 22.5357C132.373 22.8884 131.983 23.1702 131.548 23.3631C131.112 23.5559 130.641 23.6555 130.165 23.6555C129.689 23.6555 129.218 23.5559 128.783 23.3631C128.347 23.1702 127.957 22.8884 127.637 22.5357C127.317 22.1831 127.074 21.7673 126.925 21.3153C126.775 20.8633 126.721 20.3849 126.768 19.9109ZM132.1 19.9109C132.1 18.3492 131.399 17.436 130.168 17.436C128.932 17.436 128.236 18.3492 128.236 19.911C128.236 21.4852 128.932 22.3914 130.168 22.3914C131.399 22.3914 132.1 21.4789 132.1 19.9109Z"
          fill="white"
        />
        <path
          d="M135.471 16.3117H136.839V17.4562H136.946C137.126 17.0452 137.43 16.7006 137.815 16.4705C138.201 16.2404 138.648 16.1363 139.096 16.1726C139.446 16.1463 139.798 16.1991 140.126 16.3273C140.453 16.4554 140.747 16.6556 140.987 16.913C141.226 17.1703 141.405 17.4783 141.509 17.814C141.613 18.1497 141.641 18.5046 141.589 18.8523V23.5163H140.168V19.2094C140.168 18.0516 139.664 17.4758 138.613 17.4758C138.375 17.4647 138.137 17.5052 137.916 17.5945C137.696 17.6839 137.497 17.8199 137.333 17.9933C137.17 18.1667 137.046 18.3734 136.97 18.5992C136.894 18.825 136.867 19.0646 136.893 19.3016V23.5164H135.471V16.3117Z"
          fill="white"
        />
        <path
          d="M149.624 14.518V16.3445H151.185V17.5422H149.624V21.2469C149.624 22.0016 149.935 22.332 150.643 22.332C150.824 22.3315 151.005 22.3205 151.185 22.2992V23.4836C150.929 23.5293 150.671 23.5536 150.411 23.5562C148.83 23.5562 148.2 23 148.2 21.6109V17.5421H147.057V16.3445H148.2V14.518H149.624Z"
          fill="white"
        />
        <path
          d="M153.127 13.4992H154.536V17.4695H154.649C154.838 17.0546 155.15 16.7081 155.543 16.4771C155.936 16.2462 156.391 16.142 156.846 16.1789C157.194 16.1599 157.543 16.2181 157.867 16.3494C158.19 16.4807 158.481 16.6818 158.718 16.9383C158.955 17.1948 159.132 17.5005 159.238 17.8335C159.343 18.1665 159.373 18.5187 159.327 18.8648V23.5164H157.904V19.2156C157.904 18.0648 157.368 17.482 156.363 17.482C156.119 17.462 155.873 17.4956 155.643 17.5804C155.413 17.6653 155.204 17.7995 155.031 17.9734C154.858 18.1474 154.726 18.357 154.642 18.5877C154.559 18.8183 154.527 19.0643 154.549 19.3086V23.5164H153.127L153.127 13.4992Z"
          fill="white"
        />
        <path
          d="M167.618 21.5711C167.425 22.2296 167.006 22.7991 166.435 23.1802C165.865 23.5612 165.178 23.7294 164.496 23.6555C164.021 23.668 163.55 23.5771 163.114 23.389C162.678 23.201 162.288 22.9203 161.971 22.5665C161.655 22.2127 161.419 21.7942 161.28 21.3401C161.141 20.8859 161.103 20.4071 161.168 19.9367C161.105 19.4649 161.144 18.985 161.282 18.5295C161.42 18.0741 161.655 17.6536 161.97 17.2967C162.285 16.9398 162.673 16.6547 163.108 16.4608C163.542 16.2669 164.014 16.1686 164.49 16.1727C166.494 16.1727 167.704 17.5423 167.704 19.8047V20.3008H162.616V20.3805C162.594 20.6449 162.627 20.911 162.714 21.1618C162.801 21.4126 162.939 21.6425 163.119 21.8369C163.3 22.0312 163.519 22.1856 163.763 22.2903C164.007 22.3949 164.27 22.4474 164.535 22.4445C164.875 22.4853 165.22 22.4241 165.525 22.2685C165.83 22.113 166.082 21.8702 166.249 21.571L167.618 21.5711ZM162.616 19.2492H166.255C166.273 19.0074 166.24 18.7645 166.159 18.5362C166.077 18.3078 165.949 18.0991 165.782 17.9233C165.615 17.7475 165.413 17.6086 165.189 17.5156C164.965 17.4225 164.724 17.3773 164.482 17.3828C164.236 17.3797 163.992 17.4259 163.764 17.5186C163.536 17.6113 163.329 17.7487 163.156 17.9226C162.982 18.0966 162.844 18.3036 162.752 18.5314C162.659 18.7592 162.613 19.0033 162.616 19.2492Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3801_36492">
          <rect width="191.463" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default AppleStore;
