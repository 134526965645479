import './full-screen-loader.scss';

import Icon from '@/atoms/icon';

function FullScreenLoader() {
  return (
    <div className="full-screen-loader">
      <div className="flex-column align-center">
        <Icon name="pawcare" />
        <div className="progress-bar" />
      </div>
    </div>
  );
}

export default FullScreenLoader;
