import './header.scss';

import { useAppSelector } from '@/hooks';

import DefaultAvatar from '@/atoms/default-avatar';
import { prettifyDate } from '@/util';

export default function Header({ data, isMobile }: any) {
  const { name, type = [], links } = data;
  const { dateStart } = useAppSelector((state) => state.requestAppointmentForm);

  return (
    <div className="header-component mb-20">
      <div className="avatar-section">
        <DefaultAvatar
          className="mb-15"
          avatar={links?.image?.desktop?.large}
          size="big"
        />
      </div>
      <h2>{name}</h2>
      {isMobile ? (
        <p className="text-grey-dark">{prettifyDate(dateStart)}</p>
      ) : (
        <p className="mb-5">{type.map((el: any) => el.value).join(', ')}</p>
      )}
    </div>
  );
}
