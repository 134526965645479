import { useAppDispatch } from '@/hooks';

import Dropdown from '@/atoms/dropdown';
import { editForm } from '@/store/reducers/create-pet-form';

export default function SizeFilter({
  core,
  size,
}: {
  core: any;
  size: { value: string; id: number };
}) {
  const dispatch = useAppDispatch();

  return (
    <Dropdown
      placeholder="Select animal size"
      className="size-filter"
      size="small"
      items={core.size}
      onClick={(value) => dispatch(editForm({ key: 'size', value }))}
      value={size}
    />
  );
}
