import './search-bar.scss';

import { useAppDispatch } from '@/hooks';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Input from '@/atoms/input';
import { trackUserSearch } from '@/util/tracking/events';

interface SearchBarProps {
  placeholder?: string;
  onSearch: (str: string) => void;
  value?: string;
  className?: string;
  variant?: string;
  size?: string;
}

export default function SearchBar({
  placeholder = 'Search services or providers',
  onSearch,
  value,
  className,
  variant = 'white',
  size = undefined,
}: SearchBarProps) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const onKeyDown = (str: string) => {
    dispatch(trackUserSearch());
    onSearch(str);
  };

  return (
    <div className={classNames(className, `searchbar-${variant}`)}>
      <Input
        placeholder={placeholder}
        icon="search"
        iconColor={variant === 'transparent' ? 'white' : 'grey'}
        onKeyDown={(str: string) => onKeyDown(str)}
        value={value || searchParams.get('query') || ''}
        displaySubmitButton={variant === 'transparent'}
        submitButtonProps={{
          text: 'Search',
          shape: 'round',
          size: 'small',
          variant: 'default',
        }}
        autoComplete="off"
        size={size}
      />
    </div>
  );
}
