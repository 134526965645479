import { createSlice } from '@reduxjs/toolkit';

interface LocationState {
  services: any[];
  inventory: any;
  isLoading: boolean;
  links: any;
  hours: any;
  name: string;
  uuid: string;
  publicSettings: any;
}

const initialState: LocationState = {
  services: [],
  inventory: {},
  isLoading: false,
  links: {},
  hours: [],
  name: '',
  uuid: '',
  publicSettings: {},
};

const location = createSlice({
  name: 'location',
  initialState,
  reducers: {
    locationRequest(state) {
      state.isLoading = true;
    },
    locationSuccess(state, { payload }) {
      return {
        ...state,
        ...payload.data,
        isLoading: false,
      };
    },
    locationFailure(state) {
      state.isLoading = false;
    },
    locationInventoryRequest(state) {
      state.isLoading = true;
    },
    locationInventorySuccess(state, { payload }) {
      state.inventory = payload.data;
      state.isLoading = false;
    },
    locationInventoryFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = location;

export const {
  locationRequest,
  locationSuccess,
  locationFailure,
  locationInventoryRequest,
  locationInventorySuccess,
  locationInventoryFailure,
} = actions;

export default reducer;
