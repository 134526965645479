import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';

import { servicePriceCheck } from '@/actions';
import Input from '@/atoms/input';
import { promotionCode } from '@/store/reducers/invoice';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';

export default function AddDiscount() {
  const { service } = useAppSelector((state) => state.requestAppointmentForm);
  const dispatch = useAppDispatch();
  const [displayDiscountField, toggleDiscountField] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onApplyDiscount = async () => {
    dispatch(requestOngoing());
    const discountApplied = await dispatch(
      servicePriceCheck({ discount: discountCode, suid: service?.uuid }),
    );
    if (discountApplied?.success) {
      dispatch(promotionCode(discountCode));
      setSuccessMessage('Your promo code has been applied!');
    }
    dispatch(requestFulfilled());
  };

  return (
    <div>
      {!displayDiscountField ? (
        <button
          onClick={() => toggleDiscountField(true)}
          className="b-transparent border-none mt-15 fw-100 text-green pointer"
        >
          Add discount
        </button>
      ) : (
        <>
          <Input
            id="discount"
            className="mt-32"
            label="Discount code"
            onKeyDown={onApplyDiscount}
            onChange={(val: string) => {
              setDiscountCode(val);
              setSuccessMessage('');
            }}
            autoFocus
            value={discountCode}
            displaySubmitButton={discountCode}
            submitButtonProps={{ text: 'Apply' }}
          />
          {successMessage && (
            <p className="text-green mt-10 f11">{successMessage}</p>
          )}
        </>
      )}
    </div>
  );
}
