import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import paymentRedirectionMiddleware from '@/helpers/payment-redirection';

import { editForm } from '@/store/reducers/request-appointment-form';
import { openProviderViewMoreModal } from '@/store/reducers/ui';
import ViewMoreModal from '@/pages/search/view-more-modal';

export default function HardInventoryModal() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isModalOpen, location } = useAppSelector(
    (state) => state.ui.providerViewMoreModal,
  );

  const onSelectSlot = (data: any) => {
    if (!data) return;
    const { provider, slot } = data;
    const { date, time } = slot;
    dispatch(editForm({ key: 'provider', value: provider }));
    dispatch(editForm({ key: 'dateStart', value: `${date}T${time}` }));
    dispatch(editForm({ key: 'location', value: provider.uuid }));
    dispatch(openProviderViewMoreModal({ open: false }));
    dispatch(paymentRedirectionMiddleware(navigate));
  };

  const openModal = (value: any) => {
    dispatch(openProviderViewMoreModal({ open: value }));
  };

  return (
    <ViewMoreModal
      isOpen={!!isModalOpen}
      setIsOpen={openModal}
      locationUuid={location.uuid}
      onSelectSlot={onSelectSlot}
    />
  );
}
