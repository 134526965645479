import { useAppSelector } from '@/hooks';
import { useLocation } from 'react-router-dom';
import AdminNavbar from '@/organisms/admin-navbar';
import LoggedIn from './logged-in';
import LoggedOut from './logged-out';

function DesktopItems() {
  const location = useLocation();
  const { pathname } = location;
  const { token, data } = useAppSelector((state) => state.user);
  const isHomePage = pathname === '/';

  const styleOnHomePage = {
    border: '2px solid #ffffff',
    borderRadius: '40px',
  };
  const styleNotOnHomePage = {
    border: '2px solid var(--light-purple)',
    borderRadius: '40px',
  };
  const styleLoggedOut = {};

  // Check if on homepage
  let styleToUse;
  if (!token) {
    styleToUse = styleLoggedOut;
  } else {
    styleToUse = isHomePage ? styleOnHomePage : styleNotOnHomePage;
  }
  return (
    <div className="navbar-desktop flex-row pos-absolute">
      {data?.isAdmin && <AdminNavbar />}
      <div className="flex-row align-stretch" style={styleToUse}>
        {!token ? <LoggedOut /> : <LoggedIn />}
      </div>
    </div>
  );
}

export default DesktopItems;
