import './view-more-modal.scss';

import { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { loadProviderLocation } from '@/actions/providers';
import SlotsCalendar from '@/organisms/slots-calendar';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  locationUuid: string;
  onSelectSlot: (slot: any) => void;
  dateStart?: string;
}
export default function ViewMoreModal({
  isOpen,
  setIsOpen,
  locationUuid,
  onSelectSlot,
  dateStart,
}: Props) {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.location);

  useEffect(() => {
    if (isOpen) {
      dispatch(loadProviderLocation(locationUuid));
    }
  }, [locationUuid]);

  if (!locationUuid || isLoading) return;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className="view-more-modal"
      onRequestClose={() => {
        setIsOpen(false);
        document.body.classList.remove('ReactModal__Body--open');
      }}
    >
      <SlotsCalendar
        ignoreLocationUser
        locationUuid={locationUuid}
        onSelectSlot={onSelectSlot}
        dateStart={dateStart}
        className="view-more-content"
      />
    </ReactModal>
  );
}
