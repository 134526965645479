import './payment-method-modal.scss';

import ReactModal from 'react-modal';
import { useAppDispatch } from '@/hooks';
import Icon from '@/atoms/icon';
import toast from '@/atoms/toast';
import AddNewCard from '@/organisms/add-new-card';
import { requestFulfilled } from '@/store/reducers/ui';

interface PaymentMethodModalProps {
  isOpen: boolean;
  openModal: (isOpen: boolean) => void;
  modalTitle?: string;
}

export default function PaymentMethodModal({
  isOpen,
  openModal,
  modalTitle = 'Add Payment',
}: PaymentMethodModalProps) {
  const dispatch = useAppDispatch();

  const onAddCard = () => {
    openModal(false);
    dispatch(requestFulfilled());
    toast({ text: 'Card added successfully' });
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className="mw-450 payment-method-modal"
      onRequestClose={() => openModal(false)}
    >
      <div className="flex-column">
        <div className="flex-row justify-between align-center mb-30">
          <h2 className="payment-method-modal-title">{modalTitle}</h2>
          <Icon
            name="close"
            fill="black"
            onClick={() => openModal(false)}
            className="pointer p-10"
          />
        </div>
        <AddNewCard
          primaryButtonText="Save"
          onSubmit={onAddCard}
          canSubmit
          hideCopy
          hideDiscount
          label=""
          hasSignedWaiver
        />
      </div>
    </ReactModal>
  );
}
