import './progress-bar.scss';
import classNames from 'classnames';
import { generateKey } from '@/util';

interface ProgressBarProps {
  maxSteps: number;
  currentStep: number;
  className?: string;
}

function ProgressBar({ maxSteps, currentStep, className }: ProgressBarProps) {
  return (
    <div className="progress-bar flex-row w-100">
      {Array.from({ length: maxSteps }).map((_, index) => (
        <div
          key={generateKey()}
          className={classNames(
            'flex-row progress-bar-section',
            className,
            index > 0 && 'w-100',
          )}
        >
          {index > 0 && (
            <div
              className={classNames(
                'progress-bar-section-line',
                index < currentStep ? 'b-green' : 'b-extra-light-grey',
              )}
            />
          )}
          <div
            className={classNames(
              'progress-bar-section-circle',
              index < currentStep ? 'b-green' : 'b-extra-light-grey',
            )}
          />
        </div>
      ))}
    </div>
  );
}

export default ProgressBar;
