import './appointments-loader.scss';

import classNames from 'classnames';

function AppointmentsLoader({ variant = 'default' }) {
  return (
    <div className="appointments-loader">
      {[1, 2, 3, 4, 5, 6].map((el) => (
        <div key={el} className={classNames(`row flex-row row-${variant}`)}>
          <div className="flex-column w-100 content">
            <span className="title mb-15" />
            <span className="description mb-15" />
            <span className="description" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default AppointmentsLoader;
