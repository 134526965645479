function Upload({ fill = 'white' }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.177 9.40465C13.6285 9.85116 13.6285 10.5209 13.177 10.9674C12.9512 11.1907 12.6125 11.3023 12.3867 11.3023C12.1609 11.3023 11.8223 11.1907 11.5965 10.9674L10.1289 9.51628V16.8837C10.1289 17.5535 9.67737 18 9.00003 18C8.32269 18 7.87113 17.5535 7.87113 16.8837V9.51628L6.40356 10.9674C5.952 11.414 5.27466 11.414 4.82311 10.9674C4.37155 10.5209 4.37155 9.85116 4.82311 9.40465L8.2098 6.05581C8.32269 5.94419 8.43558 5.94419 8.54847 5.83256C8.88714 5.72093 9.22581 5.72093 9.45159 5.83256C9.56448 5.83256 9.67737 5.94419 9.79026 6.05581L13.177 9.40465ZM2.62469 2.59535C6.23716 -0.865116 11.8817 -0.865116 15.3812 2.59535C18.4293 5.6093 18.8808 10.4093 16.3972 13.9814C16.0586 14.4279 15.7199 14.8744 15.3812 15.2093C14.9297 15.6558 14.2523 15.6558 13.8008 15.2093C13.3492 14.7628 13.3492 14.093 13.8008 13.6465C14.1395 13.4233 14.3652 13.0884 14.591 12.7535C16.3972 10.0744 16.1715 6.50233 13.8008 4.15814C11.0914 1.5907 6.80161 1.5907 4.20515 4.15814C1.60868 6.83721 1.60868 11.0791 4.20515 13.6465C4.65671 14.093 4.65671 14.7628 4.20515 15.2093C3.97937 15.4326 3.6407 15.5442 3.41492 15.5442C3.18914 15.5442 2.85047 15.4326 2.62469 15.2093C-0.874896 11.7488 -0.874896 6.05581 2.62469 2.59535Z"
        fill={fill}
      />
    </svg>
  );
}

export default Upload;
