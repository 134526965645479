import './checkbox.scss';

import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

interface CheckboxProps {
  text?: string;
  onClick: ({
    isChecked,
    id,
    text,
  }: {
    isChecked: boolean;
    id: string | number;
    text: string;
  }) => void;
  id: string;
  value: boolean;
  children: ReactNode;
  variant?: 'consent' | 'round' | 'disabled' | 'button' | 'block';
  size?: 'small';
  className?: string;
  disableInternalCheck?: boolean;
  disabled?: boolean;
  checkmarkColor?: 'black' | 'orange';
  contentPosition?: 'left' | 'right';
}

export default function Checkbox({
  text = '',
  onClick,
  id,
  value,
  children,
  variant,
  size,
  className,
  disableInternalCheck,
  disabled,
  checkmarkColor = 'black',
  contentPosition = 'right',
}: CheckboxProps) {
  const [isChecked, check] = useState(false);

  useEffect(() => {
    check(value);
  }, [value]);

  const onChange = () => {
    if (!disableInternalCheck) {
      check((current) => !current);
    }
    onClick({ isChecked: !isChecked, id, text });
  };

  return (
    <label
      htmlFor={id}
      className={classNames(
        'checkbox__container flex-row fw-100',
        className,
        disabled && 'checkbox__container--disabled',
        `checkbox__container--${size}`,
        `checkbox__container--${variant}`,
        isChecked && variant === 'block' && 'checkbox__container--checked',
      )}
    >
      {contentPosition === 'left' && (text || children)}
      <input
        type="checkbox"
        className={classNames('checkbox')}
        id={id}
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <span
        className={classNames(
          'checkmark',
          `checkmark--${checkmarkColor}`,
          contentPosition === 'right' ? 'mr-10' : 'ml-10',
        )}
      />
      {contentPosition === 'right' && (text || children)}
    </label>
  );
}
