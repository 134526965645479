import './default-payment-method.scss';

import { useAppSelector } from '@/hooks';
import classNames from 'classnames';

import Icon from '@/atoms/icon';
import AddDiscount from '@/organisms/add-discount';
import { getCardIcon } from '@/util';
import { IconName } from '@/atoms/icon/icons';

interface DefaultPaymentMethodProps {
  isAddingDetails?: boolean;
  hideDiscount?: boolean;
}

export function DefaultPaymentMethod({
  isAddingDetails,
  hideDiscount,
}: DefaultPaymentMethodProps) {
  const { cards } = useAppSelector((state) => state.user.paymentMethod);
  const lastCard = cards?.[0] || {};

  return (
    <>
      <div
        className={classNames(
          'default-payment-method flex-row',
          isAddingDetails && 'default-payment-method--disabled',
        )}
      >
        <Icon name={getCardIcon(lastCard.brand) as IconName} />
        <div className="flex-column">
          <p className="f13">{`**** **** **** ${lastCard.last4}`}</p>
          <p className="grey-text f11">{`Expires ${lastCard.expiration.month}/${lastCard.expiration.year}`}</p>
        </div>
      </div>
      {!hideDiscount && <AddDiscount />}
    </>
  );
}
