import { useAppDispatch, useAppSelector } from '@/hooks';
import { Link } from 'react-router-dom';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { setMapLocation } from '@/store/reducers/ui';
import { capitalizeFirstLetter, splitByCapitalLetters } from '@/util';

export default function SearchByArea() {
  const dispatch = useAppDispatch();
  const { chicago } = useAppSelector((state) => state.main.market);
  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  const areas = Object.entries(chicago)
    .map(([name, data]) => ({ name, data }))
    .slice(0, 60);
  const columns = {
    column1: [],
    column2: [],
    column3: [],
  };

  areas.forEach((area: any, i: number) => {
    if (isMobile) {
      if (i < 30) {
        columns.column1.push(area);
      } else columns.column2.push(area);
    } else {
      if (i < 20) {
        columns.column1.push(area);
      }
      if (i > 19 && i < 40) {
        columns.column2.push(area);
      }
      if (i > 39 && i < 60) {
        columns.column3.push(area);
      }
    }
  });

  const onClickArea = ({ latitude, longitude }: any) => {
    dispatch(setMapLocation({ lat: latitude, lng: longitude }));
  };

  return (
    <div className="area-search flex-row justify-between">
      <h2>Pet groomers in the greater Chicago area</h2>
      <div className="flex-row w-45 justify-evenly align-start">
        {Object.entries(columns).map(([id, data]) => {
          if (data.length) {
            return (
              <div className="flex-column mr-40 ml-40" key={id}>
                {data.map(({ name, data }) => {
                  const areaName = capitalizeFirstLetter(name);
                  const splitAreaName = splitByCapitalLetters(areaName);
                  return (
                    <Link
                      key={name}
                      onClick={() => onClickArea(data)}
                      to={`/pet/groomers/chicago/${splitAreaName.toLowerCase().replace(/ /g, '-')}`}
                      className="text-green mb-5 fw-100"
                    >
                      {splitAreaName}
                    </Link>
                  );
                })}
              </div>
            );
          } else return null;
        })}
      </div>
    </div>
  );
}
