import { createSlice } from '@reduxjs/toolkit';

const hardInventory = createSlice({
  name: 'hardInventory',
  initialState: {
    providers: [],
    calendar: null,
    isLoading: false,
  },
  reducers: {
    hardInventoryRequest(state) {
      state.isLoading = true;
    },
    hardInventorySuccess(state, { payload }) {
      state.providers = payload.data.provider.location;
      state.calendar = payload.data.calendar;
      state.isLoading = false;
    },
    hardInventoryFailure(state) {
      state.isLoading = false;
    },
    clearHardInventory(state) {
      state.providers = [];
      state.calendar = null;
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = hardInventory;

export const {
  hardInventoryRequest,
  hardInventorySuccess,
  hardInventoryFailure,
  clearHardInventory,
} = actions;

export default reducer;
