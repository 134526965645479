import 'pages/blog/blog.scss';

import { useEffect, useState } from 'react';
import { useAppDispatch } from '@/hooks';
import { Link } from 'react-router-dom';

import { getBlogPosts } from '@/actions';
import BlogLoader from '@/organisms/loaders/blog-loader';
import { postsList } from '@/util';

export default function BlogPreview() {
  const dispatch = useAppDispatch();
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    dispatch(getBlogPosts())?.then(({ success, data }: any) => {
      if (success) {
        setPosts(data.data.slice(0, 3));
      }
    });
  }, []);

  return (
    <div className="blog-preview">
      <div className="flex-row justify-between mb-25 blog-preview-header">
        <h2>Tips from PawCare</h2>
        <Link to="/blog" className="view-more">
          View all
        </Link>
      </div>
      {!posts ? (
        <BlogLoader />
      ) : (
        <div className="flex-row blog-preview-second-container">
          {postsList(posts)}
        </div>
      )}
      <div />
    </div>
  );
}
