import './provider-card.scss';

import classNames from 'classnames';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import DefaultAvatar from '@/atoms/default-avatar';
import { Slots } from '@/organisms/slots';
import { UserDetails } from '@/organisms/user-details';

interface ProviderCardProps {
  item: any;
  selectSlot: any;
  onViewMore: any;
  calendar: any;
  maxSlots: any;
  className?: any;
  cardLink: any;
  stopPropagation: any;
  onClickCard: any;
}

export default function ProviderCard({
  item,
  selectSlot,
  onViewMore,
  calendar,
  maxSlots,
  className,
  cardLink,
  stopPropagation,
  onClickCard,
}: ProviderCardProps) {
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  return (
    <div
      className={classNames(
        'provider-card flex-column align-center',
        className,
      )}
    >
      <button
        className="pointer b-transparent border-none"
        onClick={() => onClickCard(cardLink, item)}
      >
        {isMobile ? (
          <UserDetails item={item} />
        ) : (
          <div>
            <div>
              <div className="provider-card-header flex-row justify-center">
                <DefaultAvatar
                  className="user-details-avatar"
                  size="extra-large"
                  avatar={item.links?.image?.desktop?.large}
                />
              </div>
            </div>
            <div className="flex-column provider-card-details">
              <h4 className="text-black f20 mt-20">{item.name}</h4>
              <p className="user-details-address">{item.address1}</p>
            </div>
          </div>
        )}
      </button>
      {!isMobile && (
        <div className="provider-card-slots w-100">
          <Slots
            calendar={calendar}
            item={item}
            selectSlot={selectSlot}
            onViewMore={onViewMore}
            maxSlots={maxSlots}
            stopPropagation={stopPropagation}
          />
        </div>
      )}
    </div>
  );
}
