import './banner.scss';

import { useAppDispatch } from '@/hooks';
import { useLocation } from 'react-router-dom';

import Icon from '@/atoms/icon';
import { displayPromoBanner } from '@/store/reducers/invoice';

export default function Banner({ text }: { text: string }) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const hideBannerUrls = ['/about', '/blog', '/privacy.html', '/terms'];

  if (hideBannerUrls.includes(location?.pathname)) {
    return null;
  }

  return (
    <div className="w-100 banner flex-row f13 justify-center">
      <p>{text}</p>
      <Icon
        name="close"
        onClick={() => dispatch(displayPromoBanner(false))}
        fill="black"
        width={12}
        height={12}
        className="ml-10 pointer"
      />
    </div>
  );
}
