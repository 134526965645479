import './provider-info-card.scss';

import Icon from '@/atoms/icon';
import { prettifyUrl } from '@/util';

export default function ProviderInfoCard({ location }: { location: any }) {
  const { address1, region = '', locality = '', postalCode, links } = location;

  const mapImage = `https://maps.googleapis.com/maps/api/staticmap?size=600x250&maptype=terrain&zoom=14&markers=${encodeURIComponent(
    `icon:https://i.imgur.com/ESZho3g.png|${address1},${locality},${region}`,
  )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const mapLink = `https://maps.google.com/maps?q=${address1}, ${region}, ${locality}`;
  return (
    <div className="provider-info-card">
      <a href={mapLink} target="_blank" rel="noreferrer">
        <div
          className="static-map"
          style={{ backgroundImage: `url(${mapImage})` }}
        />
      </a>
      <div className="details-block">
        <h3 className="f20">Location details</h3>
        <p className="f13">{address1}</p>
        <p className="f13">{`${locality}, ${region} ${postalCode}`}</p>
      </div>
      <a
        href={mapLink}
        target="_blank"
        className="flex-row details-block text-orange f13 border-top"
        rel="noreferrer"
      >
        <Icon fill="#FF9838" name="map-drop" className="mr-20" />
        <p>Get directions</p>
      </a>
      {/* We're hidding phone number for now. Will come back. */}
      {/* <div className="flex-row details-block text-orange f13 border-top">
        <Icon style={{ fill: '#FF9838' }} name="phone" className="mr-15" />
        <a href={`tel:${phone?.localized}`} onClick={() => trackEvent('Select call')}>
          <p>{phone?.localized}</p>
        </a>
      </div> */}
      {links?.website && (
        <div className="flex-row details-block text-orange f13 border-top">
          <Icon fill="#FF9838" name="world" className="mr-15" />
          <a href={links.website} target="_blank" rel="noreferrer">
            <p>{prettifyUrl(links.website)}</p>
          </a>
        </div>
      )}
    </div>
  );
}
