import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';

import Button from '@/atoms/button';
import useSignupSteps from '@/hooks/useSignupSteps';
import ListItem from '@/organisms/list-item';
import ProgressBar from '@/organisms/progress-bar';
import { setActivePet } from '@/store/reducers/pet-appointments';
import { capitalizeFirstLetter } from '@/util';
import { trackPetInfo } from '@/util/tracking/events';

function PetInfo({ onPetSelect }: { onPetSelect?: (pet: any) => void }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const [activePetLocal, setActivePetLocal] = useState(activePet);
  const [numberOfSteps] = useSignupSteps();

  useEffect(() => {
    dispatch(trackPetInfo());
    if (user.animals.length === 1 && !isPetDisabled(user.animals[0])) {
      selectPet(user.animals[0]);
      onNext();
    }
  }, []);

  const isPetDisabled = (pet: any) =>
    activePetLocal.type?.value !== pet.type?.value ||
    activePetLocal.size?.value !== pet.size?.value;

  const selectPet = (selectedPet: any) => {
    if (isPetDisabled(selectedPet)) {
      toast('This pet cannot be selected due to type/size mismatch.');
      return;
    }
    setActivePetLocal(selectedPet);
    if (onPetSelect) {
      onPetSelect(selectedPet);
    }
    dispatch(setActivePet(selectedPet));
  };

  const onNext = () => {
    navigate('/booking-select-groomer');
  };

  return (
    <div className="booking-pet-container flex-column align-start justify-center">
      <div className="mobile-page-container w-100">
        <h1 className="mb-12">Pet details</h1>
        <ProgressBar
          maxSteps={numberOfSteps as number}
          currentStep={2}
          className="mb-32 width-100"
        />
        <ul className="flex row no-style-ul">
          {user.animals.map((pet: any) => (
            <ListItem
              title={capitalizeFirstLetter(pet.name)}
              subtitle={
                isPetDisabled(pet) &&
                `Service unavailable for this ${pet.type.value.toLowerCase()}`
              }
              avatar={pet.links?.image?.desktop?.large}
              key={pet.uuid}
              id={pet.uuid}
              onClick={selectPet}
              item={pet}
              isActive={activePetLocal.uuid === pet.uuid}
              isDisabled={!!isPetDisabled(pet)}
            />
          ))}
        </ul>
      </div>
      <div className="mobile-main-action-btn w-100 mw-420">
        <Button text="Next" onClick={onNext} className="w-100" size="medium" />
      </div>
    </div>
  );
}

export default PetInfo;
