function Camera() {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="100"
        cy="100"
        r="99"
        fill="white"
        stroke="#63637B"
        strokeWidth="2"
        strokeDasharray="12 12"
      />
      <path
        d="M89.0174 66.6666C88.4518 66.6666 87.9343 66.9848 87.679 67.4894L83.135 76.4705H65.2778C61.4306 76.4705 58.3334 79.5313 58.3334 83.3333V126.471C58.3334 130.273 61.4306 133.333 65.2778 133.333H134.722C138.569 133.333 141.667 130.273 141.667 126.471V83.3333C141.667 79.5313 138.569 76.4705 134.722 76.4705H116.865L112.321 67.4894C112.066 66.9848 111.548 66.6666 110.983 66.6666H89.0174ZM100 87.2549C109.862 87.2549 117.857 95.1557 117.857 104.902C117.857 114.648 109.862 122.549 100 122.549C90.1379 122.549 82.1429 114.648 82.1429 104.902C82.1429 95.1557 90.1379 87.2549 100 87.2549Z"
        fill="#63637B"
      />
    </svg>
  );
}

export default Camera;
