import { RootState } from '@/store';
import { openCreatePetModal, openRequestModal } from '@/store/reducers/ui';
import { Dispatch } from '@reduxjs/toolkit';

export default function petCreationMiddleware() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const userHasPets = getState().user.data?.animals?.length;
    const petSize = getState().createPetForm.data.size;
    if (!userHasPets && !petSize) {
      dispatch(openCreatePetModal({ open: true, openRequestModal: true }));
    } else dispatch(openRequestModal(true));
  };
}
