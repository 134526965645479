import Button from '@/atoms/button';
import daycareImage from '@/images/daycare-confirmation.webp';

export default function ConfirmationScreen({
  openModal,
}: {
  openModal: (bool: boolean) => void;
}) {
  return (
    <div className="flex-column align-center text-center confirmation-screen">
      <img
        src={daycareImage}
        alt="decorative"
        className="daycare-modal-container-avatar"
      />
      <h2 className="mt-10">Thank you!</h2>
      <p>
        You’ve signed up to be notified when we launch Daycare. We’ll be in
        touch soon.
      </p>
      <Button onClick={() => openModal(false)} className="mt-30" text="Done" />
    </div>
  );
}
