import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';

import { editPet } from '@/actions/pet';
import { loadUser } from '@/actions/user';
import Button from '@/atoms/button';
import { DateInput } from '@/atoms/date-input';
import Dropdown from '@/atoms/dropdown';
import toast from '@/atoms/toast';
import {
  closeDrawer,
  requestFulfilled,
  requestOngoing,
} from '@/store/reducers/ui';
import { generateKey } from '@/util';

export default function UpdateVaccinationsDrawer() {
  const dispatch = useAppDispatch();
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const { animals: userAnimals } = useAppSelector((state) => state.user.data);
  const advancedSearchPetUuid = useAppSelector(
    (state) => state.advancedSearch.petUuid,
  );
  const { core } = useAppSelector((state) => state.main);
  const [selectedVaccinations, setSelectedVaccinations] = useState<any[]>([]);

  const auid = activePet?.uuid
    ? activePet.uuid
    : userAnimals?.length
      ? userAnimals[0].uuid
      : advancedSearchPetUuid;

  const animal = userAnimals.find((animal: any) => animal.uuid === auid);
  const { vaccinations, type } = animal;

  const availableVaccinations = core.vaccination.filter(
    (el) => el.animalTypeId === type?.id,
  );
  const vaccinationIdsList = selectedVaccinations
    ?.map((vaccination: any) =>
      vaccination?.id && vaccination?.value ? vaccination.id : null,
    )
    .filter((vaccination) => !!vaccination);

  useEffect(() => {
    setSelectedVaccinations(vaccinations);
  }, [vaccinations]);

  const onAddVaccine = () => {
    setSelectedVaccinations((currentVaccinations: any) => [
      ...currentVaccinations,
      {},
    ]);
  };

  const onCancel = () => {
    dispatch(closeDrawer());
  };

  const onSave = () => {
    dispatch(requestOngoing());
    dispatch(editPet({ vaccinations: selectedVaccinations }, auid))?.then(
      ({ success }: any) => {
        dispatch(requestFulfilled());
        if (success) {
          toast({ text: 'Vaccinations updated!' });
          dispatch(loadUser());
          dispatch(closeDrawer());
        }
      },
    );
  };

  return (
    <div className="edit-details-drawer flex-column">
      <div className="w-100 flex-column">
        <h2 className="f26 mb-24">Update vaccinations</h2>

        <div className="flex-column mb-12 p-24 border-light-purple radius-24">
          <h4>Vaccinations</h4>
          {selectedVaccinations?.map((vaccination: any, index: number) => (
            <div className="flex-row gap-16 mt-16" key={generateKey()}>
              <Dropdown
                placeholder="Vaccination"
                className="w-45"
                variant="white"
                items={availableVaccinations.filter(
                  (vaccinationOption: any) =>
                    vaccinationOption?.id === vaccination?.id ||
                    !vaccinationIdsList.includes(vaccinationOption?.id),
                )}
                size="small"
                onClick={(value) => {
                  const updatedVaccination = {
                    ...selectedVaccinations?.[index],
                    ...value,
                  };
                  const selectedVaccinationsCopy =
                    selectedVaccinations?.slice(0);
                  selectedVaccinationsCopy[index] = updatedVaccination;
                  return setSelectedVaccinations(selectedVaccinationsCopy);
                }}
                value={
                  vaccination?.id && vaccination?.value ? vaccination : null
                }
                noWrap
              />
              <DateInput
                className="w-45"
                placeholder="Expiration date"
                onChange={(date) => {
                  const updatedVaccination = {
                    ...selectedVaccinations?.[index],
                    expirationDate: date,
                  };
                  const selectedVaccinationsCopy =
                    selectedVaccinations?.slice(0);
                  selectedVaccinationsCopy[index] = updatedVaccination;
                  return setSelectedVaccinations(selectedVaccinationsCopy);
                }}
                value={
                  vaccination?.expirationDate
                    ? vaccination.expirationDate
                    : null
                }
                size="small"
              />
            </div>
          ))}
          <div className="flex-row mt-16">
            <Button
              className="f14 fw-400"
              text="Add vaccine"
              size="extra-small"
              variant="white-outlined"
              icon="add"
              iconPosition="start"
              onClick={onAddVaccine}
            />
          </div>
        </div>

        <div className="flex-row justify-end gap-8">
          <Button
            className="f14 fw-400"
            size="small"
            text="Cancel"
            variant="white-outlined"
            onClick={onCancel}
          />
          <Button
            className="f14 fw-400"
            size="small"
            text="Save changes"
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
}
