import { createSlice } from '@reduxjs/toolkit';

const providers = createSlice({
  name: 'providers',
  initialState: {
    providers: [],
    isLoading: false,
  },
  reducers: {
    providersRequest(state) {
      state.isLoading = true;
    },
    providersSuccess(state, { payload }) {
      state.providers = payload.data;
    },
    providersFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = providers;

export const { providersRequest, providersSuccess, providersFailure } = actions;

export default reducer;
