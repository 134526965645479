import { InfoBlock } from '@/atoms/info-block';

import { Details } from './details';
import Header from './header';

export default function ProfileInfoDesktop({ data }: any) {
  return (
    <InfoBlock>
      <div className="profile-info">
        <Header data={data} />
        <Details data={data} />
      </div>
    </InfoBlock>
  );
}
