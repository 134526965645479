function Mail({ fill = '#150828', width = 64, height = 64 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 64 64"
    >
      <path
        stroke={fill}
        strokeLinejoin="round"
        strokeWidth="2"
        d="m13.334 19.167-3.25 2.166c-2.33 1.554-3.495 2.33-4.126 3.515-.631 1.184-.627 2.576-.617 5.36.01 3.352.042 6.767.128 10.223.205 8.199.307 12.298 3.322 15.313 3.014 3.014 7.169 3.118 15.479 3.326 5.17.129 10.293.129 15.462 0 8.31-.208 12.465-.312 15.48-3.326 3.014-3.015 3.116-7.114 3.32-15.313.087-3.456.118-6.871.13-10.223.009-2.784.013-4.176-.618-5.36-.631-1.185-1.796-1.961-4.126-3.515l-3.25-2.166"
      />
      <path
        stroke={fill}
        strokeLinejoin="round"
        strokeWidth="2"
        d="m5.333 27.167 18.435 11.06c4.01 2.407 6.016 3.61 8.232 3.61 2.216 0 4.221-1.203 8.232-3.61l18.435-11.06"
      />
      <path
        stroke={fill}
        strokeWidth="2"
        d="M13.333 32.5v-16c0-5.028 0-7.542 1.562-9.105C16.458 5.833 18.972 5.833 24 5.833h16c5.028 0 7.542 0 9.105 1.562 1.562 1.563 1.562 4.077 1.562 9.105v16"
      />
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M26.667 27.167h10.666M26.667 16.5h10.666"
      />
    </svg>
  );
}

export default Mail;
