function CalendarFilled() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.50605 0C4.28011 0 4.09699 0.187382 4.09699 0.418573V3.9767C4.09699 4.2079 4.28012 4.39527 4.50605 4.39527C4.73199 4.39527 4.9151 4.20789 4.9151 3.9767V0.418573C4.9151 0.187373 4.73198 0 4.50605 0ZM13.5062 0C13.2802 0 13.0971 0.187382 13.0971 0.418573V3.9767C13.0971 4.2079 13.2802 4.39527 13.5062 4.39527C13.7321 4.39527 13.9152 4.20789 13.9152 3.9767V0.418573C13.9152 0.187373 13.7321 0 13.5062 0ZM1.64269 1.25586C0.733312 1.25586 0 2.00626 0 2.93677V6.69763H18V2.93677C18 2.00623 17.2666 1.25586 16.3573 1.25586H14.3247V3.04151C14.5756 3.27141 14.7337 3.60507 14.7337 3.97681C14.7337 4.67041 14.1843 5.23267 13.5064 5.23267C12.8286 5.23267 12.2791 4.67041 12.2791 3.97681C12.2791 3.60507 12.4373 3.27142 12.6882 3.04151V1.25586H5.31826V3.03489C5.57307 3.26501 5.73378 3.6017 5.73378 3.97678C5.73378 4.67038 5.18431 5.23264 4.50648 5.23264C3.82866 5.23264 3.27918 4.67038 3.27918 3.97678C3.27918 3.60829 3.43492 3.27768 3.6819 3.04799V1.25586H1.64269ZM0 7.53486V16.319C0 17.2495 0.733339 18 1.64269 18H16.3571C17.2665 18 17.9998 17.2495 17.9998 16.319V7.53486H0Z"
        fill="#150828"
      />
    </svg>
  );
}

export default CalendarFilled;
