import './request-appointment-modal.scss';

import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import paymentRedirectionMiddleware from '@/helpers/payment-redirection';

import { loadProviderLocation } from '@/actions/providers';
import Button from '@/atoms/button';
import { DateInput } from '@/atoms/date-input';
import Dropdown from '@/atoms/dropdown';
import Icon from '@/atoms/icon';
import ServiceBlock from '@/atoms/service-block';
import ModalCardsLoader from '@/organisms/loaders/modal-cards-loader';
import { UserDetails } from '@/organisms/user-details';
import { editForm } from '@/store/reducers/request-appointment-form';
import { openRequestModal } from '@/store/reducers/ui';
import { getOpenHours } from '@/util';
import { weekdays } from '@/util/constants';

type RequestAppointmentModalProps = {
  isOpen: boolean;
  location: any;
  disableFirstScreen?: boolean;
};

export default function RequestAppointmentModal({
  isOpen,
  location: selectedLocation,
  disableFirstScreen,
}: RequestAppointmentModalProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [screen, setScreen] = useState(disableFirstScreen ? 2 : 1);
  const [location, setLocation] = useState<null | {
    hours: any;
    uuid: string;
    services: any[];
  }>(null);
  const [day, setDay] = useState(null);
  const [time, setTime] = useState<{ valueFormatted: any } | null>(null);
  const selectedService = useAppSelector(
    (state) => state.requestAppointmentForm.service,
  );
  const { appointmentDateMin } = useAppSelector((state) => state.main.search);
  const canContinue = day && time && selectedService;

  useEffect(() => {
    if (selectedLocation) {
      dispatch(
        loadProviderLocation(selectedLocation?.uuid, [null, null, null]),
      )?.then(({ success, data }) => {
        if (success) {
          setLocation(data.data);
        }
      });
    }
    return () => {
      setScreen(1);
      setDay(null);
      setTime(null);
    };
  }, []);

  const onSelectService = (service: any) => {
    dispatch(editForm({ key: 'service', value: service }));
    setScreen(2);
  };

  const openHours = () => {
    if (day) {
      const dayOfWeek = new Date(day).getDay();
      const nameOfDay = weekdays[dayOfWeek];
      const openHoursDay = location?.hours.filter(
        (hour: any) => hour.dow.value === nameOfDay,
      )[0];
      return getOpenHours(openHoursDay?.time) || [];
    }
    return [];
  };

  const disableValues = (date: any) => {
    const openDays = location?.hours.map((hour: any) => hour.dow.id);
    const closedDays = [] as any[];
    weekdays.forEach((_, i) => {
      if (!openDays.includes(i)) {
        closedDays.push(i);
      }
    });
    return closedDays.includes(new Date(date).getDay());
  };

  const onRequestAppointment = () => {
    dispatch(editForm({ key: 'provider', value: location }));
    dispatch(
      editForm({ key: 'dateStart', value: `${day}T${time?.valueFormatted}` }),
    );
    dispatch(editForm({ key: 'location', value: location?.uuid }));
    closeModal();
    dispatch(paymentRedirectionMiddleware(navigate));
  };

  const closeModal = () => {
    dispatch(openRequestModal(false));
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="overflow-inherit"
    >
      <div
        className={classNames(
          'flex-row mb-30',
          screen === 1 ? 'justify-end' : 'justify-between',
        )}
      >
        {screen === 2 && (
          <Icon
            name="arrowLeft"
            width={20}
            height={20}
            onClick={() => setScreen(1)}
            className="back-button pointer"
          />
        )}
        <Icon
          name="close"
          width={18}
          height={18}
          onClick={closeModal}
          className="pointer"
        />
      </div>
      <h2 className="mb-25">Request appointment</h2>
      <UserDetails item={location} />
      <div
        className={classNames(
          'mt-15 request-appointment-modal-services',
          screen === 1 &&
            'overflow-auto modal-services-height request-appointment-modal-services--mobile',
        )}
      >
        {!location ? (
          <ModalCardsLoader />
        ) : screen === 1 ? (
          location?.services.map((service: any) => (
            <ServiceBlock
              onSelectService={onSelectService}
              className="services-service"
              key={service.uuid}
              service={service}
              location={location}
            />
          ))
        ) : (
          <>
            <ServiceBlock
              onSelectService={onSelectService}
              className="services-service"
              disableSelect
              key={selectedService.uuid}
              service={selectedService}
              location={location}
            />
            <div className="flex-row responsive">
              <DateInput
                className="w-50 mr-10"
                placeholder="02/02/2020"
                minDate={appointmentDateMin}
                disableValues={disableValues}
                onChange={(value: any) => {
                  setDay(value);
                  setTime(null);
                }}
                value={day}
              />
              <div className="w-50">
                <Dropdown
                  generateKey
                  placeholder="Select a time"
                  className="w-100 mt-0"
                  items={openHours()}
                  disableDropdown={!day}
                  onClick={(value) => setTime(value)}
                  value={{ value: time?.valueFormatted }}
                />
              </div>
            </div>
            <Button
              text="Request appointment"
              variant={canContinue ? 'default' : 'disabled'}
              className="mt-32"
              onClick={onRequestAppointment}
            />
          </>
        )}
      </div>
    </ReactModal>
  );
}
