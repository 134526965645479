function Bin() {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none">
      <path
        d="M13.6667 1H10.2767L9.71 0.15C9.6798 0.104146 9.63875 0.0664612 9.59048 0.0402931C9.54222 0.0141249 9.48824 0.000284039 9.43333 0H4.56667C4.51176 0.000284039 4.45778 0.0141249 4.40952 0.0402931C4.36125 0.0664612 4.3202 0.104146 4.29 0.15L3.72333 1H0.333333C0.244928 1 0.160143 1.03512 0.0976308 1.09763C0.0351187 1.16014 0 1.24493 0 1.33333V3H14V1.33333C14 1.24493 13.9649 1.16014 13.9024 1.09763C13.8399 1.03512 13.7551 1 13.6667 1Z"
        fill="#63637B"
      />
      <path
        d="M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16Z"
        fill="#63637B"
      />
    </svg>
  );
}

export default Bin;
