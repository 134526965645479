import { createSlice } from '@reduxjs/toolkit';

const providersSearch = createSlice({
  name: 'providersSearch',
  initialState: {
    data: {},
    isLoading: false,
  },
  reducers: {
    providersSearchRequest(state) {
      state.isLoading = true;
    },
    providersSearchSuccess(state, { payload }) {
      state.data = payload.data;
      state.isLoading = false;
    },
    providersSearchFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = providersSearch;

export const {
  providersSearchRequest,
  providersSearchSuccess,
  providersSearchFailure,
} = actions;

export default reducer;
