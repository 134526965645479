function Account() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 3.48808C14.5686 3.48808 15.9886 4.12404 17.0162 5.15156L17.0644 5.20381C18.0632 6.22836 18.6793 7.62793 18.6793 9.16739C18.6793 10.736 18.0437 12.1557 17.0162 13.1832C15.9886 14.2111 14.5686 14.8464 13 14.8464C11.4354 14.8464 10.0147 14.2097 8.98583 13.1812C7.95632 12.1557 7.3207 10.736 7.3207 9.16739C7.3207 7.60345 7.95732 6.18404 8.98384 5.1552L8.99013 5.14958C10.0183 4.12371 11.4374 3.48808 13 3.48808ZM13 0.399902C16.4788 0.399902 19.6301 1.81005 21.9097 4.09031C24.1899 6.3699 25.6001 9.52125 25.6001 13C25.6001 16.4788 24.1899 19.6301 21.9097 21.9097C19.6301 24.1899 16.4788 25.6001 13 25.6001C9.52125 25.6001 6.3699 24.1899 4.09031 21.9097C1.81005 19.6301 0.399902 16.4788 0.399902 13C0.399902 9.52125 1.81005 6.3699 4.09031 4.09031C6.3699 1.81005 9.52125 0.399902 13 0.399902ZM21.032 4.96802C18.9766 2.91232 16.1365 1.64073 13 1.64073C9.86354 1.64073 7.02339 2.91232 4.96802 4.96802C2.91232 7.02339 1.64073 9.86354 1.64073 13C1.64073 15.3705 2.36763 17.5718 3.60979 19.393C3.90908 18.807 4.27121 18.258 4.68691 17.755C5.98595 16.1838 7.81379 15.059 9.89231 14.6605C10.0613 14.6294 10.2412 14.6479 10.4079 14.726C10.8071 14.9118 11.231 15.0574 11.6705 15.1549C12.0876 15.2475 12.5337 15.2961 13 15.2961C13.4663 15.2961 13.9124 15.2475 14.3295 15.1549C14.7561 15.0603 15.1675 14.9208 15.5561 14.7429C15.7194 14.6569 15.9126 14.6231 16.1077 14.6605C18.1862 15.059 20.0141 16.1838 21.3131 17.755C21.7288 18.258 22.0909 18.807 22.3902 19.393C23.632 17.5721 24.3593 15.3712 24.3593 13C24.3593 9.86354 23.0877 7.02339 21.032 4.96802Z"
        fill="#150828"
      />
    </svg>
  );
}

export default Account;
