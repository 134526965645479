import './advanced-search.scss';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { storeAddress } from '@/actions';
import Button from '@/atoms/button';
import { isEmpty } from '@/util';
import LocationFilter from './location-filter';
import PetFilter from './pet-filter';
import PetSelect from './pet-select';
import SizeFilter from './size-filter';

export default function AdvancedSearch() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { core } = useAppSelector((state) => state.main);
  const { latitude, longitude } = useAppSelector(
    (state) => state.advancedSearch,
  );
  const { token, data } = useAppSelector((state) => state.user);
  const pets = data.animals;
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const { size, type } = useAppSelector((state) => state.createPetForm.data);

  const userPetSize = activePet.size;
  const userPetType = activePet.type;

  const canContinue =
    (userPetSize && userPetType && latitude && longitude) ||
    (size && type && latitude && longitude);

  const onSubmit = () => {
    if (!canContinue) return;
    dispatch(storeAddress());
    navigate('/pet/groomers/chicago');
  };

  return (
    <div className="advanced-search flex-row w-100 p-24 margin-center b-white radius-12">
      {token && !isEmpty(activePet) ? (
        <PetSelect activePet={activePet} pets={pets} />
      ) : (
        <>
          <PetFilter core={core} type={userPetType || type} />
          <SizeFilter core={core} size={userPetSize || size} />
        </>
      )}
      <LocationFilter />
      <Button
        size="small"
        onClick={onSubmit}
        text="Search"
        className="submit-button"
        variant={!canContinue ? 'disabled' : 'default'}
      />
    </div>
  );
}
