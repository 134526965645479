import { useEffect } from 'react';
import { useAppDispatch } from '@/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { loadProviderLocation } from '@/actions/providers';
import { clearForm } from '@/store/reducers/request-appointment-form';

export function UtmKeywordRedirect() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const utmKeyword = searchParams.get('utm_keyword');
    if (utmKeyword) {
      const providerSlug = utmKeyword.toLowerCase().replace(/ /g, '-');
      dispatch(
        loadProviderLocation(providerSlug, undefined, {
          displayErrorToast: false,
        }),
      )?.then(({ success, data }) => {
        if (success) {
          dispatch(clearForm());
          const { locality, slug } = data.data;
          navigate(`/${locality.toLowerCase()}/p/${slug}`);
        }
      });
    }
  }, []);

  return null;
}
