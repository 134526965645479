import './upload-pet-avatar.scss';
import { Helmet } from 'react-helmet-async';
import Landing from '@/organisms/landing';
import UploadPetAvatarDetails from './upload-pet-avatar-details';

export default function UploadPetAvatar() {
  return (
    <>
      <Helmet title="Upload pet avatar | PawCare">
        <link rel="canonical" href="https://mypawcare.com/upload-avatar" />
      </Helmet>
      <Landing removeOutterPadding>
        <UploadPetAvatarDetails />
      </Landing>
    </>
  );
}
