function Clock() {
  return (
    <svg width="22" height="22" viewBox="0 0 26 26" fill="none">
      <path
        d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z"
        stroke="#222223"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M13 12.9999V8.71423"
        stroke="#222223"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M13.4707 14.0079L18.3565 18.3577"
        stroke="#222223"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default Clock;
