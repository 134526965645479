import './reschedule-appointment-success.scss';

import ReactModal from 'react-modal';
import dayjs from 'dayjs';

import Button from '@/atoms/button';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  dateStart: string;
}

export default function RescheduleAppointmentSuccess({
  isOpen,
  setIsOpen,
  onClose,
  dateStart,
}: Props) {
  const handleDone = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={handleDone}
      className="reschedule-success-modal"
    >
      <div className="text-center flex-column align-center">
        <h2 className="mb-15 f26">Appointment confirmed</h2>
        <p className="mb-32 f15">
          Your appointment has been rescheduled to{' '}
          <strong>{dayjs(dateStart).format('dddd, MMMM D, h:mm a')}</strong>.
        </p>
        <Button size="large" onClick={handleDone} text="Done" />
      </div>
    </ReactModal>
  );
}
