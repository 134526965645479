import { createSlice } from '@reduxjs/toolkit';

interface AdminUserState {
  name: string | null;
  token: string | null;
  uuid: string | null;
}

const initialState: AdminUserState = {
  name: null,
  token: null,
  uuid: null,
};

const adminUser = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    setAdminData(_, { payload }) {
      return payload;
    },
  },
});

export const { setAdminData } = adminUser.actions;

export default adminUser.reducer;
