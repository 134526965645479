import { Helmet } from 'react-helmet-async';
import Landing from '@/organisms/landing';
import FinishSignupMFAInfo from './finish-signup-mfa-info';

export default function FinishSignupMFA() {
  return (
    <>
      <Helmet title="Phone verification | PawCare" />
      <Landing removeOutterPadding>
        <FinishSignupMFAInfo />
      </Landing>
    </>
  );
}
