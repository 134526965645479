import { useState } from 'react';
import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { logOut } from '@/actions/authentication';
import { uploadPetAvatar } from '@/actions/pet';
import { loadUser } from '@/actions/user';
import Button from '@/atoms/button';
import PetSelector from '@/atoms/dropdown/pet-selector';
import Icon from '@/atoms/icon';
import ImageUploader from '@/atoms/image-uploader';
import { InfoBlock } from '@/atoms/info-block';
import PaymentMethodModal from '@/organisms/payment-method-modal';
import { clearForm } from '@/store/reducers/create-pet-form';
import { setActivePet } from '@/store/reducers/pet-appointments';
import { capitalizeFirstLetter } from '@/util';

import CreatePetDetails from '@/pages/create-pet/create-pet-details';
import EditPetModal from '../edit-pet-modal';

export default function PetInformation({ pet }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { data: user, token } = useAppSelector((state) => state.user);
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const [isPetEditModalOpen, openPetEditModal] = useState(false);
  const [isStripeModalOpen, openStripeModal] = useState(false);
  const [isAddPetModalOpen, setIsAddPetModalOpen] = useState(false);

  const { firstName, lastName, phone, email } = pet.user;

  const handlePetSelect = (selectedItem: any) => {
    if (selectedItem.uuid === 'add-pet') {
      createPet();
    } else {
      dispatch(setActivePet(selectedItem));
    }
  };

  const editImage = (image: any) => {
    dispatch(uploadPetAvatar(image, activePet.uuid))?.then(({ success }) => {
      if (success) {
        dispatch(loadUser());
      }
    });
  };

  const createPet = () => {
    if (isMobile) {
      navigate('/create-pet');
    } else toggleAddPetModal();
  };

  const toggleAddPetModal = () => {
    dispatch(clearForm());
    setIsAddPetModalOpen((prevState) => !prevState);
  };

  return (
    <InfoBlock custom>
      <div className="flex-column pet-information">
        <div className="mb-15 ml-35 text-left">
          <ImageUploader
            showEditIcon
            saveImage={editImage}
            fileName="upload_file"
            avatar={
              activePet?.links?.image?.desktop?.large ||
              activePet?.links?.image?.desktop?.large
            }
            placeholder="pawFilled"
            variant="profile"
          />
          <div className="flex-row mt-15">
            {user.animals.length < 1 ? (
              <h2 className="text-black">{activePet.name}</h2>
            ) : (
              <PetSelector onClick={handlePetSelect} title={activePet.name} />
            )}
          </div>

          {activePet.dateOfBirth && (
            <p>{dayjs(activePet.dateOfBirth).format('MMM, DD YYYY')}</p>
          )}
        </div>
        {!isMobile && (
          <div className="flex-column justify-between h-100 align-start">
            <div>
              {(activePet.breed?.value || activePet.size?.value) && (
                <div className="flex-row mb-30">
                  <Icon className="mr-15" name="paw" />
                  <p>
                    {activePet.breed?.value ? activePet.breed.value : ''}
                    {activePet.breed?.value && activePet.size?.value
                      ? ', '
                      : ''}
                    {activePet.size?.value ? activePet.size.value : ''}
                  </p>
                </div>
              )}
              {activePet.sterilization?.isSterilized && (
                <div className="flex-row mb-30">
                  <Icon className="mr-15" name="neutered" />
                  <p>{activePet.gender?.id === 2 ? 'Neutered' : 'Spayed'}</p>
                </div>
              )}
              <div className="flex-row mb-30">
                <Icon className="mr-15" name="user" />
                <p>{`${capitalizeFirstLetter(
                  firstName,
                )} ${capitalizeFirstLetter(lastName)}`}</p>
              </div>
              {phone && (
                <div className="flex-row mb-30">
                  <Icon className="mr-15" name="phone" />
                  <p>{phone?.localized}</p>
                </div>
              )}
              <div className="flex-row mb-30 email text-left">
                <Icon className="mr-15" name="email" />
                <p className="ellipsis">{email}</p>
              </div>
              {user.animals.length <= 1 && (
                <Button
                  className="mt-25"
                  size="medium"
                  variant="reverse"
                  text="Add pet"
                  onClick={createPet}
                />
              )}
            </div>
            <div className="flex-column align-start mt-30">
              <button
                className="text-center pointer pl-10 mb-32 mobile-settings no-style-btn f16 text-black fw-100"
                onClick={() => navigate('/settings')}
              >
                Settings
              </button>
              {token && (
                <button
                  className="logout mb-20 pointer mt-17 no-style-btn f16 text-black fw-100"
                  onClick={() => logOut(dispatch)}
                >
                  Log out
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={isPetEditModalOpen}
        onRequestClose={() => openPetEditModal(false)}
      >
        <EditPetModal petId={activePet.uuid} openModal={openPetEditModal} />
      </ReactModal>
      {isAddPetModalOpen && (
        <ReactModal
          ariaHideApp={false}
          isOpen={isAddPetModalOpen}
          onRequestClose={() => toggleAddPetModal()}
        >
          <CreatePetDetails />
        </ReactModal>
      )}

      <PaymentMethodModal
        isOpen={isStripeModalOpen}
        openModal={openStripeModal}
      />
    </InfoBlock>
  );
}
