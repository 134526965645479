import { RootState } from '@/store';
import { mobileGroomerId } from '@/util/constants';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { NavigateFunction } from 'react-router-dom';

export default function paymentRedirectionMiddleware(
  navigate: NavigateFunction,
): ThunkAction<void, RootState, unknown, AnyAction> {
  // @ts-expect-error Unused 'dispatch' parameter is required for ThunkAction type.
  return (dispatch, getState) => {
    const { token } = getState().user;
    const { service } = getState().requestAppointmentForm;
    const isExperiment = null; // No experiments atm. Leave so we can use in the future
    const isMobileService = service?.type?.id === mobileGroomerId;

    if (!token) {
      const paymentPage = isExperiment ? '' : '/signup/user-details';
      return navigate(paymentPage);
    }

    if (token) {
      if (isMobileService) return navigate('/signup/address?appointment=true');
      return navigate('/booking-payment');
    }
  };
}
