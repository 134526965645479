import { useNavigate } from 'react-router-dom';

import Button from '@/atoms/button';

export default function NoAppointmentsCTA() {
  const navigate = useNavigate();
  return (
    <div className="pet-profile-empty-state">
      <h2>Make your first booking</h2>
      <p className="mt-10 mb-20">
        You need to book an appointment. Browse open time slots, or request an
        appointment.
      </p>
      <Button
        size="medium"
        text="Book appointment"
        className="mt-10"
        onClick={() => {
          navigate(localStorage.getItem('homepageUrl') || '/');
        }}
      />
    </div>
  );
}
