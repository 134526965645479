import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdvancedSearchState {
  latitude: number | null;
  longitude: number | null;
  time: string | null;
  userUuid: string | null;
  petUuid: string | null;
  address_1: string | null;
  locality: string | null;
  region: string | null;
  postalCode: string | null;
}

const initialState: AdvancedSearchState = {
  latitude: null,
  longitude: null,
  time: null,
  userUuid: null,
  petUuid: null,
  address_1: null,
  locality: null,
  region: null,
  postalCode: null,
};

const advancedSearch = createSlice({
  name: 'advancedSearch',
  initialState,
  reducers: {
    editAdvancedSearch(
      state,
      {
        payload,
      }: PayloadAction<{ key: keyof AdvancedSearchState; value: any }>,
    ) {
      const { key, value } = payload;
      (state as any)[key] = value;
    },
    clearForm() {
      return {
        latitude: null,
        longitude: null,
        time: null,
        userUuid: null,
        petUuid: null,
        address_1: null,
        locality: null,
        region: null,
        postalCode: null,
      };
    },
  },
});
const { actions, reducer } = advancedSearch;

export const { editAdvancedSearch, clearForm } = actions;

export default reducer;
