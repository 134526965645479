import './vaccination-expired-modal.scss';

import ReactModal from 'react-modal';
import { useAppDispatch } from '@/hooks';

import Button from '@/atoms/button';
import { openDrawer } from '@/store/reducers/ui';

interface VaccinationExpiredModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function VaccinationExpiredModal({
  isOpen,
  setIsOpen,
}: VaccinationExpiredModalProps) {
  const dispatch = useAppDispatch();

  const onUpdate = () => {
    setIsOpen(false);
    dispatch(openDrawer({ type: 'update-vaccinations' }));
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className="vaccination-expired-modal"
      onRequestClose={() => setIsOpen(false)}
    >
      <div className="flex-column gap-12 align-center">
        <h2 className="payment-method-modal-title">Vaccination expired</h2>
        <p className="f15 fw-400 text-center">
          Please update your vaccination records to complete your booking. Bring
          in a copy of the records on the day of your appointment or it may be
          cancelled.
        </p>
        <div className="flex-row gap-16 mt-20">
          <Button size="medium" onClick={onUpdate} text="Update" />
          <Button
            size="medium"
            variant="reverse"
            onClick={() => setIsOpen(false)}
            text="Cancel"
          />
        </div>
      </div>
    </ReactModal>
  );
}
