import './slots-loader.scss';

import { generateKey } from '@/util';

function SlotsLoader() {
  return (
    <div className="flex-column mt-15">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((el) => (
        <div className="slot-loader" key={`${generateKey()}-${el}`} />
      ))}
    </div>
  );
}

export default SlotsLoader;
