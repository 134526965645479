import { useState } from 'react';
import { useAppDispatch } from '@/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import { resetPassword } from '@/actions/authentication';
import Button from '@/atoms/button';
import Input from '@/atoms/input';
import toast from '@/atoms/toast';

export default function NewPasswordInfo() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');

  const onChangePassword = (e: any) => {
    e.preventDefault();
    dispatch(
      resetPassword({ confirmPassword, password, code: params.code }),
    )?.then(({ success }) => {
      if (success) {
        toast({ text: 'Password changed successfully' });
        if (process.env.REACT_APP_MODE !== 'development') {
          return window.location.assign('https://mypawcare.com/link');
        }
        navigate('/login');
      }
    });
  };

  return (
    <div className="login">
      <h1>Reset password</h1>
      <form onSubmit={onChangePassword}>
        <Input
          id="password"
          label="New password"
          type="password"
          onChange={(value) => setPassword(value)}
          value={password}
        />
        <div className="mt-35">
          <Input
            id="confirmPassword"
            label="Confirmed password"
            type="password"
            onChange={(value) => setConfirmPassword(value)}
            value={confirmPassword}
          />
        </div>

        <Button className="mt-25" type="submit" text="Update" />
      </form>
    </div>
  );
}
