import { styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ReactNode } from 'react';

const Tooltip = styled(
  ({
    children,
    title,
    placement = 'top-end',
    className,
  }: {
    children: ReactNode;
    title?: string;
    placement?:
      | 'top-end'
      | 'bottom'
      | 'left'
      | 'right'
      | 'top'
      | 'bottom-end'
      | 'bottom-start'
      | 'left-end'
      | 'left-start'
      | 'right-end'
      | 'right-start'
      | 'top-start';
    className?: string;
  }) => (
    <MuiTooltip
      title={title}
      placement={placement}
      classes={{ popper: className }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -5],
              },
            },
          ],
        },
      }}
    >
      <div className="pointer flex-row">{children}</div>
    </MuiTooltip>
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 20px 0px #c2c2c2',
    fontSize: 12,
    borderRadius: 12,
    padding: 10,
    fontWeight: 200,
  },
}));

export default Tooltip;
