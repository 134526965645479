import Icon from '@/atoms/icon';
import { IconName } from '../icon/icons';

interface PlaceholderImageProps {
  placeholder: IconName;
  defaultIconSize: number;
  cameraVariant: boolean;
}

export default function PlaceholderImage({
  placeholder,
  defaultIconSize,
  cameraVariant,
}: PlaceholderImageProps) {
  return (
    <Icon
      name={cameraVariant ? 'camera' : placeholder}
      width={defaultIconSize}
      height={defaultIconSize}
    />
  );
}
