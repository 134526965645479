import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Checkbox from '@/atoms/checkbox';

export default function MobileProviderFilter() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const isChecked = params.providerType === 'mobile-grooming';

  const onClick = (checked: boolean) => {
    const { pathname } = location;
    let updatedPath;
    if (checked) {
      updatedPath = params.providerType
        ? pathname.replace(params.providerType, 'mobile-grooming')
        : 'type/mobile-grooming';
    } else {
      updatedPath = params.providerType
        ? pathname.replace(params.providerType, 'all')
        : 'type/all';
    }
    navigate(updatedPath + location.search);
  };

  return (
    <div
      className={classNames(
        'flex-row mobile-provider-filter',
        isChecked && 'mobile-provider-filter--checked',
      )}
    >
      <Checkbox
        id="mobile-groomers"
        value={isChecked}
        onClick={({ isChecked }) => onClick(isChecked)}
        variant="button"
        contentPosition="left"
      >
        <p className="f11 mr-5">Mobile grooming only</p>
      </Checkbox>
    </div>
  );
}
