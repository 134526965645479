function Email() {
  return (
    <svg width="26" height="20" viewBox="0 0 26 20" fill="none">
      <path
        d="M25 4V15.9999C25 16.7956 25 18.9999 25 18.9999H1V18.1212C1 18.1212 1.00019 16.7956 1.00019 15.9999V4"
        stroke="#150828"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M24.9998 3.99998C24.9998 3.20433 24.9998 1 24.9998 1H1C1 1 1 3.20433 1 3.99998L12.9999 10.9559L24.9998 3.99998Z"
        stroke="#150828"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default Email;
