import './slots-by-day.scss';

import dayjs from 'dayjs';

import Slot from '@/atoms/slot';
import SlotsLoader from '@/organisms/loaders/slots-loader';

interface Props {
  availableSlots: any;
  startDate: any;
  isLoading: boolean;
  item: any;
  selectSlot: (data: any) => void;
  displayDate: boolean;
}

export function SlotsByDay({
  availableSlots,
  startDate,
  isLoading,
  item,
  selectSlot,
  displayDate,
}: Props) {
  const formattedStartDate = dayjs(startDate).format('dddd, MMM D');

  const onClick = (time: any, date: any) => {
    selectSlot({ provider: item, slot: { time, date } });
  };

  if (isLoading) {
    return (
      <div className="mb-25 h-100 mb-15 slots-by-day">
        <h4 className="f18">{formattedStartDate}</h4>
        <SlotsLoader />
      </div>
    );
  }

  if (
    !availableSlots ||
    availableSlots.length === 0 ||
    availableSlots.time?.length === 0
  ) {
    return (
      <div className="mb-25 h-100 slots-by-day">
        <h4 className="mb-5 f18">{formattedStartDate}</h4>
        <p className="f13 text-grey-dark">
          There&apos;re no slots available on this day
        </p>
      </div>
    );
  }

  return (
    <div className="mb-25 h-100 slots-by-day" key={availableSlots.date}>
      <h4 className="mb-15 f18">{formattedStartDate}</h4>
      <div className="slots-by-day-slots flex-column">
        {availableSlots.time.map((time: any) => (
          <Slot
            className="booking-modal-slots"
            id={time}
            key={time}
            time={time}
            date={availableSlots.date}
            location={item}
            displayDate={displayDate}
            onClick={() => onClick(time, availableSlots.date)}
            stopPropagation
          />
        ))}
      </div>
    </div>
  );
}
