import './service-block.scss';

import { useState } from 'react';
import { useAppDispatch } from '@/hooks';
import classNames from 'classnames';
import Button from '@/atoms/button';
import ServiceViewMoreModal from '@/organisms/service-view-more-modal';
import { formatHoursAndMinutes, serviceDetails } from '@/util';
import {
  trackServiceSelected,
  trackServiceViewed,
} from '@/util/tracking/events';

interface ServiceBlockProps {
  onSelectService: (service: any) => void;
  service: any;
  className?: string;
  viewMoreAction?: () => void;
  displayFullDescripton?: boolean;
  disableSelect?: boolean;
  location: any;
}

export default function ServiceBlock({
  onSelectService,
  service,
  className,
  viewMoreAction,
  displayFullDescripton,
  disableSelect,
  location,
}: ServiceBlockProps) {
  const dispatch = useAppDispatch();
  const [isOpen, openModal] = useState(false);
  const MAX_DESCRIPTION_LENGTH = 161;
  const disableViewMore = service.description.length <= MAX_DESCRIPTION_LENGTH;

  const onViewMore = () => {
    dispatch(trackServiceViewed(service, location));
    if (viewMoreAction) return viewMoreAction();
    openModal(true);
  };

  const onClick = () => {
    onSelectService(service);
    dispatch(trackServiceSelected(service, location));
  };

  if (!service) return;

  return (
    <div className={classNames('service-block mb-32', className)}>
      <div className="service-block-name flex-row align-start justify-between mb-15">
        <div className="flex-column">
          <p className="bold">{`${service.name}`}</p>
          <p className="f13">{serviceDetails(service)}</p>
          {service.durationMinutes && (
            <p className="italic text-grey-dark f13">{`Duration: ${formatHoursAndMinutes(service.durationMinutes)}`}</p>
          )}
        </div>
        <p className="bold service-block-price pl-48">
          {service.price?.localized}+
        </p>
      </div>
      <p
        className={classNames(
          displayFullDescripton
            ? 'service-block-text--full'
            : 'service-block-text--summary',
        )}
      >
        {service.description}
      </p>
      <div
        className={classNames(
          'flex-row align-center mt-5 actions width-100 mt-15',
          'actions-on-hover',
        )}
      >
        {!disableSelect && (
          <Button onClick={onClick} size="small" text="Select service" />
        )}
        {!disableViewMore && (
          <span
            tabIndex={0}
            className="text-orange ml-15 pointer f15 fw-100 view-more"
            role="button"
            onClick={onViewMore}
          >
            View more
          </span>
        )}
      </div>
      <ServiceViewMoreModal
        isOpen={isOpen}
        openModal={openModal}
        service={service}
      />
    </div>
  );
}
