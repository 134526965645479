function MapDrop({ fill = 'black' }) {
  return (
    <svg width="20" height="26" viewBox="0 0 20 26" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 26C11.6667 26 20 15.1348 20 9.75C20 4.36522 15.5228 0 10 0C4.47715 0 0 4.36522 0 9.75C0 15.1348 8.33333 26 10 26ZM10 16.25C13.6819 16.25 16.6667 13.3399 16.6667 9.75C16.6667 6.16015 13.6819 3.25 10 3.25C6.3181 3.25 3.33333 6.16015 3.33333 9.75C3.33333 13.3399 6.3181 16.25 10 16.25Z"
        fill={fill}
      />
    </svg>
  );
}

export default MapDrop;
