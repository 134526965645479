import { unsubscribe } from '@/actions/user';
import './unsubscribe.scss';

import Button from '@/atoms/button';
import Icon from '@/atoms/icon';
import { useAppDispatch } from '@/hooks';
import useQuery from '@/hooks/useQuery';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

function UnsubscribePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const [status, setStatus] = useState<'loading' | 'error' | 'unsubscribed'>(
    'loading',
  );

  const email = query.get('email');
  const code = query.get('code');

  useEffect(() => {
    if (!email || !code) return;
    setStatus('loading');
    dispatch(unsubscribe({ email, code }))?.then(({ success }) => {
      setStatus(success ? 'unsubscribed' : 'error');
    });
  }, [email, code]);

  if (!email || !code) {
    navigate('/');
    return null;
  }

  return (
    <div className="unsubscribe-page flex-column align-center justify-center w-100">
      <Helmet title="Unsubscribe | PawCare" />
      <div className="unsubscribe-page-content flex-column align-center w-100">
        <Icon name="mail" className="mb-12" />
        <h1 className="f26 bold mb-8">Unsubscribe</h1>
        <p className="f16 text-center mb-24">
          {status === 'loading' && 'Loading...'}
          {status === 'error' &&
            'Oops! Something went wrong. Please contact us at support@mypawcare.com.'}
          {status === 'unsubscribed' &&
            'You have been unsubscribed from marketing emails. You can close this tab, or book an appointment.'}
        </p>
        <Button
          variant="white-outlined"
          size="medium"
          text="Go to home page"
          onClick={() => navigate('/')}
        />
      </div>
    </div>
  );
}

export default UnsubscribePage;
