import './admin-navbar.scss';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';

import { impersonateUser, loadUser } from '@/actions/user';
import Icon from '@/atoms/icon';
import ConsumerSearch from '@/organisms/user-search/consumer-search';
import { userSetCredentials } from '@/store/reducers/user';
import AdminButton from './admin-button';

function AdminNavbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAdmin, uuid: currentUserUuid } = useAppSelector(
    (state) => state.user.data,
  );
  const { uuid: adminUuid, token: adminToken } = useAppSelector(
    (state) => state.adminUser,
  );
  const [isOpen, openSearchModal] = useState(false);
  const isImpersonating = adminUuid && currentUserUuid !== adminUuid;
  const userToImpersonateId = location.pathname.split('/')[2];

  const setState = async (state: boolean) => {
    if (state) {
      dispatch(impersonateUser(userToImpersonateId));
    } else {
      dispatch(userSetCredentials({ token: adminToken, uuid: adminUuid }));
      dispatch(loadUser(adminUuid));
      navigate(`/user/${adminUuid}/pet`);
    }
  };

  const hideUrls = ['/create-pet', '/upload-avatar', '/openapp'];

  if (
    !isAdmin ||
    location.pathname === '/' ||
    (hideUrls.includes(location.pathname) && !isImpersonating)
  )
    return null;

  return (
    <>
      <div className="admin-navbar mr-20 flex-row">
        <AdminButton
          setState={setState}
          isImpersonating={!!isImpersonating}
          // canImpersonate={userToImpersonateId && !isMyProvider}
        />
        {!isImpersonating && (
          <button
            className="search-button pointer admin-nav-button"
            onClick={() => openSearchModal(true)}
          >
            <Icon name="search" fill="white" />
          </button>
        )}
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => openSearchModal(false)}
      >
        <ConsumerSearch setIsOpen={openSearchModal} />
      </ReactModal>
    </>
  );
}

export default AdminNavbar;
