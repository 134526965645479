import '../user-search.scss';

import { useState } from 'react';
import { useAppDispatch } from '@/hooks';
import { useNavigate } from 'react-router-dom';

import { searchConsumers } from '@/actions';
import { impersonateUser } from '@/actions/user';
import DefaultAvatar from '@/atoms/default-avatar';
import Input from '@/atoms/input';

function ConsumerSearch({
  setIsOpen,
}: {
  setIsOpen?: (bool: boolean) => void;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchResults, setSearchResults] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (search: string) => {
    if (!search) setSearchResults(null);
    setValue(search);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
      setIsLoading(false);
    }
    if (search) {
      setIsLoading(true);
      setSearchResults(null);
      const timeoutId = setTimeout(() => {
        dispatch(searchConsumers(search))?.then(({ success, data }) => {
          if (success) {
            setSearchResults(data.data.consumers);
          }
          setIsLoading(false);
        });
      });
      setTypingTimeout(timeoutId);
    }
  };

  const onClickItem = (uuid: string) => {
    dispatch(impersonateUser(uuid))?.then(({ success }) => {
      if (success) {
        if (setIsOpen) {
          setIsOpen(false);
        }
        navigate(`/user/${uuid}/pet`);
      }
    });
  };

  const renderList = () => {
    if (isLoading) return 'Loading';
    if (!searchResults) return '';
    if (searchResults && !searchResults.length) return '0 results found';
    if (searchResults?.length) {
      return searchResults.map((el: any) => (
        <button
          key={el.uuid}
          className="flex-row provider no-style-btn w-100 pointer"
          onClick={() => onClickItem(el.uuid)}
        >
          <DefaultAvatar
            size="small"
            avatar={el.links?.image?.desktop?.large}
          />
          <div className="ml-10">
            <h3 className="bold text-left">{`${el.firstName} ${el.lastName}`}</h3>
            <p className="text-grey-dark text-left">{el.email}</p>
          </div>
        </button>
      ));
    }
  };

  return (
    <div className="w-100 user-search flex-column align-center">
      <div className="user-search-box">
        <h2 className="mb-15">User search</h2>
        <Input
          placeholder="Laura Smith"
          icon="search"
          className="admin-searchbar"
          onChange={handleSearch}
          value={value}
          autoFocus
        />
      </div>
      <div className="providers-list">{renderList()}</div>
    </div>
  );
}

export default ConsumerSearch;
