import './map.scss';

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { GoogleMap, Marker } from '@react-google-maps/api';
import debounce from 'lodash.debounce';

import { appointmentFinder } from '@/actions/appointments';
import Icon from '@/atoms/icon';
import MapPin from '@/images/MapPin.svg';
import MapPinSelected from '@/images/MapPinSelected.svg';
import { findMiddleLatLng } from '@/util';
import { chicagoLatLng } from '@/util/constants';
import { useGoogleMaps } from '@/config/google-maps-context';

function Map({
  onClick,
  filters,
}: {
  onClick: (id: string) => void;
  filters: any;
}) {
  const dispatch = useAppDispatch();
  const mapRef = useRef(null);
  const { isLoaded } = useGoogleMaps();
  const { mapLocation } = useAppSelector((state) => state.ui);
  const items = useAppSelector((state) => state.hardInventory.providers);
  const [activeMarker, setActiveMarker] = useState({
    position: null,
    id: '',
  });
  const [showSearchButton, setShowSearchButton] = useState(false);
  const [zoomCount, setZoomCount] = useState(0);
  const middleLocation = findMiddleLatLng(items);
  const [initialCenter, setInitialCenter] = useState({
    lat:
      activeMarker?.position?.lat ||
      mapLocation.lat ||
      middleLocation?.geo?.latitude ||
      chicagoLatLng.lat,
    lng:
      activeMarker?.position?.lng ||
      mapLocation.lng ||
      middleLocation?.geo?.longitude ||
      chicagoLatLng.lng,
  });
  const markersPadding = {
    top: 50,
    right: 0,
    left: 0,
    bottom: 0,
  };
  // Map controls
  const mapOptions = {
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    panControl: false,
    rotateControl: false,
  };
  const markers = items.map(
    ({ uuid, name, geo }: { uuid: string; name: string; geo: any }) => ({
      id: uuid,
      name,
      position: { lat: geo.latitude, lng: geo.longitude },
    }),
  );
  const locations = items.map(({ geo }: { geo: any }) => ({
    lat: geo.latitude,
    lng: geo.longitude,
  }));

  useEffect(() => {
    // We'll set bounds only when new items are loaded without using
    // the "search this area" button
    if (mapRef.current && !showSearchButton) {
      setMapToBounds(mapRef.current);
    }
    setShowSearchButton(false);
  }, [items, mapRef.current]);

  const setMapToBounds = (map: any) => {
    if (!map) return;
    const bounds = new window.google.maps.LatLngBounds();

    if (!locations.length) {
      const defaultCenter = new window.google.maps.LatLng(
        chicagoLatLng.lat,
        chicagoLatLng.lng,
      );
      map.setCenter(defaultCenter);
      map.setZoom(10);
      return;
    }

    locations.forEach((location) => {
      bounds.extend(new window.google.maps.LatLng(location.lat, location.lng));
    });
    map.fitBounds(bounds, markersPadding);
    if (locations?.length < 3) {
      map.setZoom(15);
    }
  };

  const delayedShowSearchButtonUpdate = debounce(() => {
    setShowSearchButton(true);
  }, 100);

  const getMapCenter = () => {
    if (mapRef.current) {
      // Get the current bounds of the map
      const bounds = mapRef.current.getBounds();

      // Check if bounds are available
      if (bounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        // Calculate the center of the bounds
        return {
          lat: (ne.lat() + sw.lat()) / 2,
          lng: (ne.lng() + sw.lng()) / 2,
        };
      }
    }
  };

  const handleSearchArea = async () => {
    const center = getMapCenter();
    const { dateStart, providerType } = filters;
    await dispatch(
      appointmentFinder({
        dateStart,
        includeAllProviders: true,
        lat: center?.lat,
        lng: center?.lng,
        radius: 3,
        providerType,
      }),
    );
  };

  const handleActiveMarker = (markerId: string, markerPosition: any) => {
    if (markerId === activeMarker?.id) {
      return;
    }
    setActiveMarker({ id: markerId, position: markerPosition });
    onClick(markerId);
  };

  const onMapDrag = () => {
    delayedShowSearchButtonUpdate();
  };

  if (!isLoaded) return;

  return (
    <GoogleMap
      onLoad={(map) => {
        mapRef.current = map;
        setMapToBounds(map);
      }}
      defaultCenter={initialCenter}
      locations={locations}
      onDragEnd={onMapDrag}
      onZoomChanged={() => {
        // We don't want to display the "search this area" when the map
        // zooms in after the initial calls. This is kind of hacky, need to find a better way
        if (zoomCount > 4) delayedShowSearchButtonUpdate();
        setZoomCount((prevCount) => prevCount + 1);
      }}
      mapContainerStyle={{ width: '100%', height: '100%' }}
      options={mapOptions}
      onTilesLoaded={() => {
        setInitialCenter(null);
      }}
    >
      {showSearchButton && (
        <div className="search-button-container pos-absolute flex-row justify-center">
          <button
            className="map-search-button pointer b-white f12 flex-row"
            onClick={handleSearchArea}
          >
            <Icon
              name="search"
              className="mr-8"
              width={14}
              height={14}
              fill="black"
            />
            Search this area
          </button>
        </div>
      )}
      {markers.map(({ id, position }) => (
        <Marker
          key={id}
          icon={{
            url: activeMarker?.id === id ? MapPinSelected : MapPin,
          }}
          position={position}
          onClick={() => handleActiveMarker(id, position)}
        />
      ))}
    </GoogleMap>
  );
}

export default Map;
