import { v4 as uuidv4 } from 'uuid';
import { hashString } from '@/util';
import { RootState } from '@/store';

declare global {
  interface Window {
    klaviyo: any;
    dataLayer: any;
  }
}

const isMobile = () => window.innerWidth < 600;

const getUserData = async (getState: () => RootState) => {
  const { user, advancedSearch, adminUser } = getState();

  if (!user.token) return null;

  const { email, phone, uuid, firstName, lastName, addresses } = user.data;

  const isImpersonating = adminUser.uuid && uuid !== adminUser.uuid;

  const hashedEmail = await hashString(email);
  const hashedFirstName = await hashString(firstName);
  const hashedLastName = await hashString(lastName);
  const hashedPhoneNumber = await hashString(phone?.localized);
  const hashedUuid = await hashString(user.uuid as string);
  const hashedCity = await hashString(
    advancedSearch.locality || addresses?.[0]?.locality,
  );
  const hashedState = await hashString(
    advancedSearch.region || addresses?.[0]?.region,
  );
  const hashedPostalCode = await hashString(
    advancedSearch.postalCode || addresses?.[0]?.postalCode,
  );
  const hashedStreet = await hashString(
    advancedSearch.address_1 || addresses?.[0]?.address,
  );

  return {
    em: hashedEmail,
    ph: hashedPhoneNumber,
    fn: hashedFirstName,
    ln: hashedLastName,
    external_id: hashedUuid,
    csm: isImpersonating,
    city: hashedCity,
    state: hashedState,
    postalCode: hashedPostalCode,
    st: hashedStreet,
  };
};

/* Use whenever we're running an experiment */
const experimentId = () => null;

export const trackSignup = () => async (_: any, getState: () => RootState) => {
  const unixTimestamp = Math.floor(Date.now() / 1000);
  const userData = await getUserData(getState);
  const eventId = uuidv4();
  // eslint-disable-next-line camelcase
  const experiment_id = experimentId();

  // GA
  window.dataLayer.push({
    event: 'sign_up',
    event_time: unixTimestamp,
    event_id: eventId,
    action_source: 'website',
    isMobile: isMobile(),
    user_data: userData,
    // eslint-disable-next-line camelcase
    experiment_id,
  });
};

export const trackPetInfo = () => async (_: any, getState: () => RootState) => {
  const unixTimestamp = Math.floor(Date.now() / 1000);
  const userData = await getUserData(getState);
  const eventId = uuidv4();
  // eslint-disable-next-line camelcase
  const experiment_id = experimentId();

  // GA
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'pet_info',
    event_time: unixTimestamp,
    event_id: eventId,
    action_source: 'website',
    isMobile: isMobile(),
    // eslint-disable-next-line camelcase
    experiment_id,
    user_data: userData,
  });
};

export const trackServiceSelected =
  (service: any, location: any) =>
  async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const userData = await getUserData(getState);
    const { promotionCode } = getState().invoice;
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();
    const { uuid, name, type, animal, size, hair, breed, price } = service;

    const trackingData = {
      event_time: unixTimestamp,
      event_id: eventId,
      // eslint-disable-next-line camelcase
      experiment_id,
      action_source: 'website',
      isMobile: isMobile(),
      user_data: userData,
      ecommerce: {
        items: [
          {
            item_id: uuid,
            item_name: name,
            coupon: promotionCode,
            currency: 'USD',
            item_brand: location?.name,
            item_category: type?.value,
            item_category2: animal?.type?.value,
            item_category3: size?.value,
            item_category4: hair?.length?.value,
            item_category5: breed?.value,
            price: price?.amount,
            quantity: 1,
          },
        ],
      },
      provider_data: {
        location_name: location.name,
      },
    };

    // Klavyio
    if (process.env.REACT_APP_DOMAIN !== 'http://localhost:3000') {
      window.klaviyo.push(['track', 'select_service', trackingData]);
    }

    // GA
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({ event: 'select_service', ...trackingData });
  };

export const trackServiceViewed =
  (service: any, location: any) =>
  async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const userData = await getUserData(getState);
    const { promotionCode } = getState().invoice;
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();
    const { uuid, name, type, animal, size, hair, breed, price } = service;

    const trackingData = {
      event_time: unixTimestamp,
      // eslint-disable-next-line camelcase
      experiment_id,
      event_id: eventId,
      user_data: userData,
      action_source: 'website',
      isMobile: isMobile(),
      ecommerce: {
        items: [
          {
            item_id: uuid,
            item_name: name,
            coupon: promotionCode,
            currency: 'USD',
            item_category: type?.value,
            item_category2: animal?.type?.value,
            item_category3: size?.value,
            item_category4: hair?.length?.value,
            item_category5: breed?.value,
            price: price?.amount,
            quantity: 1,
          },
        ],
      },
      provider_data: {
        location_name: location?.name,
      },
    };

    // Klavyio
    if (process.env.REACT_APP_DOMAIN !== 'http://localhost:3000') {
      window.klaviyo.push(['track', 'view_service', trackingData]);
    }

    // GA
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({ event: 'view_service', ...trackingData });
  };

export const trackSlotSelected =
  (time: string, location: any) =>
  async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const userData = await getUserData(getState);
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();

    // GA
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'select_time_slot',
      // eslint-disable-next-line camelcase
      experiment_id,
      event_time: unixTimestamp,
      event_id: eventId,
      user_data: userData,
      action_source: 'website',
      isMobile: isMobile(),
      provider_data: {
        time,
        location_name: location.name,
      },
    });
  };

export const trackViewProvider =
  (name: string) => async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const userData = await getUserData(getState);
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'view_provider',
      event_time: unixTimestamp,
      // eslint-disable-next-line camelcase
      experiment_id,
      event_id: eventId,
      user_data: userData,
      action_source: 'website',
      isMobile: isMobile(),
      provider_data: {
        location_name: name,
      },
    });
  };

export const trackViewProviderList =
  () => async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const userData = await getUserData(getState);
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'view_provider_list',
      event_time: unixTimestamp,
      // eslint-disable-next-line camelcase
      experiment_id,
      event_id: eventId,
      user_data: userData,
      action_source: 'website',
      isMobile: isMobile(),
    });
  };

export const trackUserSearch =
  () => async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const userData = await getUserData(getState);
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'user_search',
      event_time: unixTimestamp,
      event_id: eventId,
      // eslint-disable-next-line camelcase
      experiment_id,
      user_data: userData,
      action_source: 'website',
      isMobile: isMobile(),
    });
  };

export const trackCheckout =
  ({ location, service = {} }: { location: any; service: any }) =>
  async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const { promotionCode } = getState().invoice;
    const userData = await getUserData(getState);
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();

    if (!service) return;

    const trackingData = {
      event_time: unixTimestamp,
      event_id: eventId,
      // eslint-disable-next-line camelcase
      experiment_id,
      user_data: userData,
      action_source: 'website',
      isMobile: isMobile(),
      ecommerce: {
        items: [
          {
            item_id: service.uuid,
            item_name: service.name,
            coupon: promotionCode,
            currency: 'USD',
            item_brand: location?.name,
            item_category: service.type?.value,
            item_category2: service.animal?.type?.value,
            item_category3: service.size?.value,
            item_category4: service.hair?.length?.value,
            item_category5: service.breed?.value,
            price: service.price?.amount,
            quantity: 1,
          },
        ],
      },
    };

    // Klavyio
    if (process.env.REACT_APP_DOMAIN !== 'http://localhost:3000') {
      window.klaviyo.push(['track', 'view_service', trackingData]);
    }

    // GA
    window.dataLayer.push({ event: 'checkout', ...trackingData });
  };

export const trackAddPayment =
  () => async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const userData = await getUserData(getState);
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();

    window.dataLayer.push({
      event: 'add_payment_details',
      // eslint-disable-next-line camelcase
      experiment_id,
      event_time: unixTimestamp,
      event_id: eventId,
      user_data: userData,
      action_source: 'website',
      isMobile: isMobile(),
    });
  };

export const trackPurchase =
  () => async (_: any, getState: () => RootState) => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const userData = await getUserData(getState);
    const eventId = uuidv4();
    // eslint-disable-next-line camelcase
    const experiment_id = experimentId();

    window.dataLayer.push({
      event: 'purchase',
      // eslint-disable-next-line camelcase
      experiment_id,
      event_time: unixTimestamp,
      event_id: eventId,
      user_data: userData,
      action_source: 'website',
      isMobile: isMobile(),
    });
  };
