import './slot-select.scss';

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import paymentRedirectionMiddleware from '@/helpers/payment-redirection';

import { loadAvailableGroomers } from '@/actions/groomers';
import { loadProviderLocation } from '@/actions/providers';
import useSignupSteps from '@/hooks/useSignupSteps';
import ProgressBar from '@/organisms/progress-bar';
import { editForm } from '@/store/reducers/request-appointment-form';
import { getRandomGroomer } from '@/util';
import SlotsCalendar from '@/organisms/slots-calendar';

function SlotSelectInfo() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    location: locationUuid,
    dateStart,
    locationUser,
    service,
  } = useAppSelector((state) => state.requestAppointmentForm);
  const [numberOfSteps] = useSignupSteps();

  useEffect(() => {
    dispatch(loadProviderLocation(locationUuid));
  }, []);

  const onSelectSlot = async (data: any) => {
    if (!data) return;
    const { slot } = data;
    const { date, time } = slot;
    dispatch(editForm({ key: 'dateStart', value: `${date}T${time}` }));

    // TODO: This should be done in the BE. Based on the slot selected
    // we're fetching available groomers, to then randomly grab one and
    // send it back to the server with the booking info
    if (locationUser === 'any') {
      await dispatch(
        loadAvailableGroomers({
          luid: locationUuid,
          date,
          time,
          suid: service?.uuid,
        }),
      )?.then(({ success, data }) => {
        if (success) {
          const groomers = data.data;
          const randomGroomer = getRandomGroomer(groomers);
          dispatch(editForm({ key: 'locationUser', value: randomGroomer }));
        }
      });
    }

    dispatch(paymentRedirectionMiddleware(navigate));
  };

  return (
    <div className="flex-column align-start justify-center slot-select-page">
      <div className="mobile-page-container w-100">
        <div className="flex-row w-100">
          <div className="w-100">
            <h1 className="mb-12">Choose date and time</h1>
            <ProgressBar
              maxSteps={numberOfSteps as any}
              currentStep={4}
              className="mb-32 width-100"
            />
          </div>
        </div>
        <SlotsCalendar
          locationUuid={locationUuid}
          onSelectSlot={onSelectSlot}
          dateStart={dateStart}
          displayHeader={false}
        />
      </div>
    </div>
  );
}

export default SlotSelectInfo;
