import { Helmet } from 'react-helmet-async';

import Landing from '@/organisms/landing';

import FinishSignupInfo from './finish-signup-info';

export default function FinishSignup() {
  return (
    <>
      <Helmet title="Set password | PawCare">
        <link
          rel="canonical"
          href="https://mypawcare.com/signup/finish-signup"
        />
      </Helmet>
      <Landing removeOutterPadding>
        <FinishSignupInfo />
      </Landing>
    </>
  );
}
