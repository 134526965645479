import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { uploadPetAvatar } from '@/actions/pet';
import { loadUser } from '@/actions/user';
import Button from '@/atoms/button';
import ImageUploader from '@/atoms/image-uploader';
import { capitalizeFirstLetter } from '@/util';

export default function UploadPetAvatarDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [image, saveImage] = useState<FormData>();
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const advancedSearchPetUuid = useAppSelector(
    (state) => state.advancedSearch.petUuid,
  );
  const animalUuid = activePet.uuid || advancedSearchPetUuid;
  const isMobile = width < 600;

  const onNext = (e: any) => {
    e.preventDefault();

    if (!image) {
      loadUserInfo();
    } else {
      dispatch(uploadPetAvatar(image, animalUuid))?.then(({ success }) => {
        if (success) {
          loadUserInfo();
        }
      });
    }
  };

  const loadUserInfo = () => {
    dispatch(loadUser())?.then(({ success, data }) => {
      if (success) {
        navigate(`/user/${data.data.uuid}/pet/${animalUuid}`);
      }
    });
  };

  return (
    <div
      className={classNames(
        'flex-column align-center text-center',
        isMobile && 'upload-avatar-page-mobile',
      )}
    >
      <Helmet title="Pet avatar | PawCare" />
      <h1>{`Add a photo of ${capitalizeFirstLetter(activePet.name)}`}</h1>
      <form
        onSubmit={onNext}
        className={classNames('flex-column align-center', isMobile && 'w-100')}
      >
        <div className="mobile-container">
          <ImageUploader
            saveImage={saveImage}
            placeholder="pawFilled"
            cameraVariant
            showChangeImageText
            showEditIcon={false}
            fileName="upload_file"
            size="big"
            variant="profile"
            className="margin-center"
          />
        </div>
        <div
          className={classNames(
            isMobile ? 'mobile-main-action-btn' : 'margin-center mt-20',
          )}
        >
          <Button
            type="submit"
            text="Next"
            variant="default"
            size="medium"
            className="w-100"
          />
        </div>
        {!isMobile && (
          <button
            className="mt-10 text-grey-dark pointer b-transparent border-none"
            onClick={loadUserInfo}
          >
            Skip this step
          </button>
        )}
      </form>
    </div>
  );
}
