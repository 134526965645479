function Add({ fill = '#150828', width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M12.667 8.667h-4v4H7.333v-4h-4V7.333h4v-4h1.334v4h4v1.334Z"
      />
    </svg>
  );
}

export default Add;
