import './info-block.scss';

import classNames from 'classnames';
import { ReactNode } from 'react';

export function InfoBlock({
  children,
  custom,
}: {
  children: ReactNode;
  custom?: boolean;
}) {
  return (
    <div className={classNames('info-block', custom && 'info-block--custom')}>
      {children}
    </div>
  );
}
