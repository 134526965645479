import { createSlice } from '@reduxjs/toolkit';

interface SignupFormState {
  data: {
    countryCodeId: { code: string; country: string; id: number };
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    isMarketable: boolean;
  };
  validated: boolean;
  validationErrors: any;
  isLoadingValidation: boolean;
}

const initialState: SignupFormState = {
  data: {
    countryCodeId: { code: '1', country: 'United States', id: 232 },
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    isMarketable: false,
  },
  validated: false,
  validationErrors: null,
  isLoadingValidation: false,
};

const signupForm = createSlice({
  name: 'signupForm',
  initialState,
  reducers: {
    signupDataValidateRequest(state) {
      state.isLoadingValidation = true;
    },
    signupDataValidateSuccess(state) {
      state.validated = true;
      state.isLoadingValidation = false;
    },
    signupDataValidateFailure(state, { payload }) {
      state.validationErrors = payload;
      state.isLoadingValidation = false;
    },
    editForm(state, { payload }) {
      const { key, value } = payload as {
        key: keyof SignupFormState['data'];
        value: string;
      };
      (state as any).data[key] = value;
    },
    clearForm(state) {
      state.data = {
        countryCodeId: { code: '1', country: 'United States', id: 232 },
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        isMarketable: false,
      };
    },
  },
});

const { actions, reducer } = signupForm;

export const {
  editForm,
  clearForm,
  signupDataValidateRequest,
  signupDataValidateSuccess,
  signupDataValidateFailure,
} = actions;

export default reducer;
