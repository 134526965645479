import { Helmet } from 'react-helmet-async';

import Checkout from '@/organisms/checkout';

import PetInfo from './pet-info';

export default function PetSelect() {
  return (
    <>
      <Helmet title="Select pet for booking | PawCare" />
      <Checkout>
        <PetInfo />
      </Checkout>
    </>
  );
}
