export default function Mir() {
  return (
    <svg viewBox="0 0 780 500">
      <linearGradient
        id="a"
        gradientTransform="matrix(201.7633 0 0 -79 -72583.8438 21950.0254)"
        gradientUnits="userSpaceOnUse"
        x1="362.4047"
        x2="363.4047"
        y1="275.4307"
        y2="275.4307"
      >
        <stop offset="0" stopColor="#00a0e5" />
        <stop offset="1" stopColor="#0077c3" />
      </linearGradient>
      <g>
        <path
          d="m544.1 240.5v108h60v-64h68c28.6-.2 52.9-18.5 62.1-44z"
          fill="#37a72e"
        />
        <path
          d="m536.1 151.5c3.5 44.1 45.3 79 96.3 79h104.3c.8-4 1.2-8.2 1.2-12.5 0-36.6-29.5-66.2-66-66.5z"
          fill="url(#a)"
        />
        <g fill="#37a72e">
          <path d="m447.3 229.4v-.1.1c.7-1.2 1.8-1.9 3.2-1.9 2 0 3.5 1.6 3.6 3.5v116.5h60v-196h-60c-7.6.3-16.2 5.8-19.4 12.7l-47.7 102.4c-.1.4-.3.8-.5 1.2-.7 1-1.9 1.7-3.3 1.7-2.2 0-4-1.8-4-4v-114h-60v196h60c7.5-.4 15.9-5.9 19.1-12.7l49-105.1c-.1-.1 0-.2 0-.3z" />
          <path d="m223.3 232.8-35.1 114.7h-43.2l-35-114.8c-.3-1.8-1.9-3.2-3.9-3.2-2.2 0-3.9 1.8-3.9 3.9v114h-60v-196h51.5 15.3c11 0 22.6 8.6 25.8 19.1l29.2 95.5c1.5 4.8 3.8 4.7 5.3 0l29.2-95.5c3.2-10.6 14.8-19.1 25.8-19.1h15.3 51.5v196h-60v-114s0 0 0-.1c0-2.2-1.8-3.9-3.9-3.9-2 .1-3.6 1.5-3.9 3.4z" />
        </g>
      </g>
    </svg>
  );
}
