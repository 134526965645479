import { useAppSelector } from '@/hooks';

import AdvancedSearch from '@/organisms/advanced-search';
import BookingLoader from '@/organisms/loaders/booking-loader';
import RebookBanner from '@/organisms/rebook-banner';
import { isEmpty } from '@/util';

/**
 * Display AdvancedSearch for users that have never booked an appointment before
 * Display RebookBanner for users that have booked an appointment before
 */
export default function Booking() {
  const { isLoading, location } = useAppSelector(
    (state) => state.lastUserAppointment,
  );

  if (isLoading) return <BookingLoader />;

  if (!isEmpty(location)) return <RebookBanner location={location} />;

  return <AdvancedSearch />;
}
