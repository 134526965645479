import { Helmet } from 'react-helmet-async';

import Landing from '@/organisms/landing';

import ResetPasswordInfo from './reset-password-info';

export default function ResetPassword() {
  return (
    <>
      <Helmet title="Reset password | PawCare">
        <link
          rel="canonical"
          href="https://mypawcare.com/trigger-password-reset"
        />
      </Helmet>
      <Landing>
        <ResetPasswordInfo />
      </Landing>
    </>
  );
}
