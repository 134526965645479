import './search.scss';

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import classNames from 'classnames';
import petCreationMiddleware from '@/helpers/pet-creation';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { appointmentFinder, localitySearch } from '@/actions/appointments';
import SearchBar from '@/atoms/search-bar';
import SearchLoader from '@/organisms/loaders/search-loader';
import Map from '@/organisms/map';
import { getQueryParams } from '@/util';
import { trackViewProviderList } from '@/util/tracking/events';
import { clearForm, editForm } from '@/store/reducers/request-appointment-form';
import { clearHardInventory } from '@/store/reducers/hard-inventory';

import DateFilter from './date-filter';
import MobileProviderFilter from './mobile-provider-filter';
import NoResults from './no-results';
import { ProviderCard } from './provider-card';
import RequestAppointmentModal from './request-appointment-modal';
import ViewMoreModal from './view-more-modal';

export default function Search() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false);
  const [focusedLocation, setFocusedLocation] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { providers, calendar, isLoading } = useAppSelector(
    (state) => state.hardInventory,
  );
  const { isRequestModalOpen } = useAppSelector((state) => state.ui);
  const { width } = useWindowDimensions();
  const slotBreakPoint = width < 1434 && width > 1105;
  const isMobile = width < 1105;

  // Available filters
  const query = searchParams.get('query') || undefined;
  const dateStart = searchParams.get('dateStart') || undefined;
  const providerId = searchParams.get('providerId');
  // Available params
  const { providerType } = params;

  // Clear reducer so we don't display locations fetched
  // in other parts of the app (ex; Home)
  useEffect(() => {
    dispatch(clearHardInventory());
    dispatch(trackViewProviderList());
  }, []);

  useEffect(() => {
    if (!params.neighborhood) {
      dispatch(
        appointmentFinder({
          query,
          dateStart,
          includeAllProviders: true,
          radius: 3,
          providerType,
        }),
      )?.then(({ success, data }) => {
        if (success && !data.data.calendar.length) {
          dispatch(
            appointmentFinder({
              query,
              dateStart,
              includeAllProviders: true,
              radius: 5,
              providerType,
            }),
          );
        }
      });
    }
  }, [query, dateStart, providerType]);

  useEffect(() => {
    if (params.neighborhood) {
      dispatch(localitySearch({ locality: params.neighborhood }));
    }
  }, [params.neighborhood]);

  const onChangeQuery = (key: string, value: string) => {
    const params = getQueryParams(location.search);
    setSearchParams({ ...params, [key]: value });
  };

  const onChangeStartDate = (date: string) => {
    onChangeQuery('dateStart', date);
  };

  const onClickOnMapPin = (data: string) => {
    onChangeQuery('providerId', data);
  };

  const onSelectSlot = (selectedSlot: any) => {
    if (!selectedSlot) return;
    const { provider, slot } = selectedSlot;
    const { date, time } = slot;
    dispatch(editForm({ key: 'provider', value: provider }));
    dispatch(editForm({ key: 'dateStart', value: `${date}T${time}` }));
    dispatch(editForm({ key: 'location', value: provider.uuid }));
    navigate(
      `/${provider.locality.replace(/ /g, '-').toLowerCase()}/p/${
        provider.slug
      }`,
    );
  };

  const viewMore = (location: any) => {
    dispatch(clearForm());
    setFocusedLocation(location);
    setIsViewMoreModalOpen(true);
  };

  const emptySlotAction = (location: any) => {
    setFocusedLocation(location);
    dispatch(petCreationMiddleware());
  };

  const providersList = providers.map((item: any) => (
    <ProviderCard
      key={item.uuid}
      item={item}
      calendar={calendar}
      maxSlots={!isMobile ? (slotBreakPoint ? 2 : 3) : 4}
      isActive={item.uuid === providerId}
      selectSlot={onSelectSlot}
      onViewMore={viewMore}
      emptySlotAction={emptySlotAction}
      onSelectProvider={() => onChangeQuery('providerId', item.uuid)}
      cardLink={`/${item.locality.replace(/ /g, '-').toLowerCase()}/p/${
        item.slug
      }`}
    />
  ));

  const onSearch = (query: string) => {
    navigate(`/pet/groomers/chicago?query=${query}`);
  };

  const noResults = providersList.length === 0;

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://mypawcare.com${location.pathname}`}
        />
      </Helmet>
      <div className="search-page flex-row w-100 align-start">
        <div
          className={classNames(
            'flex-column search-page-providers w-100',
            noResults && 'justify-center',
          )}
        >
          <div className="flex-column">
            {isMobile && (
              <SearchBar
                onSearch={onSearch}
                value=""
                className="mobile-searchbar mb-15"
                variant=""
                size="small"
              />
            )}
            <div className="flex-row">
              {!noResults && (
                <div className="flex-column">
                  {query && (
                    <h2 className="f26 mb-15">{`Results for "${query}"`}</h2>
                  )}
                  <div className="flex-row mb-10 search-filters">
                    <DateFilter
                      value={dateStart}
                      onChange={onChangeStartDate}
                      className="search-page-date-filter f11 mr-10"
                    />
                    <MobileProviderFilter />
                  </div>
                </div>
              )}
            </div>
            {isLoading ? (
              <SearchLoader />
            ) : noResults ? (
              <NoResults query={query} dateStart={dateStart} />
            ) : (
              <>
                {providersList}
                {isMobile && <div className="mb-32" />}
              </>
            )}
          </div>
        </div>
        <div className="search-page-map">
          <Map
            onClick={onClickOnMapPin}
            filters={{
              query,
              dateStart,
              providerType,
            }}
          />
        </div>
        <ViewMoreModal
          isOpen={isViewMoreModalOpen}
          setIsOpen={setIsViewMoreModalOpen}
          locationUuid={focusedLocation?.uuid}
          dateStart={dateStart || ''}
          onSelectSlot={onSelectSlot}
        />
        {isRequestModalOpen && (
          <RequestAppointmentModal
            isOpen={isRequestModalOpen}
            location={focusedLocation}
          />
        )}
      </div>
    </>
  );
}
