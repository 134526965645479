import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import paymentRedirectionMiddleware from '@/helpers/payment-redirection';
import ScrollToTop from '@/helpers/scroll-to-top';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { loadBootstrap } from '@/actions';
import { getAppointment } from '@/actions/appointments';
import { logOut } from '@/actions/authentication';
import { paymentMethod } from '@/actions/payment';
import { loadPetAppointments } from '@/actions/pet';
import { fetchPromoCodeData } from '@/actions/promotion';
import { loadLastUserAppointment, loadUser } from '@/actions/user';
import Banner from '@/atoms/banner';
import DrawerComponent from '@/atoms/drawer';
import FullScreenLoader from '@/atoms/full-screen-loader';
import getRoutes from '@/config/routes';
import useQuery from '@/hooks/useQuery';
import CreateAnimalModal from '@/organisms/create-pet-modal';
import HardInventoryModal from '@/organisms/hard-inventory-modal';
import Navbar from '@/organisms/navbar';
import { displayPromoBanner, promotionCode } from '@/store/reducers/invoice';
import {
  initializeActivePet,
  SELECTED_PET_UUID_STORAGE_KEY,
  setActivePet,
} from '@/store/reducers/pet-appointments';
import {
  initializeForm,
  REQUEST_APPOINTMENT_FORM_STORAGE_KEY,
} from '@/store/reducers/request-appointment-form';
import { openCreatePetModal } from '@/store/reducers/ui';
import { isNotEmptyArray } from '@/util';
import { identifyUser } from '@/util/tracking';

dayjs.extend(utc);

function AppContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { createPetModal, providerViewMoreModal } = useAppSelector(
    (state) => state.ui,
  );
  const { isCreatePetModalOpen } = createPetModal;
  const { isModalOpen } = providerViewMoreModal;
  const { token } = useAppSelector((state) => state.user);
  const { isMobileMenuOpen } = useAppSelector((state) => state.ui);
  const { isPromoBannerOpen } = useAppSelector((state) => state.invoice);
  const [isLoading, setIsLoading] = useState(true);
  const isHomePage = location.pathname === '/';
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const [isPromoDataLoading, setIsPromoDataLoading] = useState(true);
  const promoBannerText = sessionStorage.getItem('promoBannerText');

  if (process.env.REACT_APP_MODE === 'development') {
    // console.log(sessionStorage);
  }

  useEffect(() => {
    async function initDataFetch() {
      const fetchBootstrap = await dispatch(loadBootstrap());
      if (!fetchBootstrap?.isLoggedIn) {
        setIsLoading(false);
      }
      localStorage.setItem('homepageUrl', '/');
    }

    if (process.env.REACT_APP_VERSION)
      console.log(`Current version: ${process.env.REACT_APP_VERSION}`);

    if (localStorage.getItem(REQUEST_APPOINTMENT_FORM_STORAGE_KEY)) {
      dispatch(
        initializeForm(
          JSON.parse(
            localStorage.getItem(REQUEST_APPOINTMENT_FORM_STORAGE_KEY) || '',
          ),
        ),
      );
    }

    initDataFetch();
  }, []);

  useEffect(() => {
    if (token) {
      fetchUserData();
    }
  }, [token]);

  useEffect(() => {
    if (location.search.includes('promo_code=')) {
      const params = new URLSearchParams(location.search);
      const promoCode = params.get('promo_code');
      sessionStorage.setItem('promoCode', promoCode || '');
      dispatch(promotionCode(promoCode || ''));
      fetchAndSetPromoData(promoCode || '');
    }
  }, [location.search]);

  useEffect(() => {
    if (promoBannerText === 'undefined') {
      sessionStorage.removeItem('promoCode');
      sessionStorage.removeItem('promoCodeSuccess');
    }
  }, [promoBannerText]);

  async function fetchAndSetPromoData(promoCode: string) {
    setIsPromoDataLoading(true);
    await dispatch(fetchPromoCodeData(promoCode));
    const promoBannerText = sessionStorage.getItem('promoBannerText');
    // Check if promoBannerText is valid and not an error message
    if (
      promoBannerText &&
      promoBannerText !== 'undefined' &&
      promoBannerText !== 'The promotion_code provided is not valid.'
    ) {
      dispatch(displayPromoBanner(true));
      setIsPromoDataLoading(false);
    } else {
      dispatch(displayPromoBanner(false));
      setIsPromoDataLoading(false);
    }
  }

  async function fetchUserData() {
    // If user didn't sign up through the regular signup flow,
    // don't run these functions.
    if (!sessionStorage.getItem('overrideLoginActions')) {
      const userData = await dispatch(loadUser());

      if (!userData?.success) {
        logOut(dispatch);
        setIsLoading(false);
        navigate('/');
        return;
      }

      dispatch(loadLastUserAppointment());
      const { data } = userData.data;
      const userPets = data.animals;
      if (isNotEmptyArray(userPets)) {
        const storedUuid = localStorage.getItem(SELECTED_PET_UUID_STORAGE_KEY);
        const storedPet = storedUuid
          ? userPets.find((animal: any) => animal.uuid === storedUuid)
          : null;

        if (storedPet) {
          dispatch(initializeActivePet(storedPet));
        } else {
          const firstPet = userPets[0];
          dispatch(setActivePet(firstPet));
          await dispatch(loadPetAppointments(firstPet));
        }
      }
      dispatch(paymentMethod(data.uuid));
      identifyUser(data);
      setIsLoading(false);
      redirectUser(data);
    } else sessionStorage.removeItem('overrideLoginActions');
  }

  const redirectUser = (data: any) => {
    const noRedirectPaths = [
      '/about',
      '/blog',
      '/signup/booking-confirmation',
      '/terms',
    ];

    // If user signed up but never went through the MFA process
    // we'll drop them here every time they visit the app
    if (
      !data.mfaVerified &&
      !noRedirectPaths.includes(location.pathname) &&
      !location.pathname.includes('/signup/finish-signup')
    ) {
      dispatch(openCreatePetModal({ open: false }));
      return navigate('/signup/phone-verification');
    }

    // Don't redirect users on any of these routes
    if (
      noRedirectPaths.includes(location.pathname) ||
      location.pathname.includes('password-reset') || // through email
      location.pathname.includes('/p/') || // provider's profile
      location.pathname.includes('/user/') || // user's profile
      location.pathname.includes('pet/groomers/chicago') ||
      location.pathname.includes('/signup/finish-signup')
    ) {
      return;
    }

    // 1. User was making an appointment
    if (sessionStorage.getItem('requestAppointmentForm')) {
      dispatch(
        initializeForm(
          JSON.parse(sessionStorage.getItem('requestAppointmentForm') || ''),
        ),
      );
      sessionStorage.removeItem('requestAppointmentForm');

      // b. User logged in while making a request. They already have a pet.
      if (data.animals.length) {
        dispatch(paymentRedirectionMiddleware(navigate));

        // c. User logged in/signed up but needs to create a pet
      } else {
        navigate('create-pet?appointment=true');
      }

      // 2. User logged in normally but has no pets
    } else if (!data.animals?.length) {
      navigate('/create-pet');
    }

    // Check if user is coming from the Confirm New Appointment Time email
    const hasAllReviewRescheduleData =
      !!query.get('action') &&
      !!query.get('apuid') &&
      !!query.get('date') &&
      !!query.get('time');
    if (
      hasAllReviewRescheduleData &&
      query.get('action') === 'review_reschedule' &&
      ['/', '/link'].includes(location.pathname)
    ) {
      // User must be logged in to confirm new appointment time
      if (!token) return;

      // If the user is logged in, redirect to appointment's pet's profile page with params
      const apuid = query.get('apuid');
      if (apuid) {
        dispatch(getAppointment(apuid))?.then(({ success, data }: any) => {
          if (success) {
            const { animal, user } = data.data;
            const animalUuid = animal?.uuid;
            const userUuid = user?.uuid;
            if (animalUuid && userUuid) {
              navigate(
                `/user/${userUuid}/pet/${animalUuid}?${query.toString()}`,
              );
            }
          }
        });
      }
    }
  };

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <div id="app">
      <ScrollToTop />
      <div
        className={classNames(
          isMobileMenuOpen && 'app-wrapper--disable-scroll',
          'app-wrapper',
        )}
      >
        <div className="app-wrapper-container">
          <Toaster
            toastOptions={{
              className: 'toast',
              duration: 5000,
              position: 'bottom-left',
            }}
          />
          <div
            className={classNames(
              'z-index-3 w-100',
              isHomePage && !isMobile ? 'pos-absolute' : 'pos-fixed',
            )}
          >
            {!isPromoDataLoading && isPromoBannerOpen && promoBannerText && (
              <Banner text={promoBannerText} />
            )}
            <DrawerComponent />
            <Navbar />
          </div>
          <div
            className={classNames(
              isPromoBannerOpen && 'app-wrapper-container-page--with-banner',
              'app-wrapper-container-page',
            )}
          >
            {getRoutes()}
          </div>
          {isCreatePetModalOpen && <CreateAnimalModal />}
          {isModalOpen && <HardInventoryModal />}
        </div>
      </div>
    </div>
  );
}

export default AppContainer;
