import { FormEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from '@/helpers/use-window-dimensions';
import { createPet } from '@/actions/pet';
import { loadUser } from '@/actions/user';
import { clearForm, editForm } from '@/store/reducers/create-pet-form';
import { setActivePet } from '@/store/reducers/pet-appointments';

import Desktop from './desktop';
import Mobile from './mobile';

export default function CreatePetDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { core } = useAppSelector((state) => state.main);
  const { isLoadingUser } = useAppSelector((state) => state.user);
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { data } = useAppSelector((state) => state.createPetForm);
  const {
    breed,
    dateOfBirth,
    gender,
    hairLength,
    name,
    size,
    isSterilized,
    type,
    vaccinations,
  } = data;
  const breedOptions = core.breed.filter((el) => el.animalTypeId === type?.id);
  const hairLengthOptions = core.hair.length.filter(
    (el) => el.animalTypeId === type?.id,
  );
  const canContinue = name && type && gender && !isLoadingUser;

  useEffect(() => {
    dispatch(editForm({ key: 'type', value: { id: 2, value: 'Dog' } }));
  }, []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    dispatch(
      createPet({
        name,
        breedId: breed?.id,
        dateOfBirth,
        genderId: gender?.id,
        hairLengthId: hairLength?.id,
        sizeId: size?.id,
        isSterilized,
        typeId: type?.id,
        vaccinations,
      }),
    )?.then(({ success, data }) => {
      if (success) {
        dispatch(clearForm());
        dispatch(loadUser());
        dispatch(setActivePet(data.data));
        navigate(`/upload-avatar${location.search}`);
      }
    });
  };

  const Element = isMobile ? Mobile : Desktop;

  return (
    <div className="create-pet">
      <Element
        data={data}
        onSubmit={onSubmit}
        breedOptions={breedOptions}
        hairLengthOptions={hairLengthOptions}
        canContinue={canContinue}
      />
    </div>
  );
}
