function Pencil() {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none">
      <rect
        x="12.9188"
        y="2.08134"
        width="3.99986"
        height="14.1276"
        rx="0.3"
        transform="rotate(45 12.9188 2.08134)"
        fill="#292936"
      />
      <path
        d="M3.92789 13.8922L5.10341 15.0677C5.18992 15.1543 5.1101 15.3207 4.97691 15.3316L2.76624 15.5116C2.49101 15.534 2.28607 15.3291 2.30849 15.0539L2.48854 12.8432C2.49939 12.71 2.66586 12.6302 2.75238 12.7167L3.92789 13.8922Z"
        fill="#292936"
      />
      <path
        d="M14.6466 0.353584C14.8418 0.158322 15.1584 0.158322 15.3537 0.353584L17.4749 2.47481C17.6702 2.67007 17.6702 2.98665 17.4749 3.18191L16.3756 4.28119C16.2585 4.39834 16.0685 4.39834 15.9514 4.28119L13.5473 1.87712C13.4301 1.75996 13.4301 1.57001 13.5473 1.45286L14.6466 0.353584Z"
        fill="#292936"
      />
    </svg>
  );
}

export default Pencil;
