import './provider-profile.scss';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { appointmentFinder } from '@/actions/appointments';
import { loadProviderLocation } from '@/actions/providers';
import Button from '@/atoms/button';
import FullScreenLoader from '@/atoms/full-screen-loader';
import ServiceBlock from '@/atoms/service-block';
import useSignupSteps from '@/hooks/useSignupSteps';
import ProgressBar from '@/organisms/progress-bar';
import {
  locationInventoryFailure,
  locationInventoryRequest,
  locationInventorySuccess,
} from '@/store/reducers/location';
import { editForm } from '@/store/reducers/request-appointment-form';
import { openCreatePetModal } from '@/store/reducers/ui';
import { mobileGroomerId } from '@/util/constants';
import { trackCheckout, trackViewProvider } from '@/util/tracking/events';
import RequestAppointmentModal from '@/pages/search/request-appointment-modal';

import { Details } from './details';
import Header from './header';
import ProfileInfoDesktop from './profile-info-desktop';

export default function ProviderProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const {
    isLoadingUser,
    token,
    data: userData,
  } = useAppSelector((state) => state.user);
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const { size } =
    useAppSelector((state) => state.createPetForm.data) || activePet.data;
  const { isRequestModalOpen } = useAppSelector((state) => state.ui);
  const locationData = useAppSelector((state) => state.location);
  const isMobile = window.innerWidth <= 600;
  const { isLoading, name, services } = locationData;
  const noServicesAvailable =
    !locationData.services || locationData.services.length === 0;
  const [numberOfSteps, setExtraSignupStep] = useSignupSteps();

  useEffect(() => {
    if (name) {
      dispatch(trackViewProvider(name));
      dispatch(editForm({ key: 'locationUser', value: {} }));
      setExtraSignupStep(!token);
    }
  }, [name]);

  useEffect(() => {
    // If user is logged in, make sure their data finished loading.
    if (!token || (token && userData?.email)) {
      dispatch(loadProviderLocation(params.slug))?.then(({ success, data }) => {
        if (success) {
          findAppointmentSlots(data.data);
          // Check if user has a pet or has provided pet size
          if ((!token && !size) || (token && !userData?.animals?.length)) {
            dispatch(openCreatePetModal({ open: true }));
          }
        }
      });
    }
    return () => dispatch(openCreatePetModal({ open: false }));
  }, [userData, activePet]);

  const findAppointmentSlots = (provider: any) => {
    dispatch(
      appointmentFinder(
        { location: provider.uuid, includeAllProviders: true },
        [
          locationInventoryRequest,
          locationInventorySuccess,
          locationInventoryFailure,
        ],
      ),
    );
  };

  const onSelectService = (service: any) => {
    const isMobileService = service?.type?.id === mobileGroomerId;
    if (isMobileService) setExtraSignupStep(true);

    dispatch(editForm({ key: 'location', value: locationData.uuid }));
    dispatch(editForm({ key: 'service', value: service }));
    dispatch(editForm({ key: 'providerPath', value: location.pathname }));
    dispatch(trackCheckout({ location, service }));
    if (!token) {
      navigate('/signup/pet-details');
    } else {
      navigate('/booking-select-pet');
    }
  };

  if (isLoading || isLoadingUser) return <FullScreenLoader />;

  return (
    <div className="services-page">
      <Helmet>
        <title>{`${name} | PawCare`}</title>
        <meta
          name="title"
          content="Find best tailored pet grooming services in Chicago"
        />
        <meta
          name="description"
          content="find the best pet groomers for your furry friend’s every need. Round-the-clock, we ensure your pets have an excellent tail-wagging experience."
        />
        <link
          rel="canonical"
          href={`https://mypawcare.com${location.pathname}`}
        />
      </Helmet>
      {isMobile ? (
        <Header data={locationData} isMobile />
      ) : (
        <ProfileInfoDesktop data={locationData} />
      )}
      <div className="page-container services">
        <div className="flex-column">
          <div>
            <div className="flex-row justify-between">
              <h1 className="section-title mb-12">Select a service</h1>
            </div>
            <ProgressBar
              maxSteps={numberOfSteps as number}
              currentStep={1}
              className="mb-32"
            />
          </div>
          {noServicesAvailable ? (
            <div className="service-block-empty flex-column align-center">
              <h2 className="flex-row justify-center">No services available</h2>
              <p className="flex-row justify-center text-center">
                This provider does not have services available for{' '}
                {activePet?.name || size?.name || 'your pet'}. Change your
                animal to see services.
              </p>
              <Button
                text="Search providers"
                size="medium"
                className="mt-15"
                onClick={() => navigate('/pet/groomers/chicago')}
              />
            </div>
          ) : (
            services.map((service) => (
              <ServiceBlock
                viewMoreAction={isMobile ? () => navigate(service.uuid) : null}
                onSelectService={onSelectService}
                className="services-service"
                key={service.uuid}
                service={service}
                location={locationData}
              />
            ))
          )}
        </div>
      </div>

      {/* TODO: move to AppContainer.js */}
      {isRequestModalOpen && (
        <RequestAppointmentModal
          isOpen={isRequestModalOpen}
          location={locationData}
          disableFirstScreen
        />
      )}
      {isMobile && <Details data={locationData} isMobile />}
    </div>
  );
}
