import './booking-confirmation.scss';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch } from '@/hooks';
import { useLocation } from 'react-router-dom';

import { clearForm as clearSurveyForm } from '@/store/reducers/advanced-search';
import { clearForm } from '@/store/reducers/request-appointment-form';

import { DesktopView } from './desktop-view';

export default function BookingConfirmation() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const finishSignup = location.pathname.includes('signup');

  useEffect(
    () => () => {
      if (!finishSignup) {
        dispatch(clearForm());
      }
      dispatch(clearSurveyForm());
      sessionStorage.removeItem('requestAppointmentForm');
    },
    [],
  );

  return (
    <>
      <Helmet title="Booking confirmation | PawCare">
        <link
          rel="canonical"
          href="https://mypawcare.com/signup/booking-confirmation"
        />
      </Helmet>
      <div className="booking-confirmation">
        <DesktopView finishSignup={finishSignup} />
      </div>
    </>
  );
}
