import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  token: string | null;
  uuid: string | null;
  isLoadingUser: boolean;
  paymentMethod: { cards: any[] };
  data: any;
}

const initialState: UserState = {
  token: null,
  uuid: null,
  isLoadingUser: false,
  paymentMethod: { cards: [] },
  data: {},
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userRequest(state) {
      state.isLoadingUser = true;
    },
    userSuccess(state, { payload }) {
      state.data = payload.data;
      state.isLoadingUser = false;
    },
    userFailure(state) {
      state.isLoadingUser = false;
    },
    userSetCredentials(state, { payload }) {
      state.token = payload.token;
      state.uuid = payload.uuid;
    },
    paymentMethodSuccess(state, { payload }) {
      state.paymentMethod = payload.data;
    },
    editForm(state, { payload }) {
      const { key, value } = payload;
      state.data[key] = value;
    },
    editPhoneNumber(state, { payload }) {
      state.data.phone.number = payload.value;
    },
    editCountryCode(state, { payload }) {
      state.data.phone.country = payload.value;
    },
  },
});

const { actions, reducer } = user;

export const {
  userRequest,
  userSuccess,
  userFailure,
  userSetCredentials,
  paymentMethodSuccess,
  editForm,
  editPhoneNumber,
  editCountryCode,
} = actions;

export default reducer;
