import '../home-loader';

import classNames from 'classnames';

function HomeLoaderMobile({ variant = 'default' }) {
  return (
    <div className="list-loader mb-20">
      {[1, 2, 3, 4, 5, 6, 7].map((el) => (
        <div key={el} className={classNames(`row flex-row row-${variant}`)}>
          <div className="flex-row w-100 content">
            <div className="avatar-loader loader-bg mr-15" />
            <span className="title" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default HomeLoaderMobile;
