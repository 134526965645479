function KnobDown({ stroke = '#63637B', width = 12, height = 6 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 6" fill="none">
      <path
        d="M11 1L6.23556 4.91964C6.20464 4.94512 6.16792 4.96533 6.1275 4.97911C6.08708 4.9929 6.04376 5 6 5C5.95624 5 5.91292 4.9929 5.8725 4.97911C5.83208 4.96533 5.79536 4.94512 5.76444 4.91964L1 1"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default KnobDown;
