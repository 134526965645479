import React, { ComponentType } from 'react';
import classNames from 'classnames';
import iconComponents, { IconName } from './icons';
import './icon.scss';

interface IconComponentProps {
  name: IconName;
  fill?: string;
  stroke?: string;
  strokeWidth?: string;
  width?: number | string;
  height?: number | string;
  outterColor?: string;
}

type IconProps = IconComponentProps & {
  className?: string;
  alt?: string;
  onClick?: React.MouseEventHandler;
  ariaLabel?: string;
  type?: 'submit';
  disabled?: boolean;
  text?: string;
};

export type SvgProps = {
  fill?: string;
  stroke?: string;
  outterColor?: string;
  strokeWidth?: string;
  width?: number | string;
  height?: number | string;
};

type SvgComponentType = ComponentType<SvgProps>;

// eslint-disable-next-line react/function-component-definition
const IconComponent: React.FC<IconComponentProps> = ({
  name,
  fill,
  strokeWidth,
  outterColor,
  stroke,
  width,
  height,
}) => {
  const SvgComponent = iconComponents[name as IconName] as SvgComponentType;

  if (!SvgComponent) {
    return null;
  }

  return (
    <SvgComponent
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      width={width}
      height={height}
      outterColor={outterColor}
    />
  );
};

export function Icon({
  name,
  className,
  alt,
  onClick,
  fill,
  stroke,
  strokeWidth,
  width,
  height,
  outterColor,
  ariaLabel,
  type,
  disabled,
  text,
}: IconProps) {
  if (!name) return null;

  const Element: any = onClick ? 'button' : 'div';
  return (
    <Element
      aria-label={onClick ? ariaLabel : null}
      className={classNames(
        'icon inline',
        `icon-${name}`,
        className,
        onClick && 'b-transparent border-none',
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      <p className="mr-4">{text}</p>
      {alt && <span className="accessible-hide">{alt}</span>}
      <IconComponent
        name={name}
        fill={fill}
        strokeWidth={strokeWidth}
        stroke={stroke}
        width={width}
        height={height}
        outterColor={outterColor}
      />
    </Element>
  );
}

export default Icon;
