import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { DateCalendar } from '@mui/x-date-pickers';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { newAppointmentFinder } from '@/actions/appointments';
import { SlotsByDay } from '@/organisms/slots-by-day';
import {
  providerCalendarFailure,
  providerCalendarRequest,
  providerCalendarSuccess,
} from '@/store/reducers/provider-calendar';
import { weekdays } from '@/util/constants';
import useQuery from '@/hooks/useQuery';
import { getMonthStartAndEndDate } from './util';

interface Props {
  locationUuid: string;
  onSelectSlot: (data: any) => void;
  dateStart?: string;
  displayHeader?: boolean;
  className?: string;
  ignoreLocationUser?: boolean;
}

export default function SlotsCalendar({
  locationUuid,
  onSelectSlot,
  dateStart,
  displayHeader = true,
  className,
  ignoreLocationUser,
}: Props) {
  const dispatch = useAppDispatch();
  const { locationUser, service } = useAppSelector(
    (state) => state.requestAppointmentForm,
  );
  const location = useAppSelector((state) => state.location);
  const { calendar, isLoading } = useAppSelector(
    (state) => state.providerCalendar,
  );
  const { hours, name } = location;
  const hasSetInitialDateStart = useRef(false);
  const query = useQuery();
  const nextDate = query.get('next-date');
  const [date, setDate] = useState(
    dateStart
      ? dayjs(dateStart).format('YYYY-MM-DD')
      : nextDate
        ? dayjs(nextDate).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
  );

  useEffect(() => {
    if (dateStart && !hasSetInitialDateStart.current) {
      setDate(dayjs(dateStart).format('YYYY-MM-DD'));
      hasSetInitialDateStart.current = true;
    } else if (nextDate && !hasSetInitialDateStart.current) {
      setDate(dayjs(nextDate).format('YYYY-MM-DD'));
      hasSetInitialDateStart.current = true;
    }
  }, [dateStart, nextDate]);

  const [monthDates, setMonthDates] = useState(getMonthStartAndEndDate(date));
  const availableSlots = calendar.filter((el: any) => el.date === date)[0];
  const openDays = hours?.map((day: any) => day.dow.value);
  const openDaysOfWeek = weekdays
    .map((day, i) => {
      if (openDays?.includes(day)) {
        return `${i}`;
      }
      return null;
    })
    .filter(Boolean);

  useEffect(() => {
    dispatch(
      newAppointmentFinder(
        {
          dateStart: monthDates.startDate,
          dateEnd: monthDates.endDate,
          luid: locationUuid,
          groomer: ignoreLocationUser ? null : locationUser.user?.uuid,
          suid: service?.uuid,
        },
        [
          providerCalendarRequest,
          providerCalendarSuccess,
          providerCalendarFailure,
        ],
      ),
    );
  }, [monthDates, locationUuid]);

  const onChangeDate = (date: any) => {
    setDate(dayjs(date).format('YYYY-MM-DD'));
  };

  const onMonthChange = (date: any) => {
    const { startDate, endDate } = getMonthStartAndEndDate(
      dayjs(date).format('YYYY-MM-DD'),
    );
    onChangeDate(startDate);
    setMonthDates({ startDate, endDate });
  };

  const shouldDisableDate = (date: any) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    const dayWithSlots = calendar.filter(
      (el: any) => el.date === formattedDate,
    )[0];
    return (
      !openDaysOfWeek.includes(`${new Date(date).getDay()}`) ||
      !dayWithSlots ||
      date.isBefore(new Date(), 'day')
    );
  };

  return (
    <>
      {displayHeader && (
        <div className="mb-25">
          <h2 className="f26">{name}</h2>
          <p>Select an appointment date and time</p>
        </div>
      )}
      <div className={classNames('flex-row align-start flex-wrap', className)}>
        <div className="calendar-view mr-30 mb-30">
          <DateCalendar
            value={dayjs(date)}
            onChange={onChangeDate}
            onMonthChange={onMonthChange}
            shouldDisableDate={shouldDisableDate}
          />
        </div>
        <div className="slots-by-day-container flex-row">
          <SlotsByDay
            isLoading={isLoading}
            startDate={date}
            key={locationUuid}
            item={location}
            selectSlot={onSelectSlot}
            availableSlots={availableSlots}
            displayDate={false}
          />
        </div>
      </div>
    </>
  );
}
