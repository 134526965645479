import './slot.scss';

import { useAppDispatch } from '@/hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { MouseEvent } from 'react';

import { prettifyHour } from '@/util';
import { trackSlotSelected } from '@/util/tracking/events';

interface SlotProps {
  date?: string;
  time?: string;
  location?: any;
  onClick?: (value: any, e?: MouseEvent) => void;
  id?: string;
  displayDate?: boolean;
  mobileStyling?: boolean;
  stopPropagation?: boolean;
  emptySlotAction?: (location: any) => void;
  emptySlotText?: string;
  className?: string;
}

export default function Slot({
  date,
  time,
  location,
  onClick,
  id,
  displayDate = true,
  mobileStyling,
  stopPropagation,
  className,
  emptySlotText,
  emptySlotAction,
}: SlotProps) {
  const dispatch = useAppDispatch();

  const handleClick = (e: MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (emptySlotAction) {
      emptySlotAction(location);
    } else {
      dispatch(trackSlotSelected(time, location));
      if (onClick) {
        onClick({ slot: { date, time }, provider: location, id });
      }
    }
  };

  return (
    <button
      className={classNames(
        'slot-button',
        className,
        emptySlotText && 'slot-button--empty',
        mobileStyling && 'slot-button--mobile',
      )}
      onClick={(e) => handleClick(e)}
    >
      {!emptySlotText
        ? `${
            displayDate ? `${dayjs(date).format('MMM DD')}, ` : ''
          }${prettifyHour(time || '')}`
        : emptySlotText || 'Request appointment'}
    </button>
  );
}
