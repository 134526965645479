import './overflow-text.scss';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

interface OverflowTextProps {
  text: string;
  buttonText?: string;
  onClick?: () => void;
  className?: string;
  stringMax?: number;
}

export default function OverflowText({
  text,
  buttonText = 'View more',
  onClick,
  className,
  stringMax = 120,
}: OverflowTextProps) {
  const [textToDisplay, setTextToDisplay] = useState(text);
  const [shouldDisplayButton, displayButton] = useState(false);

  useEffect(() => {
    const shortText = text?.substring(0, stringMax);
    setTextToDisplay(shortText);
    if (shortText !== text) {
      displayButton(true);
    }
  }, []);

  return (
    <div className="overflow-text">
      <p className={classNames('overflow-text-content', className)}>
        <span id="overflow-content">{textToDisplay}</span>
        {shouldDisplayButton ? '... ' : ''}
        {shouldDisplayButton && (
          <button
            className="overflow-text-button pointer"
            id="overflow-button"
            onClick={onClick}
          >
            {buttonText}
          </button>
        )}
      </p>
    </div>
  );
}
