import ReactModal from 'react-modal';

import Button from '@/atoms/button';

interface Warning24HoursModalProps {
  isOpen: boolean;
  openModal: (isOpen: boolean) => void;
  onConfirm: () => void;
}

export default function Warning24HoursModal({
  isOpen,
  openModal,
  onConfirm,
}: Warning24HoursModalProps) {
  const onClickConfirm = () => {
    openModal(false);
    onConfirm();
  };
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className="mw-450 text-center"
      onRequestClose={() => openModal(false)}
    >
      <h2 className="mb-15 f24">Last minute booking</h2>
      <p>
        The appointment you’re booking is within 24 hours. If you cancel, you
        will be charged a late cancellation fee. Do you accept?
      </p>
      <div className="flex-row justify-center mt-25">
        <Button
          onClick={onClickConfirm}
          text="Confirm"
          size="medium"
          className="mr-10"
          disableLoadingState
        />
        <Button
          onClick={() => openModal(false)}
          text="Not now"
          size="medium"
          variant="reverse"
          disableLoadingState
        />
      </div>
    </ReactModal>
  );
}
