function Lock() {
  return (
    <svg width="29" height="26" viewBox="0 0 20 26" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2059 13.517H22.4741L22.4712 9.86972C22.4681 5.89632 19.2357 2.66398 15.2623 2.66663C11.2891 2.66952 8.05657 5.90214 8.05921 9.87554L8.06521 13.523H7.80438C6.43847 13.5259 5.33023 14.6341 5.33335 16V26.8591C5.33335 28.225 6.44445 29.3333 7.81036 29.3333L23.2118 29.3244C24.5777 29.3244 25.6859 28.2162 25.6828 26.8503V15.9885C25.6828 14.6226 24.5717 13.5172 23.2058 13.5172L23.2059 13.517ZM10.7204 13.523L10.7144 9.8755C10.7144 7.36579 12.7528 5.32444 15.2625 5.32158C17.7722 5.32158 19.8136 7.36315 19.8136 9.86973L19.8196 13.5172L10.7204 13.523Z"
        fill="black"
      />
    </svg>
  );
}

export default Lock;
