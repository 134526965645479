import useWindowDimensions from '@/helpers/use-window-dimensions';

import Button from '@/atoms/button';
import TermsLabel from '@/organisms/add-new-card/terms-label';
import { DefaultPaymentMethod } from '@/organisms/default-payment-method';

interface DefaultCardProps {
  onChangePaymentMethod: () => void;
  onSubmit: () => void;
  primaryButtonText: string;
  hideDiscount: boolean;
  hasSignedWaiver: boolean;
}

export function DefaultCard({
  onChangePaymentMethod,
  onSubmit,
  primaryButtonText,
  hideDiscount,
  hasSignedWaiver,
}: DefaultCardProps) {
  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  return (
    <>
      <div className="mobile-page-container">
        <div className="flex-row justify-between mb-15">
          <p className="bold f18">Default payment method</p>
          <button
            className="text-orange pointer f14 no-style-btn"
            onClick={onChangePaymentMethod}
          >
            Change
          </button>
        </div>
        <DefaultPaymentMethod hideDiscount={hideDiscount} />
        {isMobile && <TermsLabel hasSignedWaiver={hasSignedWaiver} />}
      </div>
      <div className="mobile-main-action-btn">
        <Button
          onClick={onSubmit}
          className="mt-32 w-100"
          size="medium"
          text={primaryButtonText}
          variant={hasSignedWaiver ? 'default' : 'disabled'}
        />
        {!isMobile && <TermsLabel hasSignedWaiver={hasSignedWaiver} />}
      </div>
    </>
  );
}
