import {
  signupFailure,
  signupRequest,
  signupSuccess,
} from '@/store/reducers/authentication';
import {
  signupDataValidateFailure,
  signupDataValidateRequest,
  signupDataValidateSuccess,
} from '@/store/reducers/signup-form';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';
import { userSetCredentials } from '@/store/reducers/user';

import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { fetchData } from './fetchData';

export const logIn =
  (body: any, params?: any) =>
  (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'user/login',
      actions: [requestOngoing, requestFulfilled, requestFulfilled],
      httpMethod: 'post',
      body,
      showAPIerror: true,
      displayErrorToast: params?.displayErrorToast,
    })?.(dispatch, getState);

export const adminSignUp =
  (body: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'user/bypass',
      actions: [signupRequest, signupSuccess, signupFailure],
      httpMethod: 'post',
      showAPIerror: true,
      headers: {
        'x-pawcare-registration': '8413',
      },
      body: {
        ...body,
        isProvider: false,
      },
    })?.(dispatch, getState);

export const signUp =
  (body: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'user',
      actions: [signupRequest, signupSuccess, signupFailure],
      httpMethod: 'post',
      body: {
        ...body,
        isProvider: false,
      },
    })?.(dispatch, getState);

export const signupDataValidate =
  (body: any) => (dispatch: Dispatch, getState: () => RootState) => {
    const data = { ...body };
    delete data.isMarketable;
    return fetchData({
      endpoint: 'user/data/validate',
      actions: [
        signupDataValidateRequest,
        signupDataValidateSuccess,
        signupDataValidateFailure,
      ],
      httpMethod: 'post',
      body: data,
      showAPIerror: true,
    })?.(dispatch, getState);
  };

export const mfaInit =
  (body: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'mfa/init',
      actions: [null, null, null],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);

export const mfaVerify =
  (body: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'mfa/verify',
      actions: [requestOngoing, requestFulfilled, requestFulfilled],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);

export const triggerPasswordChangeEmail =
  (body: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'password/forgot',
      actions: [null, null, null],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);

export const resetPassword =
  (body: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'password/reset',
      actions: [null, null, null],
      httpMethod: 'patch',
      body,
    })?.(dispatch, getState);

export const logOut = (dispatch: Dispatch) => {
  dispatch(userSetCredentials({ token: null, uuid: null }));
  localStorage.clear();
  sessionStorage.clear();
  window.location.assign('/');
};

export const updatePassword =
  (uuid: string, body: any) =>
  (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `user/${uuid}/password/change`,
      actions: [null, null, null],
      httpMethod: 'patch',
      body,
    })?.(dispatch, getState);

export const deleteUser =
  () => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: 'user',
      actions: [null, null, null],
      httpMethod: 'delete',
    })?.(dispatch, getState);
