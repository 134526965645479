import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import Checkout from '@/organisms/checkout';

import PetDetails from './steps/pet-details';
import UserDetails from './steps/user-details';

export default function BookingSignup() {
  const location = useLocation();
  const step = location.pathname.split('signup/')[1];
  let Element;

  switch (step) {
    case 'pet-details':
      Element = PetDetails;
      break;
    case 'user-details':
      Element = UserDetails;
      break;
    default:
      Element = PetDetails;
  }

  return (
    <>
      <Helmet title="Complete booking | PawCare">
        <link rel="canonical" href="https://mypawcare.com/signup/pet-details" />
      </Helmet>
      <Checkout>
        <Element />
      </Checkout>
    </>
  );
}
