import './modal-cards-loader.scss';

import classNames from 'classnames';

function ModalCardsLoader({ variant = 'default' }) {
  return (
    <div className="modal-cards-loader">
      {[1, 2].map((el) => (
        <div
          key={el}
          className={classNames(`row flex-row mb-10 row-${variant}`)}
        >
          <div className="flex-column w-100 content">
            <div className="flex-row justify-between w-100">
              <span className="description" />
              <span className="short" />
            </div>
            <span className="description mt-10 mb-10" />
            <span className="cta" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ModalCardsLoader;
