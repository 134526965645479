import './create-pet.scss';

import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import Checkout from '@/organisms/checkout';
import Landing from '@/organisms/landing';

import CreatePetDetails from './create-pet-details';

export default function CreatePet() {
  const [searchParams] = useSearchParams();
  const showAppointmentPanel = searchParams.get('appointment');

  const LayoutComponent = showAppointmentPanel ? Checkout : Landing;
  return (
    <>
      <Helmet title="Create pet | PawCare">
        <link rel="canonical" href="https://mypawcare.com/create-pet" />
      </Helmet>
      <LayoutComponent removeOutterPadding>
        <CreatePetDetails />
      </LayoutComponent>
    </>
  );
}
