export default function Card() {
  return (
    <svg
      width="50"
      height="70"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 10H20.5"
        stroke="#323232"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 14H8"
        stroke="#323232"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 14H13"
        stroke="#323232"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M3 9C3 7.11438 3 6.17157 3.58579 5.58579C4.17157 5 5.11438 5 7 5H12H17C18.8856 5 19.8284 5 20.4142 5.58579C21 6.17157 21 7.11438 21 9V12V15C21 16.8856 21 17.8284 20.4142 18.4142C19.8284 19 18.8856 19 17 19H12H7C5.11438 19 4.17157 19 3.58579 18.4142C3 17.8284 3 16.8856 3 15V12V9Z"
        stroke="#323232"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
