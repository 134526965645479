import { Helmet } from 'react-helmet-async';

import Checkout from '@/organisms/checkout';

import SlotSelectInfo from './slot-select-info';

export default function SlotSelect() {
  return (
    <>
      <Helmet title="Select a time for your booking | PawCare" />
      <Checkout>
        <SlotSelectInfo />
      </Checkout>
    </>
  );
}
