import { useState } from 'react';
import { useAppSelector } from '@/hooks';
import classNames from 'classnames';

import Button from '@/atoms/button';
import { DateInput } from '@/atoms/date-input';
import Dropdown from '@/atoms/dropdown';
import Icon from '@/atoms/icon';
import { UserDetails } from '@/organisms/user-details';
import {
  getDayFromDate,
  getTimeFromDate,
  prettifyDate,
  prettifyHour,
} from '@/util';
import { onlyHoursOptions } from '@/util/constants';

interface ConfirmationProviderProps {
  provider: any;
  appointment: any;
  onConfirm: any;
  openConfirmationModal: any;
}
export function ConfirmationProvider({
  provider,
  appointment,
  onConfirm,
  openConfirmationModal,
}: ConfirmationProviderProps) {
  const { appointmentDateMin } = useAppSelector((state) => state.main.search);
  const [confirmingAppointment, setConfirmAppointment] = useState(false);
  const [selectedService, setselectedService] = useState<null | {
    uuid: string;
    name: string;
    price: any;
  }>(null);
  const [day, setDay] = useState(getDayFromDate(appointment.dateStart));
  const [time, setTime] = useState({
    value: prettifyHour(appointment.dateStart, true),
    valueFormatted: getTimeFromDate(appointment.dateStart),
  });

  const servicesList = () =>
    provider.locations[0].services.map((service: any) => (
      <button
        key={service.uuid}
        className={classNames(
          'service pointer',
          selectedService?.uuid === service.uuid && 'service--active',
        )}
        onClick={() => setselectedService(service)}
      >
        <h4 className="f15 mb-5">{`${service.name} (${service.price?.localized})`}</h4>
        <p className="f13 text-grey-dark ellipsis">{service.description}</p>
      </button>
    ));

  const confirmAppointment = () => {
    onConfirm(
      {
        uuid: {
          animal: appointment.animal.uuid,
          location: provider.locations[0].uuid,
          service: selectedService?.uuid,
        },
        dateStart: `${day}T${time.valueFormatted}`,
      },
      appointment.uuid,
    );
  };

  return (
    <div className="confirmation-modal">
      <button onClick={() => openConfirmationModal(false)}>
        <Icon
          name="arrowLeft"
          className="pointer"
          fill="black"
          width={25}
          height={25}
        />
      </button>
      <h2 className="mb-15 mt-5">Confirm appointment</h2>
      <UserDetails item={provider.locations[0]} />
      {!confirmingAppointment ? (
        <>
          <div className="flex-row mt-15">
            <DateInput
              className="w-100 mr-10"
              placeholder="02/02/2020"
              minDate={appointmentDateMin}
              onChange={(value: any) => setDay(value)}
              value={day}
            />
            <Dropdown
              generateKey
              placeholder="Select a time"
              className="w-100 mt-0"
              items={onlyHoursOptions}
              onClick={(value) => setTime(value)}
              value={time}
            />
          </div>
          <div className="mt-15 mb-15">{servicesList()}</div>
          <Button
            text="Next"
            variant={selectedService ? 'default' : 'disabled'}
            onClick={() => setConfirmAppointment(true)}
            size="medium"
          />
        </>
      ) : (
        <>
          <button
            className={classNames('service-confirm pointer')}
            onClick={() => setConfirmAppointment(false)}
          >
            <p className="text-grey-dark f13 bold">
              {`${selectedService?.name} (${selectedService?.price?.localized})`}
            </p>
            <h4 className="">
              {prettifyDate(`${day}T${time.valueFormatted}`)}
            </h4>
          </button>
          <Button
            text="Confirm appointment"
            onClick={confirmAppointment}
            size="medium"
          />
        </>
      )}
    </div>
  );
}
